import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import "./admin.css";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Create = ({ authorize }) => {
  const [icon, setIcon] = useState("");
  const [customInfoCheck, setCustomInfoCheck] = useState(false); //custom Info check
  const [loading, setLoading] = useState(false); //Loading bar

  const [socialWebsites, setSocialWebsites] = useState([]);
  const [socialWebsitesLogos, setSocialWebsitesLogos] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateCustomSections, setEditorStateCustomSections] = useState(
    EditorState.createEmpty()
  );

  const [customSections, setCustomSection] = useState([]);
  const [customSectionIndex, setCustomSectionIndex] = useState(0);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // for custom section
  const onEditorStateChangeCustomSections = (editorStateTokenMetrics) => {
    customSections[customSectionIndex].data = draftToHtml(
      convertToRaw(editorStateTokenMetrics.getCurrentContent())
    );
    setEditorStateCustomSections(editorStateTokenMetrics);
  };

  const sendTextToEditorCustomSections = (text) => {
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorStateCustomSections(EditorState.createWithContent(contentState));
  };

  //convert image to base64 string
  function handleFileSelect(evt) {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        // console.log(base64String);
        setIcon(base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let token = localStorage.getItem("token");
    // console.log(event.target.name.value)
    var formData = {
      Name: event.target.name.value,
      Ticker: event.target.ticker.value,
      TotalRaize: parseFloat(event.target.totalRaize.value),
      Currency: event.target.currency.value,
      Start: event.target.start.value,
      End: event.target.end.value,
      Price: event.target.price.value,
      Vesting: event.target.vesting.value,
      DealRate: event.target.dealRate.value,
      AllocationForTop10: parseFloat(event.target.allocationForTop10.value),
      AllocationForTier1: parseFloat(event.target.allocationForTier1.value),
      AllocationForTier2: parseFloat(event.target.allocationForTier2.value),
      AllocationForTier3: parseFloat(event.target.allocationForTier3.value),
      AllocationForTier4: parseFloat(event.target.allocationForTier4.value),
      Social: JSON.stringify(socialWebsites),
      Description: draftToHtml(convertToRaw(editorState.getCurrentContent())), //converted text
      CustomSections: customSections,
      socialWebsitesLogos: socialWebsitesLogos,
      Icon: icon,
      CurrentRaize: parseFloat(event.target.currentRaize.value),
      WalletAddress: event.target.walletAddress.value,
      VisibleInAll: event.target.allDealsCheckbox.checked,
      VisibleInTop10: event.target.top10Checkbox.checked,
      VisibleInTier1: event.target.tier1Checkbox.checked,
      VisibleInTier2: event.target.tier2Checkbox.checked,
      VisibleInTier3: event.target.tier3Checkbox.checked,
      VisibleInTier4: event.target.tier4Checkbox.checked,
      ManageSell: event.target.manageSell.checked,
      CustomInfoWindow: event.target.customInfoWindow.checked,
      CustomInfoText: customInfoCheck ? event.target.customInfoText.value : "",
    };
    console.log(formData);
    fetch("https://backend.bulknetwork.app/create-deals", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        else toast.error(responseJson.error.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const addNewSocialWebsite = () => {
    setSocialWebsites([...socialWebsites, { type: "", link: "" }]);
  };

  const removeSocialWebsite = (index) => {
    var _socialWebsites = socialWebsites;
    _socialWebsites.splice(index, 1);
    setSocialWebsites([..._socialWebsites]);
  };

  const socialWebsiteValueChange = (index, field, e) => {
    var _socialWebsites = socialWebsites;
    _socialWebsites[index][field] = e.target.value;
    setSocialWebsites([..._socialWebsites]);
  };

  //convert social logo image to base64 string
  function handleFileSelectForSocialLogo(evt, index) {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        // console.log(base64String);
        var _socialWebsitesLogos = socialWebsitesLogos
          ? socialWebsitesLogos
          : [];
        console.log(_socialWebsitesLogos);
        for (let i = 0; i < index; i++) {
          if (_socialWebsitesLogos.length < i) {
            _socialWebsitesLogos.push("");
          } else if (!_socialWebsitesLogos[i]) {
            _socialWebsitesLogos[i] = "";
          }
        }
        _socialWebsitesLogos[index] = base64String;
        setSocialWebsitesLogos([..._socialWebsitesLogos]);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    if (customSections.length > customSectionIndex) {
      if (customSections[customSectionIndex].data.length > 5)
        sendTextToEditorCustomSections(customSections[customSectionIndex].data);
      else sendTextToEditorCustomSections("<p></p>");
    }
  }, [customSections, customSectionIndex]);

  const addNewCustomSection = () => {
    var heading = document.getElementById("customSectionText").value;
    setCustomSection([...customSections, { heading: heading, data: "" }]);
  };

  const removeCustomSection = (index) => {
    var _customSections = customSections;
    _customSections.splice(index, 1);
    setSocialWebsites([..._customSections]);
  };

  return (
    <>
      {authorize ? (
        <div className="deal-form-page" style={{ padding: "1%" }}>
          <Container>
            <br />
            <h1 className="page-heading">Add Data</h1>

            <Form className="deal-form" onSubmit={handleSubmit}>
              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label for="name">Deal Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="ticker">
                    <Form.Label for="ticker">Ticker</Form.Label>
                    <Form.Control
                      type="text"
                      id="ticker"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="totalRaize">
                    <Form.Label for="totalRaize">Total Raize</Form.Label>
                    <Form.Control
                      type="number"
                      id="totalRaize"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="currency">
                    <Form.Label for="currency">Currency</Form.Label>
                    <Form.Control
                      type="text"
                      id="currency"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="start">
                    <Form.Label for="start">Start Time</Form.Label>
                    <Form.Control
                      type="text"
                      id="start"
                      className="input-design"
                      placeholder=""
                      aria-describedby="startHelpBlock"
                      required
                    />
                    <Form.Text id="startHelpBlock" muted>
                      e.g. 2021-10-26T15:00:00+00:00
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="end">
                    <Form.Label for="end">End Time</Form.Label>
                    <Form.Control
                      type="text"
                      id="end"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label for="price">Price </Form.Label>
                    <Form.Control
                      type="text"
                      id="price"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="vesting">
                    <Form.Label for="vesting">Vesting </Form.Label>
                    <Form.Control
                      type="text"
                      id="vesting"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="dealRate">
                    <Form.Label for="dealRate">Deal Rate </Form.Label>
                    <Form.Control
                      type="text"
                      id="dealRate"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="allocationForTop10">
                    <Form.Label for="allocationForTop10">
                      Allocation For Top 10{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="allocationForTop10"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="allocationForTier1">
                    <Form.Label for="allocationForTier1">
                      Allocation For Tier 1{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="allocationForTier1"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="allocationForTier2">
                    <Form.Label for="allocationForTier2">
                      Allocation For Tier 2{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="allocationForTier2"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="allocationForTier3">
                    <Form.Label for="allocationForTier3">
                      Allocation For Tier 3{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="allocationForTier3"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="allocationForTier4">
                    <Form.Label for="allocationForTier4">
                      Allocation For Tier 4{" "}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="allocationForTier4"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="currentRaize">
                    <Form.Label for="currentRaize">Current Raize </Form.Label>
                    <Form.Control
                      type="number"
                      id="currentRaize"
                      className="input-design"
                      placeholder=""
                      min="0"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3" controlId="walletAddress">
                    <Form.Label for="walletAddress">Wallet Address </Form.Label>
                    <Form.Control
                      type="text"
                      id="walletAddress"
                      className="input-design"
                      placeholder=""
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="4" style={{ marginTop: "30px" }}>
                  <Form.Group className="mb-3" controlId="visibleIn">
                    <Form.Check
                      type="checkbox"
                      id="manageSell"
                      className="input-design"
                      label="Manage Sell Activated"
                    />
                  </Form.Group>
                </Col>
                <Col md="4" style={{ marginTop: "30px" }}>
                  <Form.Group className="mb-3" controlId="customWindowInfo">
                    <Form.Check
                      type="checkbox"
                      id="customInfoWindow"
                      className="input-design"
                      onClick={() =>
                        customInfoCheck
                          ? setCustomInfoCheck(false)
                          : setCustomInfoCheck(true)
                      }
                      label="Custom Info Window Activated"
                    />
                    <Form.Control
                      type="text"
                      id="customInfoText"
                      className="input-design"
                      style={{ display: customInfoCheck ? "block" : "none" }}
                      placeholder="Custom Info Text"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3 user-img" controlId="icon">
                    <Form.Control
                      type="file"
                      id="icon"
                      className="input-design"
                      placeholder=""
                      onChange={handleFileSelect}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="visibleIn">
                    <Form.Label>Deal Visible in</Form.Label>
                    <Form.Check
                      type="checkbox"
                      id="allDealsCheckbox"
                      style={{ color: "white" }}
                      label="All Deals Page"
                    />
                    <Form.Check
                      type="checkbox"
                      id="top10Checkbox"
                      style={{ color: "white" }}
                      label="Top 10"
                    />
                    <Form.Check
                      type="checkbox"
                      id="tier1Checkbox"
                      style={{ color: "white" }}
                      label="Tier 1"
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3" controlId="visibleIn">
                    <Form.Label style={{ visibility: "hidden" }}>
                      Deal Visible in
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      id="tier2Checkbox"
                      style={{ color: "white" }}
                      label="Tier 2"
                    />
                    <Form.Check
                      type="checkbox"
                      id="tier3Checkbox"
                      style={{ color: "white" }}
                      label="Tier 3"
                    />
                    <Form.Check
                      type="checkbox"
                      id="tier4Checkbox"
                      style={{ color: "white" }}
                      label="Tier 4"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Row style={{ margin: "10px" }}>
                  <Col>
                    <Form.Label>Social Links</Form.Label>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-info"
                      onClick={() => addNewSocialWebsite()}
                    >
                      Add New Link
                    </Button>
                  </Col>
                </Row>

                {socialWebsites.map((data, index) => {
                  return (
                    <Row key={index}>
                      <Col md={4}>
                        <Form.Select
                          id={"social-type-" + index}
                          value={data.type || ""}
                          onChange={(e) =>
                            socialWebsiteValueChange(index, "type", e)
                          }
                        >
                          <option>Select Social Link Type</option>
                          <option value="discord">Discord</option>
                          <option value="github">Github</option>
                          <option value="website">Website</option>
                          <option value="link">Link</option>
                          <option value="medium">Medium</option>
                          <option value="telegram">Telegram</option>
                          <option value="twitter">Twitter</option>
                          <option value="youtube">Youtube</option>
                          <option value="other">Other</option>
                        </Form.Select>
                        {socialWebsites[index].type == "other" ? (
                          <Form.Control
                            type="file"
                            id={"social-logo" + index}
                            className="input-design"
                            placeholder=""
                            onChange={(e) =>
                              handleFileSelectForSocialLogo(e, index)
                            }
                          />
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          id={"social-link-" + index}
                          placeholder="Enter Social Link"
                          value={data.link || ""}
                          style={{ color: "#fff" }}
                          onChange={(e) =>
                            socialWebsiteValueChange(index, "link", e)
                          }
                        ></Form.Control>
                      </Col>
                      <Col md={2}>
                        <Button
                          variant="outline-danger"
                          onClick={() => removeSocialWebsite(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </Row>
              <br />
              <br />
              <br />

              <Row>
                <h4 style={{ color: "white" }}>Enter Project Description</h4>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbar-class"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  onEditorStateChange={onEditorStateChange}
                />
              </Row>
              <br />
              <br />
              <br />

              <Row>
                <h4 style={{ color: "white" }}>Enter Custom Section</h4>
                <Row>
                  <Col md="4" style={{ marginTop: "30px" }}>
                    <Form.Group className="mb-3" controlId="customSectionText">
                      <Form.Control
                        type="text"
                        id="customSectionText"
                        className="input-design"
                        placeholder="Custom Section Heading"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" style={{ marginTop: "30px" }}>
                    <Form.Group
                      className="mb-3"
                      controlId="customSectionButton"
                    >
                      <Button
                        id="customSectionButton"
                        variant="outline-danger"
                        onClick={() => addNewCustomSection()}
                      >
                        Add New Heading
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>

                {customSections.map((data, index) => {
                  return (
                    <Row key={index}>
                      <Col md={6}>
                        <Button
                          variant="outline-success"
                          style={{ width: "100%" }}
                          placeholder="Enter Social Link"
                          onClick={() => setCustomSectionIndex(index)}
                        >
                          {data.heading}
                        </Button>
                      </Col>
                      <Col md={2}>
                        <Button
                          variant="outline-danger"
                          onClick={() => removeCustomSection(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
                <br />
                <br />
                <br />
                <Editor
                  editorState={editorStateCustomSections}
                  toolbarClassName="toolbar-class"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  onEditorStateChange={onEditorStateChangeCustomSections}
                />
              </Row>
              <br />
              <br />
              <br />

              <Row>
                {/* <span style={{color:"white"}}>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</span> */}
                {/* <Form.Group className="mb-3" controlId="description">
        <Form.Label for="description">Description </Form.Label>
        <Form.Control as="textarea" id="description" className="input-design" rows={5} style={{height:'200px'}} required/>
        <Form.Text id="description" muted>
            put paragraph in &lt;p&gt; paragraph_text &lt;/p&gt; and links like Google: &lt;a href="www.google.com"&gt;Google Link&lt;/a&gt;<br/>
            To set font size of text &lt;p style="font-size:14px;"&gt; Font Size 14px &lt;/p&gt;<br/>
            To set color of text &lt;p style="color:Blue;"&gt; Text in Blue Color &lt;/p&gt;<br/>
            To set both font size and color &lt;p style="font-size:14px; color:Blue;"&gt; Text with Font Size 14px and Blue Color &lt;/p&gt;<br/>
            To set text underline &lt;u&gt; Underline Text &lt;/u&gt;<br/>
            To set text bold &lt;b&gt; Bold Text &lt;/b&gt;<br/>
            To add image &lt;img src="image/url" /&gt;
        </Form.Text>
    </Form.Group>  */}
              </Row>

              <Row>
                <Col className="submit-btn">
                  <Button type="submit">SUBMIT</Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default Create;
