import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "./admin.css";

const UpdateLandingPage = ({ authorize }) => {
  const [loading, setLoading] = useState(true); //Loading bar
  const [logo, setLogo] = useState("");
  // jump to contribute page
  const history = useHistory();

  useEffect(async () => {
    await fetch(
      'https://backend.bulknetwork.app/landing-pages/61fcd524559e673c3472eb6a?filter={"offset": 0,"limit": 500,"skip": 0,"order": "string","fields": {"id": true,"name": true,"description": true,"logo": true,"joinlink": true, "upcomingDealHide": true, "joinlinkHide": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        document.getElementById("name").value = result.name;
        document.getElementById("description").value = result.description;
        document.getElementById("url").value = result.joinlink;
        document.getElementById("hideUpcomingDeal").checked =
          result.upcomingDealHide;
        document.getElementById("hidejoinlink").checked = result.joinlinkHide;
        setLogo(result.logo);
        setLoading(false);
      });
  }, []);

  //convert image to base64 string
  function handleFileSelect(evt) {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        // console.log(base64String);
        setLogo(base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // console.log(event);

    let token = localStorage.getItem("token");
    var formData = {
      name: event.target.name.value,
      description: event.target.description.value,
      logo: logo,
      joinlink: event.target.url.value,
      upcomingDealHide: event.target.hideUpcomingDeal.checked,
      joinlinkHide: event.target.hidejoinlink.checked,
    };
    console.log(formData);

    fetch(
      "https://backend.bulknetwork.app/landing-pages/61fcd524559e673c3472eb6a",
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        else toast.error(responseJson.error.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <div className="dashboard">
            <Container className="maine-content">
              <h2 className="page-heading">Setup Landing Page</h2>

              <Form className="deal-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label for="name">Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="name"
                        className="input-design"
                        placeholder=""
                        required
                      />
                      <Form.Text id="name" muted>
                        e.g. Ultra &lt;span&gt;Games&lt;/span&gt;
                        <br /> The text inside span will have blue color.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="url">
                      <Form.Label for="url">Join Link</Form.Label>
                      <Form.Control
                        type="text"
                        id="url"
                        className="input-design"
                        placeholder=""
                        required
                      />
                      <Form.Text id="url" muted>
                        e.g. https://www.google.com/
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label for="description">Description </Form.Label>
                      <Form.Control
                        as="textarea"
                        id="description"
                        className="input-design"
                        rows={5}
                        style={{ height: "200px" }}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="hideData">
                      <Form.Label style={{ visibility: "hidden" }}>
                        Hide{" "}
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="hideUpcomingDeal"
                        style={{ color: "white" }}
                        label="Hide Upcoming Deal"
                      />
                      <Form.Check
                        type="checkbox"
                        id="hidejoinlink"
                        style={{ color: "white" }}
                        label="Hide Join Link"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 user-img" controlId="logo">
                      <Form.Control
                        type="file"
                        id="logo"
                        className="input-design"
                        placeholder=""
                        onChange={handleFileSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="submit-btn">
                    <Button type="submit" disabled={loading}>
                      Update Data
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default UpdateLandingPage;
