import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify"; //toster
// Route to contribute page
import { useHistory } from "react-router-dom";
import { LoginContext } from "./LoginContext";

function Login() {
  const [loading, setLoading] = useState(false);
  const [authorize, setAuthorize] = useContext(LoginContext);
  const [username, setUsername] = useState("");
  // jump to another page
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("rememberMe")) {
      const username = localStorage.getItem("username");
      setUsername(username);
    }
  }, []);

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const user = {
      username: event.target.username.value,
      password: event.target.password.value,
    };
    localStorage.setItem("rememberMe", event.target.rememberMe.checked);
    localStorage.setItem("username", username);
    fetch("https://backend.bulknetwork.app/users/login", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          localStorage.setItem("token", responseJson.userDetails.ProfileToken);
          setAuthorize(true);
          //navigate to admin page
          history.push("/dashboard");
        } else toast.error(responseJson.error.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="login">
      <div className="login-box" style={{ padding: "20px" }}>
        <h1>LOGIN </h1>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label for="username">Username</Form.Label>
            <Form.Control
              className="input-design"
              type="username"
              id="username"
              placeholder="Enter Username"
              value={username}
              onChange={handleUsername}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label for="password">Password</Form.Label>
            <Form.Control
              className="input-design"
              type="password"
              id="password"
              placeholder="Password"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="rememberMe">
            <Form.Check type="checkbox" id="rememberMe" label="Remember me " />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            Login
          </Button>

          {/* <p className="nt-member">Not a member? <a href="#">Sign up now</a></p> */}
        </Form>
      </div>
    </div>
  );
}
export default Login;
