import React, { useState, useEffect } from "react";
import { Container, Table, Button, Form, Spinner, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import './admin.css';


const ContributionEdit = ({ authorize }) => {
  const { id } = useParams()
  const [contributionData, setContributionData] = useState([])
  const [loading, setLoading] = useState(false);//Loading bar
  const [editData, setEditData] = useState({});
  const [dealDetails, setDealDetails] = useState(0);

  const [modalCreateShow, setModalCreateShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalDeleteAllShow, setModalDeleteAllShow] = useState(false);

  const getContributionData = async () => {
    setLoading(true)
    var res = await fetch('https://backend.bulknetwork.app/contributors?filter={"offset":0,"order":"string","where":{"DealId":"' + id + '"},"fields":{"DealId":true,"id":true,"UserWalletAddress":true,"TotalAmountInvested":true,"ContributorType":true,"CustomInfo":true,"TransactionId":true,"Email": true,"TelegramHandle": true}}');
    var result = await res.json();
    console.log(result)
    var data = [];
    for (var i = 0; i < result.length; i++) {

      console.log(result[i]);
      let managesell = await getManageSell(result[i].UserWalletAddress);
      result[i].manageSell = managesell;
      data.push(result[i]);
    }
    setContributionData(data)
    setLoading(false)

  }

  const getManageSell = async (walletAddress) => {
    let manageSellData = {
      walletAddress: walletAddress,
      dealId: id
    }
    var response = await fetch('https://backend.bulknetwork.app/manage-sells', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      cache: "no-cache",
      body: JSON.stringify(
        manageSellData
      ),
    });
    var responseJson = await response.json();
    // console.log(responseJson);
    // console.log(responseJson.data.length);
    if (responseJson.data.length > 0) {
      return true;
    } else {
      return false;
    }

  }

  const getDealDetails = async () => {
    // API call to fetch deals
    fetch('https://backend.bulknetwork.app/get-single-deal/' + id + '?filter={"offset":0,"limit":500,"skip":0,"order":"string","fields":{"Name":true,"id":true,"Ticker":true,"TotalRaize":true,"Currency":true,"Start":true,"End":true,"Price":true,"Vesting":true,"DealRate":true,"AllocationForTop10":true,"AllocationForTier1":true,"AllocationForTier2":true,"AllocationForTier3":true,"AllocationForTier4":true,"Description":true,"Icon":true,"CurrentRaize":true,"WalletAddress":true,"VisibleInAll":true,"VisibleInTop10":true,"VisibleInTier1":true,"VisibleInTier2":true,"VisibleInTier3":true,"VisibleInTier4":true,"ManageSell":true,"CustomInfoWindow":true,"CustomInfoText":true,"LastUpdate":true}}').then(res => res.json()).then(
      (result) => {
        // console.log(result.error)
        if (result.success) {
          //set return data into dealsDetails object
          setDealDetails(result.res);
          // console.log(result.res)
        }
      }
    )
  }

  useEffect(() => {
    getDealDetails();
    getContributionData();
  }, [])

  //Methods

  //edit Data
  const editRegistrationData = (data) => {
    setEditData(data)
    setModalEditShow(true)
  }

  //delete Data
  const deleteContributionData = (data) => {
    setEditData(data)
    setModalDeleteShow(true)
  }

  //Model
  function CreateRegistrationModel(props) {

    const contributorDataAPICall = async (txId, dealId, userWalletAddress, totalAmountInvested, contributorType, customInfo, email, telegramHandle) => {
      var contributeData = {
        DealId: dealId,
        UserWalletAddress: userWalletAddress,
        TotalAmountInvested: parseFloat(totalAmountInvested),
        ContributorType: contributorType,
        CustomInfo: customInfo,
        TransactionId: txId,
        Email: email,
        TelegramHandle: telegramHandle
      };
      console.log(contributeData)
      // create call
      await fetch('https://backend.bulknetwork.app/create-contributors', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          contributeData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.error) {
            contributorDataAPICall(dealId, userWalletAddress, totalAmountInvested, contributorType, email, telegramHandle);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const updateCurrentRaize = async (dealId, totalAmountInvested) => {
      let jsonData = {
        CurrentRaize: parseFloat(totalAmountInvested),
        id: dealId
      }
      // console.log(jsonData)
      await fetch('https://backend.bulknetwork.app/update-currentRaize', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          jsonData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const ManageSellAPICall = async (walletAddress, dealId) => {
      await fetch('https://backend.bulknetwork.app/manage-sells?filter={ "offset": 0, "limit": 500, "skip": 0, "order": "string", "where": { "WalletAddress": "' + walletAddress + '", "DealId": "' + dealId + '" },"fields": { "WalletAddress": true, "id": true, "DealId": true } }').then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          // console.log(responseJson.length);
          //already  present
          if (responseJson.length > 0) {
          } else {
            var manageSellData = {
              "WalletAddress": walletAddress,
              "DealId": dealId
            };
            fetch('https://backend.bulknetwork.app/create-manage-sells', {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              cache: "no-cache",
              body: JSON.stringify(
                manageSellData
              ),
            }).then((response) => response.json())
              .then((responseJson) => {
                console.log(responseJson);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });

    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true)
      // console.log(event.target)
      var DealId = id;
      var TransactionId = event.target.transactionIdCreate.value;
      var UserWalletAddress = event.target.walletAddressCreate.value.toLowerCase();
      const TotalAmountInvested = parseFloat(event.target.totalAmountInvestedCreate.value);
      var ContributorType = event.target.contributorTypeCreate.value;
      var CustomInfo = event.target.customInfoCreate.value;
      var Email = event.target.emailCreate.value;
      var TelegramHandle = event.target.telegramHandleCreate.value;
      var manageSellActivated = event.target.manageSellCheckboxCreate.checked;

      //Deal current raize updated
      setDealDetails({...dealDetails, CurrentRaize: dealDetails.CurrentRaize+TotalAmountInvested})
      await updateCurrentRaize(DealId, TotalAmountInvested);
      //Create contribution data
      await contributorDataAPICall(TransactionId, DealId, UserWalletAddress, TotalAmountInvested, ContributorType, CustomInfo, Email, TelegramHandle);
      //create sell
      if (manageSellActivated)
        await ManageSellAPICall(UserWalletAddress, DealId);
      getContributionData();
      toast.success("Contribution created successfully");
      props.onHide();
    }

    return (
      <div className="overlay-popup" style={{ visibility: props.show ? ('initial') : ('hidden') }}>
        <div className="popup-box" style={{ maxWidth: '60%' }}>
          <div className="closed">
            <a style={{ color: 'white', border: 'none' }} onClick={props.onHide}><span style={{ fontSize: '25px' }}>×</span></a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Create Contribution Data</h2>
            </div>
            <Form className="deal-form" onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="transactionIdCreate" id="transactionIdInputCreate">
                <Form.Label column sm="2">Transaction Hash</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="transactionIdCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="walletAddressCreateCreate">
                <Form.Label column sm="2">Wallet Address</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="walletAddressCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="totalAmountInvestedCreateCreate">
                <Form.Label column sm="2">Total Amount Invested</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="number" id="totalAmountInvestedCreate" placeholder="" step="0.005" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="telegramHandleCreateCreate">
                <Form.Label column sm="2">Telegram Handle</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="telegramHandleCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="emailCreateCreate">
                <Form.Label column sm="2">Mail</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="email" id="emailCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="contributorTypeCreate">
                <Form.Label column sm="2">Contributor Type</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="contributorTypeCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="manageSellCreateCreate">
                <Form.Label column sm="2"></Form.Label>
                <Col sm="10">
                  <Form.Check type="checkbox" id="manageSellCheckboxCreate" style={{ color: 'white' }} label="Managed sale activated" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="customInfoCreate" id="customInfoInputCreate">
                <Form.Label column sm="2">Custom Info</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="customInfoCreate" placeholder="" required />
                </Col>
              </Form.Group>
              <div className="footer-popup">
                <Button type="submit" variant="primary">SUBMIT</Button>
              </div>
            </Form>

          </div>
        </div>
      </div>
    );
  }


  function EditRegistrationModel(props) {

    useEffect(() => {
      if (Object.keys(editData).length != 0 && modalEditShow) {
        if (editData.TransactionId)
          document.getElementById("transactionId").value = editData.TransactionId;
        // else
        //   document.getElementById("transactionIdInput").hidden=true;
        document.getElementById("walletAddress").value = editData.UserWalletAddress;
        document.getElementById("totalAmountInvested").value = editData.TotalAmountInvested;
        document.getElementById("contributorType").value = editData.ContributorType;
        document.getElementById("manageSellCheckbox").checked = editData.manageSell;
        document.getElementById("telegramHandle").value = editData.TelegramHandle;
        document.getElementById("email").value = editData.Email;
        if (editData.CustomInfo)
          document.getElementById("customInfo").value = editData.CustomInfo;

      }
    }, [editData])

    const updateDealRaize = (newValue) => {
      if (newValue - editData.TotalAmountInvested != 0) {
        var dealData = dealDetails;
        dealData.CurrentRaize = dealData.CurrentRaize + newValue - editData.TotalAmountInvested;
        delete dealData.id;
        console.log(dealData)
        let token = localStorage.getItem('token');
        fetch('https://backend.bulknetwork.app/update-deals/' + id, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(
            dealData
          ),
        }).then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    //   const updateAccount= async (walletAddress,telegramId,email)=>{
    //     if(editData.walletAddress!=walletAddress || editData.TelegramHandle!=telegramId || editData.Email!=email){
    //       // update to server
    //       let userData={
    //           WalletAddress:walletAddress,
    //           Email:email,
    //           TelegramHandle:telegramId
    //       }
    //       await fetch('https://backend.bulknetwork.app/create-users', {
    //         method: 'post',
    //         headers: { 'Content-Type': 'application/json'
    //             },
    //         body: JSON.stringify(
    //                 userData
    //             ),
    //         }).then((response) => response.json())
    //         .then((responseJson) => {
    //         console.log(responseJson);
    //         })
    //         .catch((error) => {
    //         console.error(error);
    //         });
    //   }
    // }

    const setManageSell = (value, walletAddress) => {
      console.log(value);
      console.log(walletAddress);
      if (value && editData.manageSell == false) {
        //create
        let manageSellData = {
          WalletAddress: walletAddress,
          DealId: id
        }
        fetch('https://backend.bulknetwork.app/create-manage-sells', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          cache: "no-cache",
          body: JSON.stringify(
            manageSellData
          ),
        }).then((response) => response.json())
          .then((responseJson) => {
            // toast.success(responseJson.message);  
            console.log(responseJson);
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (editData.manageSell) {
        //delete
        let manageSellData = {
          walletAddress: walletAddress,
          dealId: id
        }
        fetch('https://backend.bulknetwork.app/manage-sells-delete', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          cache: "no-cache",
          body: JSON.stringify(
            manageSellData
          ),
        }).then((response) => response.json())
          .then((responseJson) => {
            // toast.success(responseJson.message);  
            console.log(responseJson);
          })
          .catch((error) => {
            console.error(error);
          });
      }

    }

    const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(event.target)
      var formData = {
        DealId: id,
        TransactionId: event.target.transactionId.value,
        UserWalletAddress: event.target.walletAddress.value.toLowerCase(),
        TotalAmountInvested: parseFloat(event.target.totalAmountInvested.value),
        ContributorType: event.target.contributorType.value,
        CustomInfo: event.target.customInfo.value,
        Email: event.target.email.value,
        TelegramHandle: event.target.telegramHandle.value
      }

      console.log(formData)
      fetch('https://backend.bulknetwork.app/update-contributors/' + editData.id, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          formData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.success) {
            toast.success(responseJson.message);
            updateDealRaize(parseFloat(event.target.totalAmountInvested.value));
            setManageSell(event.target.manageSellCheckbox.checked, event.target.walletAddress.value);
            getContributionData();
            props.onHide();
          } else
            toast.error(responseJson.error.message)
        })
        .catch((error) => {
          console.error(error);
        });
      let jsonData = {
        dealId: id,
        userWalletAddress: event.target.walletAddress.value,
        customInfo: event.target.customInfo.value
      }
      console.log(jsonData)
      fetch('https://backend.bulknetwork.app/update-custom-data', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          jsonData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          // toast.success("Custom Info updated");
        })
        .catch((error) => {
          console.error(error);
        });
    }


    return (
      <div className="overlay-popup" style={{ visibility: props.show ? ('initial') : ('hidden') }}>
        <div className="popup-box" style={{ maxWidth: '60%' }}>
          <div className="closed">
            <a style={{ color: 'white', border: 'none' }} onClick={props.onHide}><span style={{ fontSize: '25px' }}>×</span></a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Edit Contribution Data</h2>
            </div>
            <Form className="deal-form" onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="transactionId" id="transactionIdInput">
                <Form.Label column sm="2">Transaction Hash</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="transactionId" placeholder="" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="walletAddress">
                <Form.Label column sm="2">Wallet Address</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="walletAddress" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="totalAmountInvested">
                <Form.Label column sm="2">Total Amount Invested</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="number" id="totalAmountInvested" placeholder="" step="0.005" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="telegramHandle">
                <Form.Label column sm="2">Telegram Handle</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="telegramHandle" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label column sm="2">Mail</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="email" id="email" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="contributorType">
                <Form.Label column sm="2">Contributor Type</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="contributorType" placeholder="" required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="manageSell">
                <Form.Label column sm="2"></Form.Label>
                <Col sm="10">
                  <Form.Check type="checkbox" id="manageSellCheckbox" style={{ color: 'white' }} label="Managed sale activated" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="customInfo" id="customInfoInput">
                <Form.Label column sm="2">Custom Info</Form.Label>
                <Col sm="10">
                  <Form.Control className="input-design" type="text" id="customInfo" placeholder="" />
                </Col>
              </Form.Group>
              <div className="footer-popup">
                <Button type="submit" variant="primary">SUBMIT</Button>
              </div>
            </Form>

          </div>
        </div>
      </div>
    );
  }

  function ConfirmationModel(props) {

    //delete method
    const deleteRegisterData = async () => {
      // console.log(id)
      await fetch('https://backend.bulknetwork.app/contributors/' + editData.id, {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => {
        console.log(response);
        if (response.status == 204) {
          updateDealRaize();
          toast.success("deleted Sucessfully")
        }
        else if (response.status == 404)
          toast.success("Already deleted")
        else
          toast.error("Error while deleting data!")
        props.onHide();
        getContributionData();
      })
        .catch((error) => {
          console.error(error);
        });
    }


    const updateDealRaize = async () => {
      var dealData = dealDetails;
      dealData.CurrentRaize = dealData.CurrentRaize - editData.TotalAmountInvested;
      delete dealData.id;
      console.log(dealData)
      let token = localStorage.getItem('token');
      fetch('https://backend.bulknetwork.app/update-deals/' + id, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(
          dealData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          // if(responseJson.success)
          //     toast.success(responseJson.message)
          // else
          //     toast.error(responseJson.error.message)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });

    }

    return (

      <div className="overlay-popup" style={{ visibility: props.show ? ('initial') : ('hidden') }}>
        <div className="popup-box">
          <div className="closed">
            <a style={{ color: 'white', border: 'none' }} onClick={props.onHide}><span style={{ fontSize: '25px' }}>×</span></a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Delete Confirmation</h2>
            </div>

            <p style={{ textAlign: "center", color: "white" }}>{props.message}</p>
            <div className="footer-popup">
              <Button onClick={() => deleteRegisterData()}>Yes</Button>
              <Button onClick={props.onHide}>Close</Button>
            </div>

          </div>
        </div>
      </div>
    );
  }

  function DeleteAllModel(props) {

    //delete method
    const deleteContributionData = async (contributionId) => {
      // console.log(id)
      await fetch('https://backend.bulknetwork.app/contributors/' + contributionId, {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => {
        console.log(response);
        if (response.status == 204) { }
        else if (response.status == 404)
          toast.success("Already deleted")
        else
          toast.error("Error while deleting data!")
        props.onHide();
        getContributionData();
      })
        .catch((error) => {
          console.error(error);
        });
    }

    const updateDealRaize = async () => {
      var dealData = dealDetails;
      dealData.CurrentRaize = 0;
      delete dealData.id;
      console.log(dealData)
      let token = localStorage.getItem('token');
      fetch('https://backend.bulknetwork.app/update-deals/' + id, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(
          dealData
        ),
      }).then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
        })
        .catch((error) => {
          console.error(error);
        });

    }

    const DeleteAll = async () => {
      setLoading(true);
      for (let i = 0; i < contributionData.length; i++) {
        await deleteContributionData(contributionData[i].id);
      }
      await updateDealRaize();
      setLoading(false);
      toast.success("All data deleted successfully!");
    }

    return (

      <div className="overlay-popup" style={{ visibility: props.show ? ('initial') : ('hidden') }}>
        <div className="popup-box">
          <div className="closed">
            <a style={{ color: 'white', border: 'none' }} onClick={props.onHide}><span style={{ fontSize: '25px' }}>×</span></a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Delete Confirmation</h2>
            </div>

            <p style={{ textAlign: "center", color: "white" }}>Are you sure you want to delete all?</p>
            <div className="footer-popup">
              <Button onClick={() => DeleteAll()}>Yes</Button>
              <Button onClick={props.onHide}>Close</Button>
            </div>

          </div>
        </div>
      </div>
    );
  }


  return (<>
    {authorize ? (
      <div className={loading ? 'parentDisable' : ''} width="100%" style={{ padding: '1%' }}>
        <div className={loading ? 'overlay-box' : 'hide'}>
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>

        <br />
        <div className="deal-form-page">
          <Container>
            <>
              <CreateRegistrationModel
                show={modalCreateShow}
                onHide={() => setModalCreateShow(false)}
              />
              <EditRegistrationModel
                show={modalEditShow}
                onHide={() => setModalEditShow(false)}
              />
              <ConfirmationModel
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                message="Are you sure you want to delete this?"
              />
              <DeleteAllModel
                show={modalDeleteAllShow}
                onHide={() => setModalDeleteAllShow(false)}
              />
            </>
            <h1 className="page-heading">Contribution Data</h1> 
            <span style={{ color: 'white' }}>Total Raize: {dealDetails.CurrentRaize}</span>
            <Button variant="danger" style={{ margin: '0px 0px 10px 10px', float: 'right' }}
              onClick={() => setModalDeleteAllShow(true)}>Delete all contribution</Button>
            <Button style={{ float: 'right' }} onClick={() => setModalCreateShow(true)}>Add new contribution</Button>

            <br />
            <table className="table table-dark table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th width="10%">Transaction Hash</th>
                  <th width="10%">Wallet Address</th>
                  <th>Total Amount Invested</th>
                  <th>Telegram Handle</th>
                  <th>Mail</th>
                  <th>Contributor Type</th>
                  <th>Managed sale activated (Yes/No)</th>
                  <th>Custom Info</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {contributionData.map((data, key) => {
                  return (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{data.TransactionId}</td>
                      <td>{data.UserWalletAddress}</td>
                      <td>{data.TotalAmountInvested}</td>
                      <td>{data.TelegramHandle}</td>
                      <td>{data.Email}</td>
                      <td>{data.ContributorType}</td>
                      <td>{data.manageSell ? "Yes" : "No"}</td>
                      <td>{data.CustomInfo}</td>
                      <td>
                        <Button onClick={() => editRegistrationData(data)}>Edit</Button>
                        <Button variant="danger" style={{ marginTop: '10px' }} onClick={() => deleteContributionData(data)}>Delete</Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        </div>
      </div>

    ) : (
      <Redirect to="/login"></Redirect>
    )}

  </>)
}
export default ContributionEdit
