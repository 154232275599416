import Web3 from 'web3';
import {addressBulk,abiBulk} from './BSCContractData';

const providerBsc= new Web3.providers.HttpProvider(
    'https://bsc-rpc.publicnode.com/'
);
const web3Bsc = new Web3(providerBsc);

// web3Bsc.eth.net.getNetworkType().then(console.log);//BSC Network

export default new web3Bsc.eth.Contract(abiBulk,addressBulk);
