import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { isActive } from "../../pages/utils";

const Sidebar = (props) => {
  const [dealsDetails, setDealsDetails] = useState([
    {
      Name: "-",
      id: null,
      Ticker: "-",
      TotalRaize: 0,
      Currency: "-",
      Start: "",
      End: "",
      AllocationForTier1: 0,
      AllocationForTier4: 0,
      Icon: "",
      CurrentRaize: 0,
      VisibleInAll: true,
    },
  ]); //Deatils of deal
  const [myAllocation, setMyAllocation] = useState(0);
  const [myContribution, setMyContribution] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmountArray, setTotalAmountArray] = useState([]);
  const [noOfContributionArray, setNoOfContributionArray] = useState([]);

  const getDeal = async () => {
    let filter = "VisibleInAll";
    switch (props.walletData.tier) {
      case 1:
        filter = "VisibleInTier1";
        break;
      case 2:
        filter = "VisibleInTier2";
        break;
      case 3:
        filter = "VisibleInTier3";
        break;
      case 4:
        filter = "VisibleInTier4";
        break;
    }
    await fetch("https://bulkstaking.ew.r.appspot.com/process")
      .then((res) => res.json())
      .then((result) => {
        // console.log(result.tops);
        result.tops.forEach(function (item, index) {
          if (props.walletData.walletAddress == item.toLowerCase()) {
            filter = "VisibleInTop10";
          }
        });
      });

    await fetch(
      'https://backend.bulknetwork.app/get-all-deals?filter={"offset": 0,"order": "string","where": {"additionalProp1": {}},"fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"AllocationForTier1": true,"AllocationForTier2": true,"AllocationForTier3": true,"AllocationForTier4": true,"CurrentRaize": true,"VisibleInAll": true,"' +
        filter +
        '": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result)
        if (result.success) {
          result.res.some(function (item, index) {
            if (
              isActive(item.Start, item.End) &&
              (item[filter] || item.VisibleInAll)
            ) {
              setDealsDetails(item);
              return true;
            }
          });
        }
      });
  };

  const getDealLogo = async () => {
    if (dealsDetails.id) {
      let res = await fetch(
        "https://backend.bulknetwork.app/get-single-deal/" +
          dealsDetails.id +
          '?filter={"fields": {"Icon": true}}'
      );
      let result = await res.json();
      document.getElementById("sidebarDealLogo").hidden = true;
      if (result.success) {
        document.getElementById("sidebarDealLogo").src =
          "data:image/png;base64," + result.res.Icon;
        document.getElementById("sidebarDealLogo").hidden = false;
      }
    }
  };

  useEffect(() => {
    setSelectPoint();
    setData();
    getDealLogo();
  }, [dealsDetails]);

  useEffect(() => {
    getDeal();
    if (props.walletData.isConnected) {
      setData();
    } else {
      setMyContribution(0);
      setMyAllocation(0);
    }
  }, [props.walletData]);

  const setData = async () => {
    setTotal(dealsDetails.CurrentRaize);
    if (dealsDetails.id == null) {
      setTotalAmountArray([0, 0, 0, 0, 0]);
      setNoOfContributionArray([0, 0, 0, 0, 0]);
      return;
    }

    let contributeData = {
      dealId: dealsDetails.id,
      userWalletAddress: props.walletData.walletAddress,
    };
    // console.log(JSON.stringify(contributeData));
    //API call for check data is present
    await fetch("https://backend.bulknetwork.app/get-contributions", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      cache: "no-cache",
      body: JSON.stringify(contributeData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        //already  present
        if (responseJson.success) {
          // set Value
          setMyContribution(responseJson.data.TotalAmountInvested);
        } else {
          setMyContribution(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // console.log(props.walletData.tier);
    let result = 0;
    //Set according to its tier
    switch (props.walletData.tier) {
      case 1:
        result = dealsDetails.AllocationForTier1;
        break;
      case 2:
        result = dealsDetails.AllocationForTier2;
        break;
      case 3:
        result = dealsDetails.AllocationForTier3;
        break;
      case 4:
        result = dealsDetails.AllocationForTier4;
        break;
      default:
        result = 0;
    }
    setMyAllocation(result);

    //Set the Bar chart data
    var amountArray = [],
      contributionArray = [];

    var filter = ["Top10", "Tier1", "Tier2", "Tier3", "Tier4"];

    for (let i = 0; i < filter.length; i++) {
      let parameters = {
        dealId: dealsDetails.id,
        contributorType: filter[i],
      };
      // console.log(JSON.stringify(contributeData));
      //API call for check data is present
      await fetch("https://backend.bulknetwork.app/get-contribution-amount", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        cache: "no-cache",
        body: JSON.stringify(parameters),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.success) {
            amountArray.push(responseJson.data);
          } else {
            amountArray.push(0);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      //get No. of contribution
      let parameterUrl = {
        DealId: dealsDetails.id,
        ContributorType: filter[i],
      };
      fetch(
        "https://backend.bulknetwork.app/contributors/count?where=" +
          JSON.stringify(parameterUrl)
      )
        .then((res) => res.json())
        .then((result) => {
          // console.log(result.count);
          contributionArray.push(result.count);
        });
    }

    setTotalAmountArray(amountArray);
    setNoOfContributionArray(contributionArray);
  };

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }
  //functions
  const setSelectPoint = () => {
    if (dealsDetails.TotalRaize >= 0 && dealsDetails.CurrentRaize >= 0) {
      var max = dealsDetails.TotalRaize;
      //Set Labels
      var increment = max / 5;
      for (var j = 1; j <= 5; j++) {
        var label = increment * j;
        document.getElementById("pointer-labels" + j).innerHTML = nFormatter(
          label.toFixed(1)
        );
      }

      var value = dealsDetails.CurrentRaize;
      var difference = increment / 5;
      if (value <= increment) {
        document.getElementById("pinPoint").style.left =
          value / (increment / 30) + "px"; //30px
      } else if (value > increment && value <= increment * 2) {
        document.getElementById("pinPoint").style.left =
          30 + ((value - increment) / difference) * 13.2 + "px"; //96px
      } else if (value > increment * 2 && value <= increment * 3) {
        document.getElementById("pinPoint").style.left =
          96 + ((value - increment * 2) / difference) * 12.8 + "px"; //160px
      } else if (value > increment * 3 && value <= increment * 4) {
        document.getElementById("pinPoint").style.left =
          160 + ((value - increment * 3) / difference) * 14 + "px"; //230px
      } else if (value > increment * 4 && value <= increment * 5) {
        document.getElementById("pinPoint").style.left =
          230 + ((value - increment * 4) / difference) * 14 + "px"; //290px
      } else if (value > increment * 5 && value <= max) {
        document.getElementById("pinPoint").style.left =
          320 + ((value - max) / difference) * 14 + "px"; //320px
      }
    }
  };

  return (
    <>
      <Col md={3} className="side-bar">
        <div className="btn-rw btn-rw-desktop">
          <a href="https://forms.gle/wk1G9aokHMbADPAm6" target="_blank">
            <button className="btn-main"> Log support ticket</button>
          </a>
          <a href="https://forms.gle/96LVzB1FcTZiew4W6" target="_blank">
            <button className="white-btn"> Suggest project</button>
          </a>
        </div>
        <div className="bar-box">
          <div className="bar-heading">
            <h3>{dealsDetails.Name}</h3>{" "}
            <img id="sidebarDealLogo" hidden={true} />
          </div>
          <div className="bar-cnt">
            <h3>
              {" "}
              <span style={{ fontSize: "12px" }}>{dealsDetails.Currency} </span>
              {dealsDetails.TotalRaize}
            </h3>
            <p></p>
          </div>

          <div class="line-chart">
            <svg
              width="100%"
              height="204"
              viewBox="0 0 340 204"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 63.1842C0 59.5218 1.32457 55.984 3.93445 53.4147C11.0146 46.4445 26.4302 33 40 33C65.5 33.0001 77.0923 91.4999 98.5 91.5C119.908 91.5001 131 54 153 54C175 54 183.02 77.0859 214 77.0001C244.98 76.9143 256.02 45.0002 287 45.0001C313.846 45 333.963 85.9232 338.854 96.8468C339.641 98.6045 340 100.49 340 102.416V189C340 197.284 333.284 204 325 204H15C6.71572 204 0 197.284 0 189V63.1842Z"
                fill="url(#paint0_linear_2_204)"
              />
              <path
                opacity="0.1"
                d="M0 47.1842C0 43.5218 1.32457 39.984 3.93445 37.4147C11.0146 30.4445 26.4302 17 40 17C65.5 17.0001 77.0923 75.4999 98.5 75.5C119.908 75.5001 131 38 153 38C175 38 183.02 61.0859 214 61.0001C244.98 60.9143 256.02 29.0002 287 29.0001C313.846 29 333.963 69.9232 338.854 80.8468C339.641 82.6045 340 84.49 340 86.4158V173C340 181.284 333.284 188 325 188H15C6.71572 188 0 181.284 0 173V47.1842Z"
                fill="url(#paint1_linear_2_204)"
              />
              <path
                opacity="0.1"
                d="M0 30.1842C0 26.5218 1.32457 22.984 3.93445 20.4147C11.0146 13.4445 26.4302 -3.16047e-05 40 1.84243e-09C65.5 5.9396e-05 77.0923 58.4999 98.5 58.5C119.908 58.5001 131 21 153 21C175 21 183.02 44.0859 214 44.0001C244.98 43.9143 256.02 12.0002 287 12.0001C313.846 12 333.963 52.9232 338.854 63.8468C339.641 65.6045 340 67.49 340 69.4158V156C340 164.284 333.284 171 325 171H15C6.71572 171 0 164.284 0 156V30.1842Z"
                fill="url(#paint2_linear_2_204)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2_204"
                  x1="6.73268"
                  y1="96.5143"
                  x2="335.968"
                  y2="113.964"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#7C6ADD" />
                  <stop offset="1" stop-color="#617EEB" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2_204"
                  x1="6.73268"
                  y1="80.5143"
                  x2="335.968"
                  y2="97.9642"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#7C6ADD" />
                  <stop offset="1" stop-color="#617EEB" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_2_204"
                  x1="6.73268"
                  y1="63.5143"
                  x2="335.968"
                  y2="80.9642"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#7C6ADD" />
                  <stop offset="1" stop-color="#617EEB" />
                </linearGradient>
              </defs>
            </svg>
            <div class="select-point" id="pinPoint">
              <svg
                className="slt-crl"
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
              </svg>

              <svg
                className="chart-ln"
                width="1"
                height="80"
                viewBox="0 0 1 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="80"
                  x2="0.499997"
                  y2="2.18557e-08"
                  stroke="url(#paint0_linear_2_214)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2_214"
                    x1="1.00018"
                    y1="-8.9184e-06"
                    x2="1.00009"
                    y2="74.8148"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.7" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="number-cnt">
              <div className="numb-p" id="pointer-labels1"></div>
              <div className="numb-p" id="pointer-labels2"></div>
              <div className="numb-p" id="pointer-labels3"></div>
              <div className="numb-p" id="pointer-labels4"></div>
              <div className="numb-p" id="pointer-labels5"></div>
            </div>
          </div>
        </div>{" "}
        {/* bar box closed */}
        <div className="bar-box">
          <div className="bar-heading">
            <h3 style={{ marginBottom: "10px" }}>Contributor’s statistics</h3>
          </div>

          <Bar
            data={{
              labels: ["Top10", "Tier1", "Tier2", "Tier3", "Tier4"],
              datasets: [
                {
                  label: "Total Amount",
                  yAxisID: "A",
                  data: totalAmountArray,
                  backgroundColor: "#7C6BDE",
                  borderWidth: 1,
                  barThickness: 5,
                  color: "#fff",
                },
                {
                  label: "No. of Contributors",
                  yAxisID: "B",
                  data: noOfContributionArray,
                  backgroundColor: "#617EEB",
                  borderWidth: 1,
                  barThickness: 5,
                  color: "#fff",
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                  color: "#fff",
                },
              },
              scales: {
                A: {
                  type: "linear",
                  position: "left",
                },
                B: {
                  type: "linear",
                  position: "right",
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              },
            }}
          />
          <div className="bar-cnt d-flex">
            <p style={{ marginLeft: "40px" }}></p>
            <p className="tm">
              <i class="fa fa-circle" aria-hidden="true"></i> Total amount
            </p>
            <p className="nc">
              <i class="fa fa-circle" aria-hidden="true"></i> Nr. of
              contributors
            </p>
          </div>
        </div>{" "}
        {/* bar box closed */}
        <div className="bar-box">
          <div className="bar-heading">
            <h3>Contributor’s statistics</h3>
          </div>
          <Row>
            <Col md={4}>
              <div className="progress-div">
                <div className="progress-div-data">
                  <p>
                    {myAllocation - myContribution}/<br />
                    {myAllocation}
                  </p>
                </div>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        label: "My Allocation",
                        data: [myContribution, myAllocation - myContribution],
                        backgroundColor: ["#617EEB", "rgba(46, 53, 96, 1)"],
                        borderWidth: 0,
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    cutout: "80%",
                    events: [],
                  }}
                />
              </div>
              <p
                className="progress-heading show-mobile"
                style={{ marginTop: "100%" }}
              >
                My Allocation
              </p>
            </Col>

            <Col md={4}>
              <div className="progress-div">
                <div className="progress-div-data">
                  <p>
                    {myContribution}/<br />
                    {myAllocation}
                  </p>
                </div>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        label: "My Contribution",
                        data: [myContribution, myAllocation - myContribution],
                        backgroundColor: ["#FFD700", "rgba(46, 53, 96, 1)"],
                        borderWidth: 0,
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    cutout: "80%",
                    events: [],
                  }}
                />
              </div>
              <p
                className="progress-heading show-mobile"
                style={{ marginTop: "100%" }}
              >
                My Contribution
              </p>
            </Col>

            <Col md={4}>
              <div className="progress-div">
                <div className="progress-div-data">
                  <p>
                    {myContribution}/<br />
                    {total}
                  </p>
                </div>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        label: "My Contribution vs Total",
                        data: [myContribution, total - myContribution],
                        backgroundColor: ["#7C6BDE", "rgba(46, 53, 96, 1)"],
                        borderWidth: 0,
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    cutout: "80%",
                    events: [],
                  }}
                />
              </div>
              <p
                className="progress-heading show-mobile"
                style={{ marginTop: "100%" }}
              >
                My Contribution vs Total
              </p>
            </Col>
          </Row>
          <Row className="none-mb">
            <Col md={4} style={{ padding: "10px 0px 0px 0px" }}>
              <p className="progress-heading">My Allocation</p>
            </Col>
            <Col md={4} style={{ padding: "10px 0px 0px 0px" }}>
              <p className="progress-heading">My Contribution</p>
            </Col>
            <Col md={4} style={{ padding: "10px 0px 0px 0px" }}>
              <p className="progress-heading">
                My Contribution <br />
                vs Total
              </p>
            </Col>
          </Row>
        </div>{" "}
        {/* bar box closed */}
      </Col>{" "}
      {/* sidebar closed */}
    </>
  );
};
export default Sidebar;
