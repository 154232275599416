import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "./admin.css";

const UpdateAdmin = ({ authorize }) => {
  const [loading, setLoading] = useState(false); //Loading bar
  // jump to contribute page
  const history = useHistory();
  let userData = {};

  useEffect(async () => {
    let token = localStorage.getItem("token");
    let data = { tokenData: token };
    console.log(data);
    await fetch("https://backend.bulknetwork.app/get-profile-by-token", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          userData = responseJson.data;
          // console.log('Data fetched')
        } else {
          //redirecct to login
          history.push("/login");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const validatePassword = (password1, password2) => {
    if (password1.length >= 8) {
      if (password1 === password2) {
        return true;
      } else {
        toast.error("Passowrd should be same");
      }
    } else {
      toast.error("Passowrd should be more than 8 characters");
    }
    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (
      validatePassword(
        event.target.confirmPassword.value,
        event.target.password.value
      )
    ) {
      let token = localStorage.getItem("token");
      userData.password = event.target.confirmPassword.value;
      // console.log(userData)
      fetch("https://backend.bulknetwork.app/update-user/" + userData.id, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.success) toast.success(responseJson.message);
          else toast.error(responseJson.error.message);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else setLoading(false);
  };
  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <div className="dashboard">
            <Container className="maine-content">
              <h2 className="page-heading">Update Password</h2>

              <Form className="deal-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label for="password">New Passowrd</Form.Label>
                      <Form.Control
                        type="text"
                        id="password"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="confirmPassword">
                      <Form.Label for="confirmPassword">
                        Confirm Passowrd
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="confirmPassword"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="submit-btn">
                    <Button type="submit" disabled={loading}>
                      Update Password
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default UpdateAdmin;
