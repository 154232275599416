import React, { useEffect, useState } from 'react';
import {Button, Spinner, Modal} from "react-bootstrap";
import { toast } from 'react-toastify'; //toster
import Web3 from 'web3';

import ETHBulkContract from '../contracts/ETHBulkContract';
import BSCBulkContract from '../contracts/BSCBulkContract';
import BSCFarmingContract from '../contracts/BSCFarmingContract';

import ETHimage from '../assects/ethereum.svg'
import BSCimage from '../assects/binance.svg'
import DownArrow from '../assects/down-arrow.svg'

let web3;

const  ConnectWallet=(props)=> {
  const [loading,setLoading] = useState(false);//Loading bar
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(()=>{
    if(window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      })
      window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        connect();
      })
    }
    //already connected   //window.ethereum.isConnected()
    if(!props.walletData.isConnected){
      if(sessionStorage.getItem('walletConneted')=="true"){
        connect();
      }
    }
  },[])

    async function connect(){
      setLoading(true)
      //connect to web3
      if(typeof window.ethereum !== 'undefined'){
        //&& window.ethereum.isMetaMask
        //we are in the browser and metamask is running
          //ETH test net 3,  // mainnet 1
          // BSC test net 97  //mainnet 56
          window.web3 = new Web3(window.ethereum);//set window.web3 object properly
        web3 = new Web3(window.ethereum);
        let netId = await web3.eth.net.getId();
        var networkName="";
        console.log(netId);
        switch (netId) {
          case 1:
            networkName='Eth'
            console.log('Network is Ethereum mainnet')
            break
          case 56:
            networkName='BSC'
            console.log('Network is BSC mainnet')
            break
          // case 3:
          //   networkName='Eth'
          //   console.log('Network is Ethereum Ropstern test network')
          //   break
          // case 97:
          //   networkName='BSC'
          //   console.log('Network is BSC mainnet')
          //   break
          default:
            toast.error('Please select mainnet first')
            setLoading(false)
            return;
        }
        //set details
        //log network
        // web3.eth.net.getNetworkType().then(console.log);
        let accounts;
        try{
          accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch(error){
          if(error.code===4001){
            toast.error("Please allow metamask to connect")
            }
            setLoading(false)
            return;
          // console.log(error)
        }
        // console.log(accounts)
        if(accounts.length<1){
          setLoading(false)
          toast.error('Please unlock metamask wallet first!')
          return;
        }
        let weiBalance= await web3.eth.getBalance(accounts[0]);
        let balance=truncate(web3.utils.fromWei(weiBalance, 'ether'),4);
        // console.log(balance)
        // console.log('Checking tier');
        var token=0,tokenEth=0,tokenBSC=0, totalFarmingTokens=0;

        let errorCall=false
        do {
          try{
          tokenEth = await ETHBulkContract.methods.balanceOf(accounts[0]).call();//0x68fe5fcccc6504fae59f4b8a52ea06707cfd088e
          tokenBSC = await BSCBulkContract.methods.balanceOf(accounts[0]).call();
          totalFarmingTokens = await BSCFarmingContract.methods.balanceOf(accounts[0]).call();//"0x5c7b2970870ab0b8d6289da8acf61cf797bbd05e"
          // console.log("You have\n TokenEth "+tokenEth+"\nTokenBSC "+ tokenBSC+"\ntotalFarmingTokens "+totalFarmingTokens);
          tokenEth=web3.utils.fromWei(tokenEth, 'ether');
          tokenBSC=web3.utils.fromWei(tokenBSC, 'ether');
          totalFarmingTokens=web3.utils.fromWei(totalFarmingTokens, 'ether');
          // console.log("You have\n TokenEth "+tokenEth+"\nTokenBSC "+ tokenBSC+"\ntotalFarmingTokens "+totalFarmingTokens);
          token = parseFloat(tokenEth) + parseFloat(tokenBSC) + parseFloat(totalFarmingTokens);
          // console.log("You have\n TokenEth "+tokenEth+"\nTokenBSC "+ tokenBSC);
          errorCall=false
          }catch(error){
            console.log(error)
            errorCall=true
          }
        } while(errorCall)  //handle network issue while call
        
        let tier = 0
        if(token>=1000000){
          tier=1
        } else if(token>=500000){
          tier=2
        } else if(token>=100000){
          tier=3
        } else if(token>=10000){
          tier=4
        }
        props.setWalletData({walletAddress:accounts[0],balance:balance,isConnected:true,networkName:networkName,ETHTokenCount:parseFloat(tokenEth),
        BSCTokenCount:parseFloat(tokenBSC),TotalTokenCount:token,TotalFarmingTokens:totalFarmingTokens,tier:tier,web3:web3})
        sessionStorage.setItem('walletConneted',true)
      } else{
        //on  the browser or user is not running metamask
        toast.error("Metamask wallet not found! Please make sure wallet is installed and running!");
      }
      setLoading(false)
    }

    async function disconnect(){
      setLoading(true)
      //Disconnect
      sessionStorage.setItem('walletConneted',false)
      props.setWalletData({walletAddress:'',balance:0,isConnected:false,networkName:'',ETHTokenCount:0,
      BSCTokenCount:0,TotalTokenCount:0,TotalFarmingTokens:0,tier:0,web3:null})
      setLoading(false)
    }

    //Show balance with 4 decimal places
    function truncate(str, maxDecimalDigits) {
        if (str.includes('.')) {
            const parts = str.split('.');
            return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
        }
        return str;
    }

    return (
      <>
      {
        loading?(
            <Spinner style={{float:'right'}} animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
        ):(<>
          { props.walletData.isConnected?(
            <>
            <div className="finished-row finished" style={{float:'right',width:'fit-content'}} onClick={() => setModalShow(true)}>
            <span className="icon blue-bg"> 
              {props.walletData.networkName=="Eth"?
                (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#627EEA"/>
                <path d="M10.3116 2.49805V8.04206L14.9973 10.1361L10.3116 2.49805Z" fill="white" fill-opacity="0.602"/>
                <path d="M10.3117 2.49805L5.62604 10.1361L10.3117 8.04206V2.49805Z" fill="white"/>
                <path d="M10.3116 13.7279V17.495L15 11.0078L10.3116 13.7279Z" fill="white" fill-opacity="0.602"/>
                <path d="M10.3117 17.495V13.7279L5.62604 11.0078L10.3117 17.495Z" fill="white"/>
                <path d="M10.3116 12.8571L14.9973 10.137L10.3116 8.04297V12.8571Z" fill="white" fill-opacity="0.2"/>
                </svg>):
                (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.28109 5.88291L7 3.164L9.7202 5.88409L11.3022 4.30209L7 0L2.6992 4.3008L4.28114 5.8828L4.28109 5.88291ZM0 7L1.58206 5.41772L3.164 6.99966L1.58194 8.58172L0 7ZM4.28109 8.11726L7 10.836L9.72014 8.11597L11.303 9.69713L11.3022 9.69797L7 14L2.6992 9.6992L2.69696 9.69696L4.28126 8.11709L4.28109 8.11726ZM10.836 7.00067L12.4181 5.41862L14 7.00056L12.418 8.58262L10.836 7.00067Z" fill="white"/>
                <path d="M8.60445 6.99983H8.60512L6.99993 5.39453L5.81346 6.58067L5.67716 6.71703L5.39604 6.9982L5.3938 7.00039L5.39604 7.00268L6.99993 8.6068L8.60523 7.00151L8.60602 7.00061L8.60456 6.99983" fill="white"/>
                </svg>)}
            </span>
                {props.mobileView?<><i class="fa fa-angle-down" style={{color:'white'}}></i></>:
                  <>
                    <span></span> 
                    <p>{props.walletData.networkName} : {props.walletData.balance}</p>
                    <div className="fin-right">
                      <p>Address : </p><span>{props.walletData.walletAddress.substr(0,5)}...{props.walletData.walletAddress.slice(-3)}
                      &ensp; <img height="15pts" src={DownArrow}/></span>
                    </div>
                  </>
                }
              </div>
            {/* Popup */}
                <div className="overlay-popup" style={{display:modalShow?('flex'):('none')}}>
                <div className="popup-box" style={{width:'95%'}}>
                <div className="closed">
                <a style={{color:'white',border:'none'}} onClick={() => setModalShow(false)}><span style={{fontSize:'25px'}}>×</span></a>
                </div>
                
                <div className="popup-body" style={{padding:'30px 30px 0'}}>
                <div className="popup-header">
                    <h2>Wallet Details</h2> 
                    <p style={{fontSize:'14px'}}>
                      <b>Address:</b> <span class="amount-waddres">{props.walletData.walletAddress}</span>
                    </p>
                    <p style={{fontSize:'14px'}}>
                      <b>Balance:</b> <span style={{fontSize:'14px'}} class="amount">{props.walletData.balance}&ensp;{props.walletData.networkName}</span>
                    </p>
                </div>  
                <div className="footer-popup">
                  <button className="btn-main cnt-button" style={{fontSize:'14px',height:'45px'}} onClick={disconnect}>Disconnect</button>
                </div>
                </div>
                </div>
                </div>
            </>
          ):(
            <button style={{float:'right'}} className="btn-main btn-main-gray" variant="secondary" onClick={connect}>Connect Wallet</button>        
          )}</>
        )
      }
      </>
    );
  }
  
  export default ConnectWallet
  