import Web3 from 'web3';
import {addressUSDTToken,abiUSDTToken} from './BSCContractData';

let web3Bsc;
let BSCUSDTContract;

if(typeof window.ethereum !== 'undefined'){
    web3Bsc = new Web3(window.ethereum);
    // web3Bsc.eth.net.getNetworkType().then(console.log);//BSC Network
    BSCUSDTContract=new web3Bsc.eth.Contract(abiUSDTToken,addressUSDTToken);
} else{
    //on  the browser or user is not running metamask
    console.log("Metamask wallet not found! Please make sure wallet is installed and running!");
}

export default BSCUSDTContract;
