import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Form,
  Spinner,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import "./admin.css";

const ManageSell = ({ authorize }) => {
  const { id } = useParams();
  const [manageSellData, setManageSellData] = useState([{}]);
  const [loading, setLoading] = useState(false); //Loading bar

  const getManageSellData = async () => {
    setLoading(true);

    let allMagageSellWallets;
    //API call for check Manage Sell is present
    let manageSellDataJson = {
      dealId: id,
    };
    await fetch("https://backend.bulknetwork.app/manage-sells", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      cache: "no-cache",
      body: JSON.stringify(manageSellDataJson),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        allMagageSellWallets = responseJson.data;
      })
      .catch((error) => {
        console.error(error);
      });

    // API call to fetch each wallet details
    let jsonData = [];
    console.log(allMagageSellWallets);
    for (let i = 0; i < allMagageSellWallets.length; i++) {
      var parameters = {
        walletAddress: allMagageSellWallets[i].WalletAddress,
      };
      console.log(parameters);
      await fetch("https://backend.bulknetwork.app/get-one", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(parameters),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson.data);
          if (responseJson.success) {
            jsonData.push(responseJson.data);
          } else {
            var data = {
              WalletAddress: allMagageSellWallets[i].WalletAddress,
              Email: "-",
              TelegramHandle: "-",
            };
            jsonData.push(data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    console.log(jsonData);
    setManageSellData(jsonData);
    setLoading(false);
  };

  useEffect(() => {
    getManageSellData();
  }, []);

  //Methods
  //delete method
  const deleteRegisterData = async (WalletAddress) => {
    let parameters = {
      walletAddress: WalletAddress,
      dealId: id,
    };
    await fetch("https://backend.bulknetwork.app/manage-sells-delete", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(parameters),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          toast.success(responseJson.message);
        } else {
          toast.error(responseJson.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    getManageSellData();
  };

  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <br />
          <div className="deal-form-page">
            <Container>
              <h1 className="page-heading">Manage Sell</h1>
              <br />
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Wallet Address</th>
                    <th>Email</th>
                    <th>Telegram Handle</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {manageSellData.map((data, key) => {
                    return (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{data.WalletAddress}</td>
                        <td>{data.Email}</td>
                        <td>{data.TelegramHandle}</td>
                        <td>
                          <Button
                            variant="danger"
                            style={{ float: "right" }}
                            onClick={() =>
                              deleteRegisterData(data.WalletAddress)
                            }
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default ManageSell;
