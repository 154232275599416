import Web3 from 'web3';
import {addressUSDTToken,abiUSDTToken} from './ETHContractData';

let web3Eth
let ETHUSDTContract;
if(typeof window.ethereum !== 'undefined'){
    web3Eth = new Web3(window.ethereum);    
    // web3Eth.eth.net.getNetworkType().then(alert);//ETH Network
    ETHUSDTContract=new web3Eth.eth.Contract(abiUSDTToken,addressUSDTToken);
} else{
    //on  the browser or user is not running metamask
    alert("Metamask wallet not found! Please make sure wallet is installed and running!");
}
export default ETHUSDTContract;
