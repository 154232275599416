
//returns true if deal is finished 
//parameter end date return boolean
export function isFinished(endDate){
    //consition for check
    if(parseDate(endDate)<new Date())
        return true;
    return false;
}

//returns true if deal is active 
//parameter start and end date return boolean
export function isActive(startDate, endDate){
    //consition for check
    if(parseDate(startDate)<new Date() && parseDate(endDate)>new Date())
        return true;
    return false;
}

//returns true if deal is upcoming 
//parameter end date return boolean
export function isUpcoming(startDate){
    //consition for check
    if(parseDate(startDate)>new Date())
        return true;
    return false;
}


export function getDealStauts(startDate, endDate){
    if(isFinished(endDate)){
        return (<p class="price-chart"><i class="fa fa-circle yellow-text" aria-hidden="true"></i>Finished</p>);
    } else if(isUpcoming(startDate)){
        return (<p class="price-chart"><i class="fa fa-circle yellow-text" aria-hidden="true"></i> Start in {getDealGoesLiveIn(startDate)}</p>);
    } else if(isActive(startDate,endDate)){
        return (<p class="price-chart"><i class="fa fa-circle yellow-text" aria-hidden="true"></i> End in {getDealEndIn(endDate)}</p>);
    }
    return (<p class="price-chart"></p>);
}

export function getDealGoesLiveIn(startDate){
    let result='';
    if(parseDate(startDate)<new Date()){
        result='Started';
    } else if(startDate){
        let t=getDayAndHours(parseDate(startDate),new Date());
        //return days and hours
        if(t[0]<=0){ //if days <=0
            result=t[1]+'h '+t[2]+'m '+t[3]+'s';
        } else{
            result=t[0]+'d '+t[1]+'h';
        }
    }
    return result;
}

export function getDealEndIn(endDate){
    let result='';
    if(parseDate(endDate)<new Date()){
        result='Finished';
    } else if(endDate){
        let t=getDayAndHours(parseDate(endDate),new Date());
        //return days and hours
        if(t[0]<=0){ //if days <=0
            result=t[1]+'h '+t[2]+'m '+t[3]+'s';
        } else{
            result=t[0]+'d '+t[1]+'h';
        }
    }
    return result;
}

export function parseDate(str_date) {
    return new Date(Date.parse(str_date));
  }

  function getDayAndHours(dateFuture,dateNow){
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // console.log('minutes', minutes);

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds) % 60;
    // console.log('seconds', seconds);

    return [days,hours,minutes,seconds]
  }

  export function formatNumber(num) {
    if(num!==undefined)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    return num;
}
