import Web3 from 'web3';
import {addressBulk,abiBulk} from './ETHContractData';


let ETHBulkContract;
if(typeof window.ethereum !== 'undefined'){
    
    let providerEth;
    if(window.ethereum.networkVersion=='1'){
        providerEth=window.ethereum;
        console.log('using metamask url');
    } else{
        providerEth= new Web3.providers.HttpProvider(
            'https://mainnet.infura.io/v3/12db852b0e8849a1b5ab0abc0822206d'
            //'https://main-light.eth.linkpool.io/' 
            //'https://ropsten.infura.io/v3/9a18e0e530714fcf9cd0ccd5beedc4c4'
        );
        console.log('using rinkyby url');
    }
    const web3Eth = new Web3(providerEth);

    // web3Eth.eth.net.getNetworkType().then(alert);//ETH Network
    ETHBulkContract=new web3Eth.eth.Contract(abiBulk,addressBulk);
}
export default ETHBulkContract;
