import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify"; //toster
import "./Style.css";
import Menubar from "../components/pageComponents/navigationBar";
import Sidebar from "../components/pageComponents/sideBar";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
import { WalletContext } from "./WalletContext";
// Route to contribute page
import { useHistory } from "react-router-dom";
//utils function
import { isFinished, isActive, isUpcoming, getDealStauts } from "./utils";
//sample data
// import {result} from "./data";
import Up from "../components/assects/up.png";
import Down from "../components/assects/down.png";

const Deals = () => {
  const [dealsData, setDealsData] = useState([{}]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedOrderText, setSelectedOrderText] =
    useState("Date Latest First");
  const [loading, setLoading] = useState(false); //Loading bar
  // jump to contribute page
  const history = useHistory();

  //import hooks for object
  const [walletData, setWalletData] = useContext(WalletContext);

  const getDeals = async (selectedOrder) => {
    // API call to fetch deals
    //Test data
    // setDealsData(result.res)
    setLoading(true);
    await fetch(
      'https://backend.bulknetwork.app/get-all-deals?filter={"offset": 0,"order": "' +
        selectedOrder +
        '","where": {"VisibleInAll": true},"fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"AllocationForTier1": true,"AllocationForTier4": true,"CurrentRaize": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result)
        let data = [];
        if (result.success) {
          if (tabIndex == 0) {
            //set all deals
            data = result.res;
          } else {
            if (tabIndex == 1) {
              //set active deals only
              result.res.forEach((item) => {
                if (isActive(item.Start, item.End)) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 2) {
              //set upcoming deals only
              result.res.forEach((item) => {
                if (isUpcoming(item.Start)) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 3) {
              //set finished deals only
              result.res.forEach((item) => {
                if (isFinished(item.End)) {
                  data.push(item);
                }
              });
            }
          }
          setDealsData(data);
          setLoading(false);
        } else {
          toast.error("Error while loading deals!");
        }
      });
  };

  const handleDealType = async (index) => {
    // Handle tab selected
    if (index !== tabIndex) {
      setLoading(true);
      setTabIndex(index);
    }
  };

  useEffect(() => {
    getDeals("LastUpdate DESC");
    // console.log(JSON.stringify(dealsData));
  }, [tabIndex]);

  const GetDealLogo = (props) => {
    const [imageData, setImageData] = useState(null);
    useEffect(() => {
      if (props.id) {
        fetch(
          "https://backend.bulknetwork.app/get-single-deal/" +
            props.id +
            '?filter={"fields": {"Icon": true}}'
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              setImageData("data:image/png;base64," + result.res.Icon);
            }
          });
      }
    }, [props.id]);

    return (
      <>
        {imageData ? (
          <img src={imageData} />
        ) : (
          <img src={imageData} hidden={true} />
        )}
      </>
    );
  };

  return (
    <>
      <div className="dashboard">
        <Container className="box-container">
          <div className="content d-flex flex-wrap">
            <Col md={3} className="left-nav">
              <div className="cnt-lft">
                <Menubar></Menubar>
                <div className="hd-down">
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                    mobileView={true}
                  ></ConnectWallet>
                </div>
              </div>
            </Col>

            <Col md={6} className="right-content">
              <div className="deal-row none-mb">
                <div className="d-flex align-items-center">
                  <div className="heading-row">
                    <h1>Public Area</h1>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* Connect Wallet Button */}
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                  ></ConnectWallet>
                </div>
              </div>

              <div className="tab-box">
                {/* Options */}
                <div className="tab-row">
                  <a
                    className={tabIndex == 0 ? "active" : ""}
                    onClick={() => handleDealType(0)}
                  >
                    All Deals
                  </a>
                  <a
                    className={tabIndex == 1 ? "active" : ""}
                    onClick={() => handleDealType(1)}
                  >
                    Active
                  </a>
                  <a
                    className={tabIndex == 2 ? "active" : ""}
                    onClick={() => handleDealType(2)}
                  >
                    Upcoming
                  </a>
                  <a
                    className={tabIndex == 3 ? "active" : ""}
                    onClick={() => handleDealType(3)}
                  >
                    Finished
                  </a>
                  <div className="tab-right">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span className="sort-by">
                          <i class="fa fa-filter" aria-hidden="true"></i>Sort
                          By:
                        </span>{" "}
                        <span> {selectedOrderText} </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("LastUpdate DESC");
                            setSelectedOrderText("Date New First");
                          }}
                        >
                          By date: New First
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("LastUpdate");
                            setSelectedOrderText("Date Old First");
                          }}
                        >
                          By date: Old First
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("Name");
                            setSelectedOrderText("Name A to Z");
                          }}
                        >
                          By name: A - Z
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("Name DESC");
                            setSelectedOrderText("Name Z to A");
                          }}
                        >
                          By name: Z - A
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {/* tab row closed */}

                {/* Deals Cards */}
                <div className="tab-content">
                  {/* Loading bar */}
                  {loading ? (
                    <div>
                      <Spinner animation="border" role="status" variant="light">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <>
                      {/* For Deals set the data */}
                      {dealsData.map((data, key) => {
                        return (
                          <div className="all-deal-box">
                            <Row className=" header-chart align-items-center">
                              <Col md={8}>
                                <div className="user-name-row dl-dt">
                                  <GetDealLogo id={data.id} />
                                  <div>
                                    <p>{data.Name}</p>
                                    <p className="small-text">{data.Ticker}</p>
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={4}
                                className="d-flex justify-content-end none-mb"
                              >
                                {isFinished(data.End) ? (
                                  <button
                                    onClick={(e) =>
                                      history.push("/contribute/" + data.id)
                                    }
                                    className="btn-main"
                                  >
                                    View Deal
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      history.push("/contribute/" + data.id)
                                    }
                                    className="btn-main"
                                  >
                                    Join Deal
                                  </button>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <div class="deal-info">
                                  <ul>
                                    <li>
                                      <span className="text-heading">
                                        Max Allocation
                                      </span>{" "}
                                      <span>
                                        {" "}
                                        <img src={Up} />
                                        {data.AllocationForTier1}{" "}
                                        {data.Currency}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="text-heading">
                                        Min Allocation
                                      </span>{" "}
                                      <span>
                                        <img src={Down} />
                                        {data.AllocationForTier4}{" "}
                                        {data.Currency}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="chart-box">
                                  <svg
                                    width="56"
                                    height="30"
                                    viewBox="0 0 56 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2 28C4.41676 25.3389 7.63911 18.2204 13.2782 18.0208C18.9173 17.8212 23.1467 24.8066 28.9872 24.8066C34.8277 24.8066 37.4358 13.3385 44.6961 8.04155C48.107 5.55309 54.5646 0.457338 53.9604 2.45318"
                                      stroke="url(#paint0_linear_2_459)"
                                      stroke-width="3"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_2_459"
                                        x1="53.9604"
                                        y1="2"
                                        x2="2.19124"
                                        y2="28.3221"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop
                                          stop-color="#587DFF"
                                          stop-opacity="0"
                                        />
                                        <stop
                                          offset="0.535704"
                                          stop-color="#587DFF"
                                        />
                                        <stop
                                          offset="1"
                                          stop-color="#587DFF"
                                          stop-opacity="0"
                                        />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div className="chart-box-content">
                                    <p>Total Raize</p>
                                    <p class="price-chart">
                                      <span>
                                        {data.TotalRaize} {data.Currency}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="chart-box">
                                  <div className="progress-bar-row">
                                    <p className="pr-percent">
                                      {(
                                        (data.CurrentRaize / data.TotalRaize) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </p>
                                    <ProgressBar
                                      now={
                                        (data.CurrentRaize / data.TotalRaize) *
                                        100
                                      }
                                    />
                                  </div>
                                  <div className="chart-box-content">
                                    <p>Status</p>
                                    {isFinished(data.End) ? (
                                      <p class="price-chart">
                                        <i
                                          class="fa fa-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Finished
                                      </p>
                                    ) : (
                                      getDealStauts(data.Start, data.End)
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            {isFinished(data.End) ? (
                              <button
                                onClick={(e) =>
                                  history.push("/contribute/" + data.id)
                                }
                                className="btn-main show-mobile"
                              >
                                View Deal
                              </button>
                            ) : (
                              <button
                                onClick={(e) =>
                                  history.push("/contribute/" + data.id)
                                }
                                className="btn-main show-mobile"
                              >
                                Join Deal
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </Col>

            <Sidebar walletData={walletData}></Sidebar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Deals;
