import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  DropdownButton,
  ProgressBar,
} from "react-bootstrap";
import { toast } from "react-toastify"; //toster
import "../Style.css";
import "./admin.css";
import { LoginContext } from "../LoginContext";
// Route to contribute page
import { Redirect, useHistory } from "react-router-dom";
//utils function
import { isFinished, isActive, isUpcoming, getDealStauts } from "../utils";

function Dashboard({ authorize }) {
  const [dealsData, setDealsData] = useState([{}]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false); //Loading bar
  // jump to contribute page
  const history = useHistory();
  const [selectedDealID, setSelectedDealId] = useState(null);
  const [authorizeLogin, setAuthorizeLogin] = useContext(LoginContext);

  const getDeals = async () => {
    // API call to fetch deals
    //Test data
    // setDealsData(result.res)
    await fetch(
      'https://backend.bulknetwork.app/get-all-deals?filter={"offset": 0,"limit": 500,"skip": 0,"order": "LastUpdate DESC","where": {"additionalProp1": {}},"fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"AllocationForTier1": true,"AllocationForTier4": true,"Description": true,"CurrentRaize": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        //set return data into dealsData object
        if (result.success) {
          if (tabIndex == 0) {
            //set all deals
            // if(result.res.length<1)
            //     toast.info("No data found",{autoClose:2000})
            setDealsData(result.res);
            setLoading(false);
          } else {
            let data = [];
            if (tabIndex == 1) {
              //set active deals only
              result.res.forEach((item) => {
                if (isActive(item.Start, item.End)) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 2) {
              //set upcoming deals only
              result.res.forEach((item) => {
                if (isUpcoming(item.Start)) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 3) {
              //set finished deals only
              result.res.forEach((item) => {
                if (isFinished(item.End)) {
                  data.push(item);
                }
              });
            }
            // if(data.length<1)
            //     toast.info("No data found",{autoClose:2000})
            setDealsData(data);
            setLoading(false);
          }
        } else {
          toast.error("Error while loading deals!");
        }
      });
  };

  const GetDealLogo = (props) => {
    const [imageData, setImageData] = useState(null);
    useEffect(() => {
      if (props.id) {
        fetch(
          "https://backend.bulknetwork.app/get-single-deal/" +
            props.id +
            '?filter={"fields": {"Icon": true}}'
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              setImageData("data:image/png;base64," + result.res.Icon);
            }
          });
      }
    }, [props.id]);

    return (
      <>
        {imageData ? (
          <img src={imageData} />
        ) : (
          <img src={imageData} hidden={true} />
        )}
      </>
    );
  };

  const deleteDeal = async (id) => {
    // console.log(id)
    let token = localStorage.getItem("token");
    await fetch("https://backend.bulknetwork.app/delete-deals/" + id, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        else toast.error(responseJson.error.message);
        getDeals();
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch(
      'https://backend.bulknetwork.app/vesting-data?filter={"where":{"DealId":"' +
        id +
        '"},"fields":{"id":true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        //set return data into vestingData object
        for (let j = 0; j < result.length; j++) {
          var objId = result[j].id;
          deleteVesting(objId);
        }
      });
    await fetch(
      'https://backend.bulknetwork.app/contributors?filter={"where":{"DealId":"' +
        id +
        '"},"fields":{"id":true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        //set return data into contributors object
        for (let j = 0; j < result.length; j++) {
          var objId = result[j].id;
          deleteContributeData(objId);
        }
      });
    setSelectedDealId(null);
  };

  //delete method
  const deleteContributeData = async (objId) => {
    await fetch("https://backend.bulknetwork.app/contributors/" + objId, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteVesting = (vestingId) => {
    let token = localStorage.getItem("token");
    fetch("https://backend.bulknetwork.app/vesting-data/" + vestingId, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });
  };

  const handleDealType = async (index) => {
    // Handle tab selected
    if (index !== tabIndex) {
      setLoading(true);
      setTabIndex(index);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDeals();
    // console.log(JSON.stringify(dealsData));
  }, [tabIndex]);

  return (
    <>
      {authorize ? (
        <div className="dashboard" style={{ padding: "1%" }}>
          {/* Popup */}
          <div
            className="overlay-popup"
            style={{ display: selectedDealID != null ? "flex" : "none" }}
          >
            <div className="popup-box" style={{ width: "95%" }}>
              <div className="closed">
                <a
                  style={{ color: "white", border: "none" }}
                  onClick={() => setSelectedDealId(null)}
                >
                  <span style={{ fontSize: "25px" }}>×</span>
                </a>
              </div>

              <div className="popup-body" style={{ padding: "30px 30px 0" }}>
                <div className="popup-header">
                  <h2>Delete Confirmation</h2>
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Are you sure you want to delete this?
                  </p>
                </div>
                <div className="footer-popup">
                  <Button onClick={() => deleteDeal(selectedDealID)}>
                    Yes
                  </Button>
                  <Button onClick={() => setSelectedDealId(null)}>Close</Button>
                </div>
              </div>
            </div>
          </div>
          <Container className="maine-content">
            <Row>
              <div>
                <div style={{ float: "left" }}>
                  <h2 className="page-heading">Deals</h2>
                </div>
                <div style={{ float: "right" }}>
                  <DropdownButton variant="secondary" title="Admin">
                    <Dropdown.Item
                      onClick={(e) => history.push("/update-landing-page")}
                    >
                      Update Landing Page
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => history.push("/register-accounts")}
                    >
                      Registred Accounts
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => history.push("/update-admin")}
                    >
                      Update Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setAuthorizeLogin(false);
                        localStorage.removeItem("token");
                        history.push("/login");
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </Row>

            <Row>
              <div>
                <div style={{ float: "left", width: "90%" }}>
                  {/* Options */}
                  <div className="tab-row">
                    <a
                      className={tabIndex == 0 ? "active" : ""}
                      onClick={() => handleDealType(0)}
                    >
                      All Deals
                    </a>
                    <a
                      className={tabIndex == 1 ? "active" : ""}
                      onClick={() => handleDealType(1)}
                    >
                      Active
                    </a>
                    <a
                      className={tabIndex == 2 ? "active" : ""}
                      onClick={() => handleDealType(2)}
                    >
                      Upcoming
                    </a>
                    <a
                      className={tabIndex == 3 ? "active" : ""}
                      onClick={() => handleDealType(3)}
                    >
                      Finished
                    </a>
                  </div>
                  {/* tab row closed */}
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    className="button-strict"
                    onClick={(e) => history.push("/create")}
                  >
                    + Add Deal
                  </Button>
                </div>
              </div>
            </Row>

            {/* Deals Cards */}
            <div className="tab-content">
              <Row>
                {/* Loading bar */}
                {loading ? (
                  <div>
                    <Spinner animation="border" role="status" variant="light">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <>
                    {/* For Deals set the data */}
                    {dealsData.map((data, key) => {
                      return (
                        <div className="all-deal-box">
                          <Row className=" header-chart align-items-center">
                            <Col md={4}>
                              <div className="user-name-row dl-dt">
                                <GetDealLogo id={data.id} />
                                <div>
                                  <p>{data.Name}</p>
                                  <p className="small-text">{data.Ticker}</p>
                                </div>
                              </div>
                            </Col>

                            <Col md={8} className="d-flex justify-content-end">
                              <button
                                className="btn-main btn-main-red"
                                variant="danger"
                                onClick={() => setSelectedDealId(data.id)}
                              >
                                Delete Deal
                              </button>
                              <button
                                className="btn-main btn-main-yellow"
                                variant="warning"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                                onClick={(e) =>
                                  history.push("/edit/" + data.id)
                                }
                              >
                                Edit Deal
                              </button>
                              <button
                                className="btn-main btn-main-gray"
                                variant="secondary"
                                onClick={(e) =>
                                  history.push("/manage-sell/" + data.id)
                                }
                              >
                                Manage Sell Data
                              </button>
                              <button
                                className="btn-main btn-main-gray"
                                variant="secondary"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                                onClick={(e) =>
                                  history.push("/upload-deal-data/" + data.id)
                                }
                              >
                                Upload Old Deal Data
                              </button>
                              <button
                                className="btn-main btn-main-gray"
                                variant="secondary"
                                style={{ marginRight: "10px" }}
                                onClick={(e) =>
                                  history.push("/edit-contribution/" + data.id)
                                }
                              >
                                Edit Contribution
                              </button>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <div class="deal-info">
                                <ul>
                                  <li>
                                    <span className="text-heading">
                                      Max Allocation
                                    </span>{" "}
                                    <span>
                                      {" "}
                                      {data.AllocationForTier1} {data.Currency}
                                    </span>
                                  </li>
                                  <li>
                                    <span className="text-heading">
                                      Min Allocation
                                    </span>{" "}
                                    <span>
                                      {data.AllocationForTier4} {data.Currency}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="chart-box">
                                <svg
                                  width="56"
                                  height="30"
                                  viewBox="0 0 56 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 28C4.41676 25.3389 7.63911 18.2204 13.2782 18.0208C18.9173 17.8212 23.1467 24.8066 28.9872 24.8066C34.8277 24.8066 37.4358 13.3385 44.6961 8.04155C48.107 5.55309 54.5646 0.457338 53.9604 2.45318"
                                    stroke="url(#paint0_linear_2_459)"
                                    stroke-width="3"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_2_459"
                                      x1="53.9604"
                                      y1="2"
                                      x2="2.19124"
                                      y2="28.3221"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop
                                        stop-color="#587DFF"
                                        stop-opacity="0"
                                      />
                                      <stop
                                        offset="0.535704"
                                        stop-color="#587DFF"
                                      />
                                      <stop
                                        offset="1"
                                        stop-color="#587DFF"
                                        stop-opacity="0"
                                      />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <div className="chart-box-content">
                                  <p>Total Raize</p>
                                  <p class="price-chart">
                                    <span>
                                      {data.TotalRaize} {data.Currency}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="chart-box">
                                <div className="progress-bar-row">
                                  <p className="pr-percent">
                                    {(
                                      (data.CurrentRaize / data.TotalRaize) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </p>
                                  <ProgressBar
                                    now={
                                      (data.CurrentRaize / data.TotalRaize) *
                                      100
                                    }
                                  />
                                </div>
                                <div className="chart-box-content">
                                  <p>Status</p>
                                  {isFinished(data.End) ? (
                                    <p class="price-chart">
                                      <i
                                        class="fa fa-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Finished
                                    </p>
                                  ) : (
                                    getDealStauts(data.Start, data.End)
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}
              </Row>
            </div>
          </Container>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
}

export default Dashboard;
