import React, {useState, createContext} from 'react';
import { useEffect } from 'react';

export const WalletContext = createContext();

export const WalletProvider= props =>{
    //import hooks for object
    const [walletData, setWalletData] =  useState({
        walletAddress:"",
        balance:0,
        isConnected:false,
        networkName:"",
        ETHTokenCount:0,
        BSCTokenCount:0,
        TotalTokenCount:0,
        TotalFarmingTokens:0,
        tier:0,
        web3:null
    })
    

    return(
        <WalletContext.Provider value={[walletData,setWalletData]}>
            {props.children}
        </WalletContext.Provider>
    )

}