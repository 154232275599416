import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Form,
  Spinner,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import "./admin.css";

const UploadDealData = ({ authorize }) => {
  const { id } = useParams();
  const [fileData, setFileData] = useState([{}]);
  const [fileSelected, setFileSelected] = useState(false); //file is selected
  const [loading, setLoading] = useState(false); //Loading bar

  const contributorDataAPICall = async (
    txId,
    dealId,
    userWalletAddress,
    totalAmountInvested,
    contributorType,
    customInfo,
    email,
    telegramHandle
  ) => {
    var contributeData = {
      DealId: dealId,
      UserWalletAddress: userWalletAddress,
      TotalAmountInvested: parseFloat(totalAmountInvested),
      ContributorType: contributorType,
      CustomInfo: customInfo,
      TransactionId: txId,
      Email: email,
      TelegramHandle: telegramHandle,
    };
    console.log(contributeData);
    // create call
    await fetch("https://backend.bulknetwork.app/create-contributors", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(contributeData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.error) {
          contributorDataAPICall(
            dealId,
            userWalletAddress,
            totalAmountInvested,
            contributorType,
            email,
            telegramHandle
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateCurrentRaize = async (dealId, totalAmountInvested) => {
    let jsonData = {
      CurrentRaize: parseFloat(totalAmountInvested),
      id: dealId,
    };
    // console.log(jsonData)
    await fetch("https://backend.bulknetwork.app/update-currentRaize", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ManageSellAPICall = async (walletAddress, dealId) => {
    await fetch(
      'https://backend.bulknetwork.app/manage-sells?filter={ "offset": 0, "limit": 500, "skip": 0, "order": "string", "where": { "WalletAddress": "' +
        walletAddress +
        '", "DealId": "' +
        dealId +
        '" },"fields": { "WalletAddress": true, "id": true, "DealId": true } }'
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // console.log(responseJson.length);
        //already  present
        if (responseJson.length > 0) {
        } else {
          var manageSellData = {
            WalletAddress: walletAddress,
            DealId: dealId,
          };
          fetch("https://backend.bulknetwork.app/create-manage-sells", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            cache: "no-cache",
            body: JSON.stringify(manageSellData),
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadData = async () => {
    if (fileSelected) {
      setLoading(true);
      for (let i = 0; i < fileData.length; i++) {
        var data = fileData[i];

        var txId = data[0];
        var userWalletAddress = data[1].toLowerCase();
        var totalAmountInvested = data[2];
        var telegramHandle = data[3];
        var email = data[4];
        var contributorType = data[5];
        var manageSellActivated = data[6];
        var customInfo = data[7];
        var dealId = id;

        //Deal current raize updated
        await updateCurrentRaize(dealId, totalAmountInvested);
        //Create contribution data
        await contributorDataAPICall(
          txId,
          dealId,
          userWalletAddress,
          totalAmountInvested,
          contributorType,
          customInfo,
          email,
          telegramHandle
        );
        //create sell
        if (manageSellActivated == "Yes" || manageSellActivated == "yes")
          await ManageSellAPICall(userWalletAddress, dealId);
      }
      toast.success("All data uploaded!");
      setLoading(false);
    } else {
      toast.error("Please select file first!");
    }
  };

  const convertTextToObject = (allText) => {
    var allTextLines = allText.split(/\r\n|\n/);
    var headers = allTextLines[0].split(",");
    var lines = [];

    for (var i = 1; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(",");
      if (data.length == headers.length) {
        var tarr = [];
        for (var j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    }
    console.log(lines);
    return lines;
  };

  //convert image to base64 string
  function handleFileSelect(evt) {
    var file = evt.target.files[0]; // FileList object
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      // console.log(text);

      setFileData(convertTextToObject(text));
    };
    reader.readAsText(file);
    setFileSelected(true);
  }

  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <br />
          <div className="deal-form-page">
            <Container>
              <h1 className="page-heading">Upload Data</h1>
              <br />
              <Form className="deal-form">
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3 user-img" controlId="dataFile">
                      <Form.Label for="dataFile"> Select File </Form.Label>
                      <Form.Control
                        type="file"
                        id="dataFile"
                        className="input-design"
                        accept=".csv"
                        onChange={handleFileSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="submit-btn">
                    <Button
                      type="button"
                      disabled={loading || !fileSelected}
                      onClick={uploadData}
                    >
                      Update Data
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Transaction Id</th>
                    <th>Wallet</th>
                    <th>Amount contributed</th>
                    <th>Telegram Handle</th>
                    <th>Mail</th>
                    <th>Tier</th>
                    <th>Managed sale activated</th>
                    <th>Custom info</th>
                  </tr>
                </thead>
                <tbody>
                  {fileData.map((data, key) => {
                    return (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{data[0]}</td>
                        <td>{data[1]}</td>
                        <td>{data[2]}</td>
                        <td>{data[3]}</td>
                        <td>{data[4]}</td>
                        <td>{data[5]}</td>
                        <td>{data[6]}</td>
                        <td>{data[7]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};

export default UploadDealData;
