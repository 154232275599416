import React, { useContext } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify"; //toster

function RegistrationModel(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event.target)
    var formData = {
      WalletAddress: props.walletAddress,
      Email: event.target.email.value,
      TelegramHandle: event.target.telegramId.value,
    };
    console.log(formData);
    fetch("https://backend.bulknetwork.app/create-users", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          toast.success(responseJson.message);
          window.location.reload();
        } else toast.error(responseJson.error.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal show={props.modalShow} onHide={props.onHide} className="popup-lg">
      <Modal.Body style={{ padding: "0px" }}>
        <div className="login-box">
          <div className="closed">
            <a
              style={{ color: "white", border: "none" }}
              onClick={props.onHide}
            >
              <span style={{ fontSize: "25px" }}>×</span>
            </a>
          </div>
          <div className="no-access-popup">
            <div className="popup-header">
              <h2>Registration</h2>
              <p>
                To participate in our deals you need to complete registration by
                providing the following information
              </p>
            </div>
            <Form className="deal-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className="input-design"
                  type="email"
                  id="email"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="telegramId">
                <Form.Label>Telegram Handle</Form.Label>
                <Form.Control
                  className="input-design"
                  type="text"
                  id="telegramId"
                  placeholder=""
                  required
                />
                <Form.Text id="telegramIdHelpBlock" muted>
                  e.g. @JhonDoe
                </Form.Text>
              </Form.Group>
              <Button className="cnt-button" type="submit" variant="primary">
                SUBMIT
              </Button>
            </Form>

            <div className="footer-popup">
              <p>
                Note: This information will be associated with the wallet [
                {props.walletAddress}]. You can change this at any time in the
                My Profile section
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RegistrationModel;
