import React, { useState } from "react";
import {Button, Modal} from "react-bootstrap";
import './noAccess.css';

function NoAccessModel(props) {
  const [buyingCheck,setBuyingCheck] = useState(true);//set buying Url

  // javascript code
  document.onclick = function(e){
      // console.log(e.target.className);
      if(e.target.className ==="popup-lg"){
        props.onHide();
      }
  };

    return (
    <Modal show={props.modalShow} onHide={props.onHide} className="popup-lg">
    <Modal.Body style={{padding:'0px'}}>
    <div className="login-box">
    <div className="closed">
      <a style={{color:'white',border:'none'}} onClick={props.onHide}><span style={{fontSize:'25px'}}>×</span></a>
    </div>

    <div className="no-access-popup">
    <div className="popup-header">
    <h2>
    <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0856 11.0644H20.232V6.89148C20.232 3.09806 17.134 0 13.3405 0H10.8115C7.01804 0 3.91998 3.09795 3.91998 6.89148V11.0326H3.06639C1.35944 11.0326 0 12.4236 0 14.099V26.9336C0 28.6406 1.391 30 3.06639 30H21.117C22.824 30 24.1834 28.609 24.1834 26.9336L24.1832 14.1306C24.1832 12.4235 22.7922 11.0642 21.0852 11.0642L21.0856 11.0644ZM8.0613 6.89148C8.0613 5.37414 9.29418 4.14127 10.8115 4.14127H13.3406C14.8579 4.14127 16.0908 5.37414 16.0908 6.89148V11.0326L8.06124 11.0328L8.0613 6.89148ZM16.3121 22.7292C16.881 23.2981 16.881 24.215 16.3121 24.7839C16.0276 25.0684 15.6481 25.1949 15.3004 25.1949C14.9527 25.1949 14.5734 25.0686 14.2887 24.7839L12.0761 22.5711L9.89481 24.7524C9.61035 25.0369 9.23084 25.1634 8.88311 25.1634C8.53537 25.1634 8.15608 25.0371 7.8714 24.7524C7.30249 24.1835 7.30249 23.2666 7.8714 22.6977L10.0527 20.5164L7.8714 18.3351C7.30249 17.7662 7.30249 16.8493 7.8714 16.2804C8.44031 15.7115 9.35718 15.7115 9.92609 16.2804L12.0761 18.4932L14.2574 16.3119C14.8263 15.743 15.7431 15.743 16.3121 16.3119C16.881 16.8808 16.881 17.7977 16.3121 18.3666L14.1307 20.5479L16.3121 22.7292Z" fill="white"/>
    </svg>
      No access</h2> 
    <p>Due to confidentiality reasons, the vast majority of our deals are not posted in the public All Deals area. These deals are only accessible to our members in the Member Area.
Higher Tiers grant larger individual allocations and provide access to more exclusive deals that might not be accessible in lower Tiers.</p>
    <p style={{margin:'0 0 30px'}}>To access Member Area you need to lock the following number of tokens:</p>
    </div>

    <div class="row-box"><p class="text-heading">Top 10</p><p class="amount">10 users with highest locked balances</p></div>
    <div class="row-box"><p class="text-heading">Tier 1</p><p class="amount">1 000 000 BULK</p></div>
    <div class="row-box"><p class="text-heading">Tier 2</p><p class="amount">500 000 BULK</p></div>
    <div class="row-box"><p class="text-heading">Tier 3</p><p class="amount">100 000 BULK</p></div>
    <div class="row-box"><p class="text-heading">Tier 4</p><p class="amount">10 000 BULK</p></div>

    <div className="custom_radio">
        <input type="radio" id="buyingUrl-1" name="buyingUrl" onClick={()=> setBuyingCheck(true)} checked={buyingCheck}/>
        <label for="buyingUrl-1">Buy on Uniswap</label>
        <input type="radio" id="buyingUrl-2" name="buyingUrl" onClick={()=> setBuyingCheck(false)} checked={!buyingCheck}/>
        <label for="buyingUrl-2" >Buy on Pancakeswap</label>
    </div>
    <div className="access-btn-row">
    <a href={buyingCheck?
                ("https://app.uniswap.org/#/swap?outputCurrency=0xa143ac515dca260a46c742c7251ef3b268639593"):
                ("https://pancakeswap.finance/swap?outputCurrency=0xa143ac515dca260a46c742c7251ef3b268639593")
            } target="_blank"><Button className="cnt-button"> Buy </Button></a> 
      <a href="https://bulklock.web.app/" target="_blank">
      <Button className="cnt-button"> Lock Tokens </Button>
      </a>
    </div> 

    </div>

    </div>
    </Modal.Body>
    </Modal>
    );
  }

  export default NoAccessModel