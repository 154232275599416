import React, { useState, useEffect, useContext } from "react";
import {Container, Row, Col, Spinner,Button} from "react-bootstrap";
import { toast } from 'react-toastify'; //toster
import './Style.css';
import Menubar from "../components/pageComponents/navigationBar";
import Sidebar from "../components/pageComponents/sideBar";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
import {WalletContext} from './WalletContext';
//sample data
import Logo from '../components/assects/bulk-logo.png';
import Rocekt from  '../components/assects/bulk-logo.png';


const Token = ()=>{     

    const [loading,setLoading] = useState(false);//Loading bar
    //import hooks for object
    const [walletData, setWalletData] =  useContext(WalletContext)
    
return(<>

<div className={loading ? 'parentDisable' : ''} width="100%">
    <div className={loading ? 'overlay-box' : 'hide'}>
        <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>

    <div className="dashboard">
<Container className="box-container">

<div className="content d-flex flex-wrap">

    <Col md={3} className="left-nav">
        <div className="cnt-lft">
            <Menubar></Menubar>
            <div className="hd-down">
                <ConnectWallet walletData={walletData} setWalletData={setWalletData} mobileView={true}></ConnectWallet>
            </div>
        </div>
    </Col>
<Col md={6} className="right-content">
    
    <div className="deal-row none-mb">
    <div  className="d-flex align-items-center">
    <div className="heading-row"><h1>Token Lock</h1></div> 
    </div>
    
    
    <div>
    <div className="finished-row finished">
    <span className="icon-1" > 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#627EEA"/>
    <path d="M10.3115 2.49805V8.04206L14.9971 10.1361L10.3115 2.49805Z" fill="white" fill-opacity="0.602"/>
    <path d="M10.3116 2.49805L5.62598 10.1361L10.3116 8.04206V2.49805Z" fill="white"/>
    <path d="M10.3115 13.7279V17.495L14.9999 11.0078L10.3115 13.7279Z" fill="white" fill-opacity="0.602"/>
    <path d="M10.3116 17.495V13.7279L5.62598 11.0078L10.3116 17.495Z" fill="white"/>
    <path d="M10.3115 12.8571L14.9971 10.137L10.3115 8.04297V12.8571Z" fill="white" fill-opacity="0.2"/>
    </svg>
    
    </span> <span></span> <p>ETH::0.987</p>
    <div className="fin-right"> <p>Address:</p> <span>0x345..576</span></div>
    </div>
    </div>
    </div>
    
    <Row>
    
    <Col md={6}>
    <div className="token-content">
    <div className='date-row'>
    <h3>Lock Tokens</h3>
    </div>
    <div class="deal-info">
    <ul>
    <li><span className='text-heading'>Locked tokens:</span> <span>~BULK</span></li>  
    <li><span className='text-heading'>Possible to lock:</span>  <span>~ BULK</span></li>  
    </ul> 
    </div>  
    <p className="amt-cnt">Amount</p>
    <div className="amout">
    <p>100</p>  <span>BULK</span>
    </div>
    <button className="btn-main">Approve</button>
    </div>
    </Col>
    
    <Col md={6}>
    <div className="token-content">
    <div className='date-row'>
    <h3>Unlock Tokens</h3>
    <div className='date-tk'>
    <p><span>2</span> <span className="sm-text">days</span></p>
    <span className='c-dots'>:</span>
    <p><span>21</span> <span className="sm-text">hrs</span></p>   
    <span className='c-dots'>:</span> 
    <p><span>13</span> <span className="sm-text">min</span></p>   
    <span className='c-dots'>:</span> 
    <p><span>10</span><span className="sm-text">sec</span></p>  
    </div>
    </div>
    <div class="deal-info">
    <ul>
    <li><span className='text-heading'>Locked tokens:</span> <span>~BULK</span></li>  
    <li><span className='text-heading'>Possible to lock:</span>  <span>~ BULK</span></li>  
    </ul> 
    </div>  
    <p className="amt-cnt">Amount</p>
    <div className="amout">
    <p>100</p>  <span>BULK</span>
    </div>
    <button className="btn-main unlock-tk-btn">Unlock Tokens</button>
    </div>
    </Col>
    
    <Col md={6}>
    <div className="token-content">
    <div className='date-row'>
    <h3>Claim</h3>
    </div>
    <div class="deal-info">
    <ul>
    <li><span className='text-heading'>Possible to claim:</span> <span>~BULK</span></li>  
    </ul> 
    </div>  
    <button className="btn-main claim-btn">Claim Tokens</button>
    </div>
    </Col>
    
    <Col md={6}>
    <div className="token-content">
    <div className='date-row'>
    <h3>Withdraw</h3>
    </div>
    <div class="deal-info">
    <ul>
    <li><span className='text-heading'>Withdraw</span> <span>~BULK</span></li>  
    </ul> 
    </div>  
    <button className="btn-main">Restake Tokens</button>
    <button className="btn-main">Withdraw Tokens</button>
    </div>
    </Col>
    
    </Row>
    
    </Col> {/* right content closed   */}

    
    <Sidebar walletData={walletData}></Sidebar>

    </div>
    </Container>
    </div>
    </div>
</>)
}

export default Token