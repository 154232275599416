import BSCUSDTContract from './BSCUSDTContract';
import BSCBulkContract from './BSCBulkContract';
import {addressBulk} from './BSCContractData';
import { toast } from 'react-toastify'; //toster


export async function contributeBSC(walletAddress,amount,receiverAddress){
    // var result=false;
    // console.log('wallet address is '+walletAddress+'\n amount is '+amount)
    //allowance method with parameter user wallet address, bulk contract address
    try{
        // var allowance = await BSCUSDTContract.methods.allowance(walletAddress,addressBulk).call();
        // console.log('allowance is '+allowance)
        // if(amount>allowance){
        //     console.log('set allowance first!')
        //     //call to set allowance of size amount
        //     await BSCUSDTContract.methods.approve(addressBulk,amount).send({from:walletAddress});
        // }
        //Stake amount from user wallet
        // var receipt=await BSCBulkContract.methods.stake(amount).send({from:walletAddress});
        var receipt=await BSCUSDTContract.methods.transfer(receiverAddress,amount).send({from:walletAddress});
        console.log(receipt)
        // result=true;
        return receipt;
    } catch (err) {
        console.log(err)
        toast.error(err.message)
    }
    return false;
}
