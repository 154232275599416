import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


export const Recaptcha = props =>{  
  const { fetch: originalFetch } = window;
  const { executeRecaptcha } = useGoogleReCaptcha();


  const handleReCaptchaVerify = async () => {
    console.log('call')
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('login');
    localStorage.setItem('recaptchaToken', token)
    return token
  };
  
  window.fetch = async (...args) => {
    let [resource, options] = args;
    const isPostRequest = options?.method === 'post' || options?.method === 'put';
    if (isPostRequest) {
      const token = await handleReCaptchaVerify();
      if(token) {
        options = {
          ...options,
          headers: {
            ...options?.headers,
            'X-Recaptcha': token,
          }
        }
      } else {
        options = {
          ...options,
          headers: {
            ...options?.headers,
            'X-Recaptcha': localStorage.getItem('recaptchaToken'),
          }
        }
      }
    }

    
    const response = await originalFetch(resource, options);
    return response;
  };

  return null
}