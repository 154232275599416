import React, {useState, createContext} from 'react';
import { useEffect } from 'react';

export const LoginContext = createContext();

export const LoginProvider= props =>{
    const [authorize,setAuthorize]=useState(false)
    

    return(
        <LoginContext.Provider value={[authorize,setAuthorize]}>
            {props.children}
        </LoginContext.Provider>
    )

}