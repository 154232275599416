import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import "./admin.css";

import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Calender part
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

const Edit = ({ authorize }) => {
  const { id } = useParams();
  const [icon, setIcon] = useState("");
  const [selectedVestingData, setSelectedVestingData] = useState([]);
  const [vestingDataList, setVestingDataList] = useState([]);
  const [customInfoCheck, setCustomInfoCheck] = useState(false); //custom Info check
  const [tabKey, setTabKey] = useState("create-vesting");
  const [loading, setLoading] = useState(false); //Loading bar

  const [socialWebsites, setSocialWebsites] = useState([]);
  const [socialWebsitesLogos, setSocialWebsitesLogos] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateCustomSections, setEditorStateCustomSections] = useState(
    EditorState.createEmpty()
  );

  const [customSections, setCustomSection] = useState([]);
  const [customSectionIndex, setCustomSectionIndex] = useState(0);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const sendTextToEditor = (text) => {
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
  };

  // for custom section
  const onEditorStateChangeCustomSections = (editorStateTokenMetrics) => {
    customSections[customSectionIndex].data = draftToHtml(
      convertToRaw(editorStateTokenMetrics.getCurrentContent())
    );
    setEditorStateCustomSections(editorStateTokenMetrics);
  };

  const sendTextToEditorCustomSections = (text) => {
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorStateCustomSections(EditorState.createWithContent(contentState));
  };

  const insertText = (text) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  useEffect(() => {
    setLoading(true);
    fetch(
      "https://backend.bulknetwork.app/get-single-deal/" +
        id +
        '?filter={"offset": 0,"limit": 500,"skip": 0,"order": "string","fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"Price": true,"Vesting": true,"DealRate": true,"AllocationForTop10": true,"AllocationForTier1": true,"AllocationForTier2": true,"AllocationForTier3": true,"AllocationForTier4": true,"Social": true,"Description": true,"CustomSections": true,"socialWebsitesLogos": true,"Icon": true,"CurrentRaize": true,"WalletAddress": true,"VisibleInAll": true,"VisibleInTop10": true,"VisibleInTier1": true,"VisibleInTier2": true,"VisibleInTier3": true,"VisibleInTier4": true,"ManageSell": true,"CustomInfoWindow": true,"CustomInfoText": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        //set return data into dealsData object
        if (result.success) {
          if (result.res.length < 1)
            toast.info("No data found", { autoClose: 2000 });
          else {
            console.log(result.res);
            //Set vesting data
            document.getElementById("projectName").innerHTML = result.res.Name;
            document.getElementById("vestingSchedule").innerHTML =
              result.res.Vesting;

            //set deal edit data
            document.getElementById("name").value = result.res.Name;
            document.getElementById("ticker").value = result.res.Ticker;
            document.getElementById("totalRaize").value = result.res.TotalRaize;
            document.getElementById("currency").value = result.res.Currency;
            document.getElementById("start").value = result.res.Start;
            document.getElementById("end").value = result.res.End;
            document.getElementById("price").value = result.res.Price;
            document.getElementById("vesting").value = result.res.Vesting;
            document.getElementById("dealRate").value = result.res.DealRate;
            document.getElementById("allocationForTop10").value =
              result.res.AllocationForTop10;
            document.getElementById("allocationForTier1").value =
              result.res.AllocationForTier1;
            document.getElementById("allocationForTier2").value =
              result.res.AllocationForTier2;
            document.getElementById("allocationForTier3").value =
              result.res.AllocationForTier3;
            document.getElementById("allocationForTier4").value =
              result.res.AllocationForTier4;
            // document.getElementById("description").value =result.res.Description;
            if (result.res.Social) {
              setSocialWebsites(JSON.parse(result.res.Social));
            }
            sendTextToEditor(result.res.Description);
            if (result.res.CustomSections) {
              setCustomSection(result.res.CustomSections);
            }
            if (result.res.socialWebsitesLogos) {
              setSocialWebsitesLogos(result.res.socialWebsitesLogos);
            }
            document.getElementById("currentRaize").value =
              result.res.CurrentRaize;
            document.getElementById("walletAddress").value =
              result.res.WalletAddress;
            document.getElementById("allDealsCheckbox").checked =
              result.res.VisibleInAll;
            document.getElementById("top10Checkbox").checked =
              result.res.VisibleInTop10;
            document.getElementById("tier1Checkbox").checked =
              result.res.VisibleInTier1;
            document.getElementById("tier2Checkbox").checked =
              result.res.VisibleInTier2;
            document.getElementById("tier3Checkbox").checked =
              result.res.VisibleInTier3;
            document.getElementById("tier4Checkbox").checked =
              result.res.VisibleInTier4;
            document.getElementById("manageSell").checked =
              result.res.ManageSell;
            document.getElementById("customInfoWindow").checked =
              result.res.CustomInfoWindow;
            setCustomInfoCheck(result.res.CustomInfoWindow);
            if (result.res.CustomInfoText !== undefined)
              document.getElementById("customInfoText").value =
                result.res.CustomInfoText;
            setIcon(result.res.Icon);
            setLoading(false);
          }
        } else {
          toast.error("Error while loading deals!");
        }
      });
    getVestingDataCall();
  }, []);

  const getVestingDataCall = () => {
    setLoading(true);
    fetch(
      'https://backend.bulknetwork.app/vesting-data?filter={"offset":0,"limit":500,"skip":0,"order":"string","where":{"DealId":"' +
        id +
        '"},"fields":{"DealId":true,"id":true,"date":true,"UnlockPercentage":true,"CumulativeUnlock":true,"DistributionTrHash":true,"MarketSaleTrHash":true,"IsDistributed":true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        //set return data into dealsData object
        if (result) {
          setVestingDataList(result);
        } else {
          toast.error("Error while loading deals!");
        }
        setLoading(false);
      });
  };

  const selectedVestingDataChange = (data) => {
    setSelectedVestingData(data);
    setTabKey("update-vesting");
  };

  useEffect(() => {
    if (selectedVestingData.date) {
      setVestingUpdateTab();
    }
  }, [selectedVestingData]);

  const setVestingUpdateTab = () => {
    document.getElementById("vestingDateUpdate").value =
      selectedVestingData.date.substr(0, 10);
    document.getElementById("projectNameUpdate").innerHTML =
      document.getElementById("projectName").innerHTML;
    document.getElementById("vestingScheduleUpdate").innerHTML =
      document.getElementById("vestingSchedule").innerHTML;
    document.getElementById("unlockPercentageUpdate").value =
      selectedVestingData.UnlockPercentage;
    document.getElementById("cumulativeUnlockPercentageUpdate").value =
      selectedVestingData.CumulativeUnlock;
    document.getElementById("distributionTxHashUpdate").value =
      selectedVestingData.DistributionTrHash;
    document.getElementById("marketSaleTxHashUpdate").value =
      selectedVestingData.MarketSaleTrHash;
    document.getElementById("distributionExecutedUpdateCheckbox").checked =
      selectedVestingData.IsDistributed;
  };

  const handleVestingUpdateSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let token = localStorage.getItem("token");

    var formData = {
      DealId: id,
      date: new Date(event.target.vestingDateUpdate.value).toISOString(),
      UnlockPercentage: event.target.unlockPercentageUpdate.value,
      CumulativeUnlock: event.target.cumulativeUnlockPercentageUpdate.value,
      DistributionTrHash: event.target.distributionTxHashUpdate.value,
      MarketSaleTrHash: event.target.marketSaleTxHashUpdate.value,
      IsDistributed: event.target.distributionExecutedUpdateCheckbox.checked,
    };
    console.log(formData);
    fetch(
      "https://backend.bulknetwork.app/vesting-data/" + selectedVestingData.id,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        setLoading(false);
        getVestingDataCall();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const deleteVesting = (vestingId) => {
    setLoading(true);
    let token = localStorage.getItem("token");
    fetch("https://backend.bulknetwork.app/vesting-data/" + vestingId, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        setLoading(false);
        getVestingDataCall();
      })
      .catch((error) => {
        alert(error);
        console.log(error);
        setLoading(false);
      });
  };

  const handleVestingSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let token = localStorage.getItem("token");

    var formData = {
      DealId: id,
      date: new Date(event.target.vestingDate.value).toISOString(),
      UnlockPercentage: event.target.unlockPercentage.value,
      CumulativeUnlock: event.target.cumulativeUnlockPercentage.value,
      DistributionTrHash: event.target.distributionTxHash.value,
      MarketSaleTrHash: event.target.marketSaleTxHash.value,
      IsDistributed: event.target.distributionExecutedCheckbox.checked,
    };
    console.log(formData);
    fetch("https://backend.bulknetwork.app/vesting-data", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.id) toast.success("Created Sucessfully");
        else toast.error(responseJson.error.message);
        setLoading(false);
        getVestingDataCall();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  //convert image to base64 string
  function handleFileSelect(evt) {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        // console.log(base64String);
        setIcon(base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let token = localStorage.getItem("token");
    // console.log(event.target.name.value)
    var formData = {
      Name: event.target.name.value,
      Ticker: event.target.ticker.value,
      TotalRaize: parseFloat(event.target.totalRaize.value),
      Currency: event.target.currency.value,
      Start: event.target.start.value,
      End: event.target.end.value,
      Price: event.target.price.value,
      Vesting: event.target.vesting.value,
      DealRate: event.target.dealRate.value,
      AllocationForTop10: parseFloat(event.target.allocationForTop10.value),
      AllocationForTier1: parseFloat(event.target.allocationForTier1.value),
      AllocationForTier2: parseFloat(event.target.allocationForTier2.value),
      AllocationForTier3: parseFloat(event.target.allocationForTier3.value),
      AllocationForTier4: parseFloat(event.target.allocationForTier4.value),
      Social: JSON.stringify(socialWebsites),
      Description: draftToHtml(convertToRaw(editorState.getCurrentContent())), //replace new line with br
      CustomSections: customSections,
      socialWebsitesLogos: socialWebsitesLogos,
      Icon: icon,
      CurrentRaize: parseFloat(event.target.currentRaize.value),
      WalletAddress: event.target.walletAddress.value,
      VisibleInAll: event.target.allDealsCheckbox.checked,
      VisibleInTop10: event.target.top10Checkbox.checked,
      VisibleInTier1: event.target.tier1Checkbox.checked,
      VisibleInTier2: event.target.tier2Checkbox.checked,
      VisibleInTier3: event.target.tier3Checkbox.checked,
      VisibleInTier4: event.target.tier4Checkbox.checked,
      ManageSell: event.target.manageSell.checked,
      CustomInfoWindow: event.target.customInfoWindow.checked,
      CustomInfoText: customInfoCheck ? event.target.customInfoText.value : "",
      LastUpdate: new Date().toISOString(),
    };
    console.log(formData);
    fetch("https://backend.bulknetwork.app/update-deals/" + id, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        else toast.error(responseJson.error.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  /* Vesting Date Data */

  const getVestingIsDistributed = () => {
    var result = [];
    for (let j = 0; j < vestingDataList.length; j++) {
      var obj = vestingDataList[j];
      if (obj.IsDistributed) result.push(new Date(obj.date));
      // console.log(JSON.stringify(obj));
    }
    return result;
  };

  const getVestingNotDistributed = () => {
    var result = [];
    for (let j = 0; j < vestingDataList.length; j++) {
      var obj = vestingDataList[j];
      if (!obj.IsDistributed) result.push(new Date(obj.date));
      // console.log(JSON.stringify(obj));
    }
    return result;
  };

  const modifiers = {
    vestingIsDistributed: getVestingIsDistributed(),
    vestingNotDistributed: getVestingNotDistributed(),
  };

  const modifiersStyles = {
    vestingNotDistributed: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    vestingIsDistributed: {
      color: "#ffc107",
      border: "1px solid #EBA724",
    },
  };

  const addNewSocialWebsite = () => {
    setSocialWebsites([...socialWebsites, { type: "", link: "" }]);
  };

  const removeSocialWebsite = (index) => {
    var _socialWebsites = socialWebsites;
    _socialWebsites.splice(index, 1);
    setSocialWebsites([..._socialWebsites]);
  };

  const socialWebsiteValueChange = (index, field, e) => {
    var _socialWebsites = socialWebsites;
    _socialWebsites[index][field] = e.target.value;
    setSocialWebsites([..._socialWebsites]);
  };

  //convert social logo image to base64 string
  function handleFileSelectForSocialLogo(evt, index) {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        // console.log(base64String);
        var _socialWebsitesLogos = socialWebsitesLogos
          ? socialWebsitesLogos
          : [];
        console.log(_socialWebsitesLogos);
        for (let i = 0; i < index; i++) {
          if (_socialWebsitesLogos.length < i) {
            _socialWebsitesLogos.push("");
          } else if (!_socialWebsitesLogos[i]) {
            _socialWebsitesLogos[i] = "";
          }
        }
        _socialWebsitesLogos[index] = base64String;
        setSocialWebsitesLogos([..._socialWebsitesLogos]);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    if (customSections.length > customSectionIndex) {
      if (customSections[customSectionIndex].data.length > 5)
        sendTextToEditorCustomSections(customSections[customSectionIndex].data);
      else sendTextToEditorCustomSections("<p></p>");
    }
  }, [customSections, customSectionIndex]);

  const addNewCustomSection = () => {
    var heading = document.getElementById("customSectionText").value;
    setCustomSection([...customSections, { heading: heading, data: "" }]);
  };

  const removeCustomSection = (index) => {
    var _customSections = customSections;
    _customSections.splice(index, 1);
    setSocialWebsites([..._customSections]);
  };

  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <br />
          <div className="deal-form-page">
            <Container>
              <h1 className="page-heading">Edit Data</h1>

              <Row style={{ color: "white" }}>
                <Col md={6}>
                  <div className="calender-desktop-view">
                    <DayPicker
                      numberOfMonths={3}
                      modifiers={modifiers}
                      modifiersStyles={modifiersStyles}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  {vestingDataList.map((data, key) => {
                    return (
                      <div className="map-label" key={key}>
                        <span onClick={() => selectedVestingDataChange(data)}>
                          {data.date.substr(0, 10)}
                        </span>
                        <i
                          onClick={() => deleteVesting(data.id)}
                          class="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </div>
                    );
                  })}
                </Col>

                <Tabs
                  activeKey={tabKey}
                  onSelect={(k) => (k == "create-vesting" ? setTabKey(k) : "")}
                  className="mb-3"
                >
                  <Tab eventKey="create-vesting" title="Create New Vesting">
                    <Form className="deal-form" onSubmit={handleVestingSubmit}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="vestingDate"
                      >
                        <Form.Label column sm="4" for="vestingDate">
                          Select Vesting Date :{" "}
                        </Form.Label>
                        <Col sm="2">
                          <Form.Control
                            type="date"
                            className="input-design"
                            placeholder=""
                            id="vestingDate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="projectName"
                      >
                        <Form.Label column sm="4" for="projectName">
                          Project Name :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <span id="projectName"> </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="vestingSchedule"
                      >
                        <Form.Label column sm="4" for="vestingSchedule">
                          Vesting Schedule :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <span id="vestingSchedule"> </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="unlockPercentage"
                      >
                        <Form.Label column sm="4" for="unlockPercentage">
                          Unlock Percentage :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="unlockPercentage"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="cumulativeUnlockPercentage"
                      >
                        <Form.Label
                          column
                          sm="4"
                          for="cumulativeUnlockPercentage"
                        >
                          Cumulative unlock percentage :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="cumulativeUnlockPercentage"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="distributionTxHash"
                      >
                        <Form.Label column sm="4" for="distributionTxHash">
                          Distribution Tx Hash :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="distributionTxHash"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="marketSaleTxHash"
                      >
                        <Form.Label column sm="4" for="marketSaleTxHash">
                          Market Sale distribution Tx Hash :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="marketSaleTxHash"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="distributionExecutedCheckbox"
                      >
                        <Form.Label
                          column
                          sm="4"
                          for="distributionExecutedCheckbox"
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Check
                            type="checkbox"
                            id="distributionExecutedCheckbox"
                            style={{ color: "white" }}
                            label="Distribution executed"
                          />
                        </Col>
                      </Form.Group>

                      <Row>
                        <Button type="submit">Create Vesting Data</Button>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab eventKey="update-vesting" title="Update Vesting">
                    <Form
                      className="deal-form"
                      onSubmit={handleVestingUpdateSubmit}
                    >
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="vestingDateUpdate"
                      >
                        <Form.Label column sm="4" controlId="vestingDateUpdate">
                          Select Vesting Date :{" "}
                        </Form.Label>
                        <Col sm="2">
                          <Form.Control
                            type="date"
                            className="input-design"
                            placeholder=""
                            id="vestingDateUpdate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="projectNameUpdate"
                      >
                        <Form.Label column sm="4" controlId="projectNameUpdate">
                          Project Name :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <span id="projectNameUpdate"> </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="vestingScheduleUpdate"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="vestingScheduleUpdate"
                        >
                          Vesting Schedule :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <span id="vestingScheduleUpdate"> </span>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="unlockPercentageUpdate"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="unlockPercentageUpdate"
                        >
                          Unlock Percentage :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="unlockPercentageUpdate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="cumulativeUnlockPercentageUpdate"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="cumulativeUnlockPercentageUpdate"
                        >
                          Cumulative unlock percentage :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="cumulativeUnlockPercentageUpdate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="distributionTxHashUpdate"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="distributionTxHashUpdate"
                        >
                          Distribution Tx Hash :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="distributionTxHashUpdate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="marketSaleTxHashUpdate"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="marketSaleTxHashUpdate"
                        >
                          Market Sale distribution Tx Hash :{" "}
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            className="input-design"
                            placeholder=""
                            id="marketSaleTxHashUpdate"
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="distributionExecutedUpdateCheckbox"
                      >
                        <Form.Label
                          column
                          sm="4"
                          controlId="distributionExecutedUpdateCheckbox"
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Check
                            type="checkbox"
                            id="distributionExecutedUpdateCheckbox"
                            style={{ color: "white" }}
                            label="Distribution executed"
                          />
                        </Col>
                      </Form.Group>

                      <Row>
                        <Button type="submit">Update Vesting Data</Button>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </Row>

              <Form className="deal-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label for="name">Deal Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="name"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="ticker">
                      <Form.Label for="ticker">Ticker</Form.Label>
                      <Form.Control
                        type="text"
                        id="ticker"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="totalRaize">
                      <Form.Label for="totalRaize">Total Raize</Form.Label>
                      <Form.Control
                        type="number"
                        id="totalRaize"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="currency">
                      <Form.Label for="currency">Currency</Form.Label>
                      <Form.Control
                        type="text"
                        id="currency"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="start">
                      <Form.Label for="start">Start Time</Form.Label>
                      <Form.Control
                        type="text"
                        id="start"
                        className="input-design"
                        placeholder=""
                        aria-describedby="startHelpBlock"
                        required
                      />
                      <Form.Text id="startHelpBlock" muted>
                        e.g. 2021-10-26T15:00:00+00:00
                      </Form.Text>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="end">
                      <Form.Label for="end">End Time</Form.Label>
                      <Form.Control
                        type="text"
                        id="end"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label for="price">Price </Form.Label>
                      <Form.Control
                        type="text"
                        id="price"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="vesting">
                      <Form.Label for="vesting">Vesting </Form.Label>
                      <Form.Control
                        type="text"
                        id="vesting"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="dealRate">
                      <Form.Label for="dealRate">Deal Rate </Form.Label>
                      <Form.Control
                        type="text"
                        id="dealRate"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="allocationForTop10">
                      <Form.Label for="allocationForTop10">
                        Allocation For Top 10{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="allocationForTop10"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="allocationForTier1">
                      <Form.Label for="allocationForTier1">
                        Allocation For Tier 1{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="allocationForTier1"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="allocationForTier2">
                      <Form.Label for="allocationForTier2">
                        Allocation For Tier 2{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="allocationForTier2"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="allocationForTier3">
                      <Form.Label for="allocationForTier3">
                        Allocation For Tier 3{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="allocationForTier3"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="allocationForTier4">
                      <Form.Label for="allocationForTier4">
                        Allocation For Tier 4{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="allocationForTier4"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="currentRaize">
                      <Form.Label for="currentRaize">Current Raize </Form.Label>
                      <Form.Control
                        type="number"
                        id="currentRaize"
                        className="input-design"
                        placeholder=""
                        min="0"
                        step="0.005"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3" controlId="walletAddress">
                      <Form.Label for="walletAddress">
                        Wallet Address{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="walletAddress"
                        className="input-design"
                        placeholder=""
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" style={{ marginTop: "30px" }}>
                    <Form.Group className="mb-3" controlId="visibleIn">
                      <Form.Check
                        type="checkbox"
                        id="manageSell"
                        className="input-design"
                        label="Manage Sell Activated"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" style={{ marginTop: "30px" }}>
                    <Form.Group className="mb-3" controlId="customWindowInfo">
                      <Form.Check
                        type="checkbox"
                        id="customInfoWindow"
                        className="input-design"
                        onClick={() =>
                          customInfoCheck
                            ? setCustomInfoCheck(false)
                            : setCustomInfoCheck(true)
                        }
                        label="Custom Info Window Activated"
                      />
                      <Form.Control
                        type="text"
                        id="customInfoText"
                        className="input-design"
                        style={{ display: customInfoCheck ? "block" : "none" }}
                        placeholder="Custom Info Text"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Form.Group className="mb-3 user-img" controlId="icon">
                      <Form.Control
                        type="file"
                        id="icon"
                        className="input-design"
                        placeholder=""
                        onChange={handleFileSelect}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="visibleIn">
                      <Form.Label>Deal Visible in</Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="allDealsCheckbox"
                        style={{ color: "white" }}
                        label="All Deals Page"
                      />
                      <Form.Check
                        type="checkbox"
                        id="top10Checkbox"
                        style={{ color: "white" }}
                        label="Top 10"
                      />
                      <Form.Check
                        type="checkbox"
                        id="tier1Checkbox"
                        style={{ color: "white" }}
                        label="Tier 1"
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group className="mb-3" controlId="visibleIn">
                      <Form.Label style={{ visibility: "hidden" }}>
                        Deal Visible in
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="tier2Checkbox"
                        style={{ color: "white" }}
                        label="Tier 2"
                      />
                      <Form.Check
                        type="checkbox"
                        id="tier3Checkbox"
                        style={{ color: "white" }}
                        label="Tier 3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="tier4Checkbox"
                        style={{ color: "white" }}
                        label="Tier 4"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "10px" }}>
                  <Row style={{ margin: "10px" }}>
                    <Col>
                      <Form.Label>Social Links</Form.Label>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-info"
                        onClick={() => addNewSocialWebsite()}
                      >
                        Add New Link
                      </Button>
                    </Col>
                  </Row>

                  {socialWebsites.map((data, index) => {
                    return (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Select
                            id={"social-type-" + index}
                            value={data.type || ""}
                            onChange={(e) =>
                              socialWebsiteValueChange(index, "type", e)
                            }
                          >
                            <option>Select Social Link Type</option>
                            <option value="discord">Discord</option>
                            <option value="github">Github</option>
                            <option value="website">Website</option>
                            <option value="link">Link</option>
                            <option value="medium">Medium</option>
                            <option value="telegram">Telegram</option>
                            <option value="twitter">Twitter</option>
                            <option value="youtube">Youtube</option>
                            <option value="other">Other</option>
                          </Form.Select>
                          {socialWebsites[index].type == "other" ? (
                            <Form.Control
                              type="file"
                              id={"social-logo" + index}
                              className="input-design"
                              placeholder=""
                              onChange={(e) =>
                                handleFileSelectForSocialLogo(e, index)
                              }
                            />
                          ) : null}
                        </Col>
                        <Col md={6}>
                          <Form.Control
                            type="text"
                            id={"social-link-" + index}
                            placeholder="Enter Social Link"
                            value={data.link || ""}
                            style={{ color: "#fff" }}
                            onChange={(e) =>
                              socialWebsiteValueChange(index, "link", e)
                            }
                          ></Form.Control>
                        </Col>
                        <Col md={2}>
                          <Button
                            variant="outline-danger"
                            onClick={() => removeSocialWebsite(index)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </Row>
                <br />
                <br />
                <br />

                <Row>
                  <h4 style={{ color: "white" }}>Enter Project Description</h4>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbar-class"
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Row>
                <br />
                <br />
                <br />

                <Row>
                  <h4 style={{ color: "white" }}>Enter Custom Section</h4>
                  <Row>
                    <Col md="4" style={{ marginTop: "30px" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="customSectionText"
                      >
                        <Form.Control
                          type="text"
                          id="customSectionText"
                          className="input-design"
                          placeholder="Custom Section Heading"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4" style={{ marginTop: "30px" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="customSectionButton"
                      >
                        <Button
                          id="customSectionButton"
                          variant="outline-danger"
                          onClick={() => addNewCustomSection()}
                        >
                          Add New Heading
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>

                  {customSections.map((data, index) => {
                    return (
                      <Row key={index}>
                        <Col md={6}>
                          <Button
                            variant="outline-success"
                            style={{ width: "100%" }}
                            placeholder="Enter Social Link"
                            onClick={() => setCustomSectionIndex(index)}
                          >
                            {data.heading}
                          </Button>
                        </Col>
                        <Col md={2}>
                          <Button
                            variant="outline-danger"
                            onClick={() => removeCustomSection(index)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <br />
                  <br />
                  <br />
                  <Editor
                    editorState={editorStateCustomSections}
                    toolbarClassName="toolbar-class"
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={onEditorStateChangeCustomSections}
                  />
                </Row>
                <br />
                <br />
                <br />

                <Row>
                  {/* <span style={{ color: "white" }}>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</span> */}
                  {/* <Form.Group className="mb-3" controlId="description">
        <Form.Label for="description">Description </Form.Label>
        <Form.Control as="textarea" id="description" className="input-design" rows={5} style={{height:'200px'}} required/>
        <Form.Text id="description" muted>
            put paragraph in &lt;p&gt; paragraph_text &lt;/p&gt; and links like Google: &lt;a href="www.google.com"&gt;Google Link&lt;/a&gt;<br/>
            To set font size of text &lt;p style="font-size:14px;"&gt; Font Size 14px &lt;/p&gt;<br/>
            To set color of text &lt;p style="color:Blue;"&gt; Text in Blue Color &lt;/p&gt;<br/>
            To set both font size and color &lt;p style="font-size:14px; color:Blue;"&gt; Text with Font Size 14px and Blue Color &lt;/p&gt;<br/>
            To set text underline &lt;u&gt; Underline Text &lt;/u&gt;<br/>
            To set text bold &lt;b&gt; Bold Text &lt;/b&gt;<br/>
            To add image &lt;img src="image/url" /&gt;
        </Form.Text>
    </Form.Group>  */}
                </Row>

                <Row>
                  <Col className="submit-btn">
                    <Button type="submit">SUBMIT</Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default Edit;
