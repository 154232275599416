import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify"; //toster
import "./Style.css";
// Route to contribute page
import { useParams, useHistory } from "react-router-dom";
//utils function
import { isFinished, isActive, isUpcoming, getDealStauts } from "./utils";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
import { WalletContext } from "./WalletContext";
import Menubar from "../components/pageComponents/navigationBar";
import Sidebar from "../components/pageComponents/sideBar";
import NoAccessModel from "../components/pageComponents/noAccessModel";
import Up from "../components/assects/up.png";
import Down from "../components/assects/down.png";

const MemberArea = () => {
  const { type } = useParams();
  const [dealsData, setDealsData] = useState([{}]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedOrderText, setSelectedOrderText] =
    useState("Date Latest First");
  const [loading, setLoading] = useState(false); //Loading bar

  const [modalShow, setModalShow] = React.useState(false);
  //import hooks for object
  const [walletData, setWalletData] = useContext(WalletContext);
  // jump to contribute page
  const history = useHistory();

  const getDeals = async (selectedOrder) => {
    setLoading(true);
    // Select filter
    let filter = "";
    switch (type) {
      case "top10":
        filter = "VisibleInTop10";
        break;
      case "tier1":
        filter = "VisibleInTier1";
        break;
      case "tier2":
        filter = "VisibleInTier2";
        break;
      case "tier3":
        filter = "VisibleInTier3";
        break;
      case "tier4":
        filter = "VisibleInTier4";
        break;
    }
    // API call to fetch deals
    await fetch(
      'https://backend.bulknetwork.app/get-all-deals?filter={"offset": 0,"limit": 500,"skip": 0,"order": "' +
        selectedOrder +
        '","where": {"additionalProp1": {}},"fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"AllocationForTier1": true,"AllocationForTier4": true,"Description": true,"CurrentRaize": true,"' +
        filter +
        '": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        let data = [];
        if (result.success) {
          if (tabIndex == 0) {
            //set all deals
            result.res.forEach((item) => {
              if (item[filter]) {
                data.push(item);
              }
              // console.log(item[filter])
            });
          } else {
            if (tabIndex == 1) {
              //set active deals only
              result.res.forEach((item) => {
                if (isActive(item.Start, item.End) && item[filter]) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 2) {
              //set upcoming deals only
              result.res.forEach((item) => {
                if (isUpcoming(item.Start) && item[filter]) {
                  data.push(item);
                }
              });
            } else if (tabIndex == 3) {
              //set finished deals only
              result.res.forEach((item) => {
                if (isFinished(item.End) && item[filter]) {
                  data.push(item);
                }
              });
            }
          }
          setDealsData(data);
          setLoading(false);
        } else {
          toast.error("Error while loading deals!");
        }
      });
  };

  const GetDealLogo = (props) => {
    const [imageData, setImageData] = useState(null);
    useEffect(() => {
      if (props.id) {
        fetch(
          "https://backend.bulknetwork.app/get-single-deal/" +
            props.id +
            '?filter={"fields": {"Icon": true}}'
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              setImageData("data:image/png;base64," + result.res.Icon);
            }
          });
      }
    }, [props.id]);

    return (
      <>
        {imageData ? (
          <img src={imageData} />
        ) : (
          <img src={imageData} hidden={true} />
        )}
      </>
    );
  };

  const handleDealType = async (index) => {
    // Handle tab selected
    if (index !== tabIndex) {
      setLoading(true);
      setTabIndex(index);
    }
  };

  const isTop10 = async () => {
    await fetch("https://bulkstaking.ew.r.appspot.com/process")
      .then((res) => res.json())
      .then((result) => {
        // console.log(result.tops);
        let top10 = false;
        result.tops.forEach(function (item, index) {
          if (walletData.walletAddress == item.toLowerCase()) {
            console.log(item);
            top10 = true;
          }
        });
        if (top10) {
          getDeals("LastUpdate DESC");
        } else {
          setModalShow(true);
          setDealsData([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(true);

    let filter = 0;
    switch (type) {
      case "tier1":
        filter = 1;
        break;
      case "tier2":
        filter = 2;
        break;
      case "tier3":
        filter = 3;
        break;
      case "tier4":
        filter = 4;
        break;
    }
    //wallet data
    if (!walletData.isConnected) {
      toast.error("Please Connect wallet first!");
    } else if (type == "top10") {
      isTop10();
    } else if (walletData.tier != filter) {
      // toast.error('Unauthorised for access!')
      setModalShow(true);
      setDealsData([]);
      setLoading(false);
    } else {
      getDeals("LastUpdate DESC");
    }
    // console.log(JSON.stringify(dealsData));
  }, [tabIndex, type]);

  const getPageHeading = () => {
    let heading = "";
    switch (type) {
      case "top10":
        heading = "Top 10";
        break;
      case "tier1":
        heading = "Tier 1";
        break;
      case "tier2":
        heading = "Tier 2";
        break;
      case "tier3":
        heading = "Tier 3";
        break;
      case "tier4":
        heading = "Tier 4";
        break;
    }
    return heading;
  };

  return (
    <>
      <div className="dashboard">
        <Container className="box-container">
          <div className="content d-flex flex-wrap">
            <Col md={3} className="left-nav">
              <div className="cnt-lft">
                <Menubar></Menubar>
                <div className="hd-down">
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                    mobileView={true}
                  ></ConnectWallet>
                </div>
              </div>
            </Col>

            <Col md={6} className="right-content">
              <div className="deal-row none-mb">
                <div className="d-flex align-items-center">
                  <div className="heading-row">
                    <h1>{getPageHeading()}</h1>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* Connect Wallet Button */}
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                  ></ConnectWallet>
                </div>
              </div>

              <>
                <NoAccessModel
                  modalShow={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
              <div className="tab-box">
                {/* Options */}
                <div className="tab-row">
                  <a
                    className={tabIndex == 0 ? "active" : ""}
                    onClick={() => handleDealType(0)}
                  >
                    All Deals
                  </a>
                  <a
                    className={tabIndex == 1 ? "active" : ""}
                    onClick={() => handleDealType(1)}
                  >
                    Active
                  </a>
                  <a
                    className={tabIndex == 2 ? "active" : ""}
                    onClick={() => handleDealType(2)}
                  >
                    Upcoming
                  </a>
                  <a
                    className={tabIndex == 3 ? "active" : ""}
                    onClick={() => handleDealType(3)}
                  >
                    Finished
                  </a>
                  <div className="tab-right">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span className="sort-by">
                          <i class="fa fa-filter" aria-hidden="true"></i>Sort
                          By:
                        </span>{" "}
                        <span> {selectedOrderText} </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("LastUpdate DESC");
                            setSelectedOrderText("Date New First");
                          }}
                        >
                          By date: New First
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("LastUpdate");
                            setSelectedOrderText("Date Old First");
                          }}
                        >
                          By date: Old First
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("Name");
                            setSelectedOrderText("Name A to Z");
                          }}
                        >
                          By name: A - Z
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            getDeals("Name DESC");
                            setSelectedOrderText("Name Z to A");
                          }}
                        >
                          By name: Z - A
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {/* tab row closed */}

                {/* Deals Cards */}
                <div className="tab-content">
                  <Row>
                    {/* Loading bar */}
                    {loading ? (
                      <div>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="light"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>
                        {/* For Deals set the data */}
                        {dealsData.map((data, key) => {
                          return (
                            <div className="all-deal-box">
                              <Row className=" header-chart align-items-center">
                                <Col md={8}>
                                  <div className="user-name-row dl-dt">
                                    <GetDealLogo id={data.id} />
                                    <div>
                                      <p>{data.Name}</p>
                                      <p className="small-text">
                                        {data.Ticker}
                                      </p>
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  md={4}
                                  className="d-flex justify-content-end none-mb"
                                >
                                  {isFinished(data.End) ? (
                                    <button
                                      onClick={(e) =>
                                        history.push("/contribute/" + data.id)
                                      }
                                      className="btn-main"
                                    >
                                      View Deal
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        history.push("/contribute/" + data.id)
                                      }
                                      className="btn-main"
                                    >
                                      Join Deal
                                    </button>
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col md={4}>
                                  <div class="deal-info">
                                    <ul>
                                      <li>
                                        <span className="text-heading">
                                          Max Allocation
                                        </span>{" "}
                                        <span>
                                          {" "}
                                          <img src={Up} />
                                          {data.AllocationForTier1}{" "}
                                          {data.Currency}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="text-heading">
                                          Min Allocation
                                        </span>{" "}
                                        <span>
                                          <img src={Down} />
                                          {data.AllocationForTier4}{" "}
                                          {data.Currency}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="chart-box">
                                    <svg
                                      width="56"
                                      height="30"
                                      viewBox="0 0 56 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2 28C4.41676 25.3389 7.63911 18.2204 13.2782 18.0208C18.9173 17.8212 23.1467 24.8066 28.9872 24.8066C34.8277 24.8066 37.4358 13.3385 44.6961 8.04155C48.107 5.55309 54.5646 0.457338 53.9604 2.45318"
                                        stroke="url(#paint0_linear_2_459)"
                                        stroke-width="3"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_2_459"
                                          x1="53.9604"
                                          y1="2"
                                          x2="2.19124"
                                          y2="28.3221"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop
                                            stop-color="#587DFF"
                                            stop-opacity="0"
                                          />
                                          <stop
                                            offset="0.535704"
                                            stop-color="#587DFF"
                                          />
                                          <stop
                                            offset="1"
                                            stop-color="#587DFF"
                                            stop-opacity="0"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                    <div className="chart-box-content">
                                      <p>Total Raize</p>
                                      <p class="price-chart">
                                        <span>
                                          {data.TotalRaize} {data.Currency}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="chart-box">
                                    <div className="progress-bar-row">
                                      <p className="pr-percent">
                                        {(
                                          (data.CurrentRaize /
                                            data.TotalRaize) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </p>
                                      <ProgressBar
                                        now={
                                          (data.CurrentRaize /
                                            data.TotalRaize) *
                                          100
                                        }
                                      />
                                    </div>
                                    <div className="chart-box-content">
                                      <p>Status</p>
                                      {isFinished(data.End) ? (
                                        <p class="price-chart">
                                          <i
                                            class="fa fa-circle"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Finished
                                        </p>
                                      ) : (
                                        getDealStauts(data.Start, data.End)
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              {isFinished(data.End) ? (
                                <button
                                  onClick={(e) =>
                                    history.push("/contribute/" + data.id)
                                  }
                                  className="btn-main show-mobile"
                                >
                                  View Deal
                                </button>
                              ) : (
                                <button
                                  onClick={(e) =>
                                    history.push("/contribute/" + data.id)
                                  }
                                  className="btn-main show-mobile"
                                >
                                  Join Deal
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </Col>

            <Sidebar walletData={walletData}></Sidebar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MemberArea;
