import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify"; //toster
import "./Style.css";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
import { WalletContext } from "./WalletContext";
import Menubar from "../components/pageComponents/navigationBar";
import Sidebar from "../components/pageComponents/sideBar";
// Route to contribute page
import { useHistory } from "react-router-dom";
import RegistrationModel from "../components/pageComponents/registrationModel";

const Profile = () => {
  const [registered, setRegistered] = useState(false); //user is registered
  const [editData, setEditData] = useState(false); //editing email and telegram data
  const [userEmail, setUserEmail] = useState("-"); //user email
  const [userTelegram, setUserTelegram] = useState("-"); //user telegram handle

  const [modalShow, setModalShow] = React.useState(false);
  //import hooks for object
  const [walletData, setWalletData] = useContext(WalletContext);

  // jump to contribute page
  const history = useHistory();

  const getDetails = async () => {
    // API call to fetch deals
    var parameters = {
      walletAddress: walletData.walletAddress,
    };
    console.log(parameters);
    fetch("https://backend.bulknetwork.app/get-one", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(parameters),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          setUserEmail(responseJson.data.Email);
          setUserTelegram(responseJson.data.TelegramHandle);
          setRegistered(true);
        } else {
          setUserEmail("-");
          setUserTelegram("-");
          setRegistered(false);
          // toast.error(responseJson.error.message)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (walletData.isConnected) getDetails();
  }, [walletData]);

  const editButtonCall = () => {
    if (registered) {
      //already registered
      if (editData) {
        setEditData(false);
      } else {
        setEditData(true);
        document.getElementById("email").value = userEmail;
        document.getElementById("telegramId").value = userTelegram;
      }
    } else {
      //register
      setModalShow(true);
    }
  };

  const saveButtonCall = async () => {
    // update to server
    //API call to server
    let userData = {
      WalletAddress: walletData.walletAddress,
      Email: document.getElementById("email").value,
      TelegramHandle: document.getElementById("telegramId").value,
    };
    await fetch("https://backend.bulknetwork.app/create-users", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) toast.success(responseJson.message);
        else toast.error(responseJson.error.message);
      })
      .catch((error) => {
        console.error(error);
      });
    setUserEmail(userData.Email);
    setUserTelegram(userData.TelegramHandle);
    setEditData(false);
  };

  return (
    <>
      <div className="dashboard">
        <Container className="box-container">
          <div className="content d-flex flex-wrap">
            <Col md={3} className="left-nav">
              <div className="cnt-lft">
                <Menubar></Menubar>
                <div className="hd-down">
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                    mobileView={true}
                  ></ConnectWallet>
                </div>
              </div>
            </Col>
            <Col md={6} className="right-content">
              <div className="deal-row none-mb">
                <div className="d-flex align-items-center">
                  <div className="heading-row">
                    <h1 style={{ fontSize: "28px" }}>My Profile</h1>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* Connect Wallet Button */}
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                  ></ConnectWallet>
                </div>
              </div>

              <div className="all-deal-box">
                <Row>
                  <Col md={8} style={{ marginBottom: "5px" }}>
                    <div style={{ margin: "2px" }}>
                      <span class="text-heading">Email</span>
                      <input
                        className="input-field-design"
                        type="email"
                        id="email"
                        hidden={!editData}
                        required
                      ></input>
                      <span class="amount" id="emailValue" hidden={editData}>
                        {userEmail}
                      </span>
                    </div>
                    <div style={{ margin: "2px" }}>
                      <span class="text-heading">Telegram Handle</span>
                      <input
                        className="input-field-design"
                        type="text"
                        id="telegramId"
                        hidden={!editData}
                        required
                      ></input>
                      <span
                        class="amount"
                        id="telegramIdValue"
                        hidden={editData}
                      >
                        {userTelegram}
                      </span>
                    </div>
                  </Col>
                  <Col md={4} className="d-flex justify-content-end">
                    <button
                      className="btn-main full-width-mobile"
                      onClick={editButtonCall}
                      disabled={!walletData.isConnected}
                    >
                      {registered
                        ? editData
                          ? "Cancel"
                          : "Change"
                        : "Register"}
                    </button>
                    {editData ? (
                      <button
                        className="btn-main full-width-mobile"
                        style={{ marginLeft: "15px" }}
                        onClick={saveButtonCall}
                      >
                        Save
                      </button>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <div className="button-row"></div>
                <>
                  <RegistrationModel
                    walletAddress={walletData.walletAddress}
                    modalShow={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </>

                <div className="profile-content">
                  <div
                    className="bottom-content"
                    style={{ textAlign: "center" }}
                  >
                    More user states to come soon
                  </div>{" "}
                  {/*box row closed */}
                </div>
                {/* tab content closed */}
              </div>
            </Col>{" "}
            {/* right content closed   */}
            <Sidebar walletData={walletData}></Sidebar>
          </div>{" "}
          {/*content */}
        </Container>
      </div>
    </>
  );
};

export default Profile;
