import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify"; //toster
import "react-toastify/dist/ReactToastify.css";
import Contribute from "./pages/Contribute";
import Deals from "./pages/Deals";
import MyDeals from "./pages/MyDeals";
import Profile from "./pages/Profile";
import MemberArea from "./pages/MemberArea";
import Login from "./pages/login";
import Dashboard from "./pages/admin/Dashboard";
import Create from "./pages/admin/Create";
import Edit from "./pages/admin/Edit";
import UpdateAdmin from "./pages/admin/UpdateAdmin";
import UploadDealData from "./pages/admin/UploadDealData";
import ContributionEdit from "./pages/admin/ContributionEdit";
import RegisterAccounts from "./pages/admin/RegisterAccounts";
import ManageSell from "./pages/admin/ManageSell";
import UpdateLandingPage from "./pages/admin/UpdateLandingPage";
import Token from "./pages/Token";

// import Test from "./pages/test";

import { useContext } from "react";
import { LoginContext } from "./pages/LoginContext";
import { Recaptcha } from "./components/recaptcha/Recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function App() {
  const [authorize, setAuthorize] = useContext(LoginContext);

  const checkAuthentication = async () => {
    await handleReCaptchaVerify();
    let token = localStorage.getItem("token");
    let data = { tokenData: token };
    console.log(data);
    fetch("https://backend.bulknetwork.app/get-profile-by-token", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("fetch data by token", responseJson);
        if (responseJson.success) {
          console.log("set value true");
          setAuthorize(true);
          console.log(authorize);
        } else {
          console.log("set value false");
          setAuthorize(false);
          console.log(authorize);
        }
      })
      .catch((error) => {
        console.error(error);
        setAuthorize(false);
      });
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    localStorage.setItem("recaptchaToken", token);
    return token;
  };

  return (
    <>
      <div onLoad={handleReCaptchaVerify()}></div>
      {/* Area to place menus and page heading stuff */}
      <ToastContainer autoClose={5000} limit={3}></ToastContainer>
      <Router>
        <Switch>
          <Route exact path="/">
            <Deals />
          </Route>
          <Route path="/contribute/:id">
            <Contribute />
          </Route>
          <Route path="/my-deals">
            <MyDeals />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          {/* <Route path="/test">
          <Test />
        </Route> */}
          <Route path="/token">
            <Token />
          </Route>
          <Route path="/member-area/:type">
            <MemberArea />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dashboard" onClick={checkAuthentication}>
            <Dashboard authorize={authorize} />
          </Route>
          <Route path="/create" onClick={checkAuthentication}>
            <Create authorize={authorize} />
          </Route>
          <Route path="/edit/:id" onClick={checkAuthentication}>
            <Edit authorize={authorize} />
          </Route>
          <Route path="/register-accounts" onClick={checkAuthentication}>
            <RegisterAccounts authorize={authorize} />
          </Route>
          <Route path="/manage-sell/:id" onClick={checkAuthentication}>
            <ManageSell authorize={authorize} />
          </Route>
          <Route path="/update-admin" onClick={checkAuthentication}>
            <UpdateAdmin authorize={authorize} />
          </Route>
          <Route path="/update-landing-page" onClick={checkAuthentication}>
            <UpdateLandingPage authorize={authorize} />
          </Route>
          <Route path="/upload-deal-data/:id" onClick={checkAuthentication}>
            <UploadDealData authorize={authorize} />
          </Route>
          <Route path="/edit-contribution/:id" onClick={checkAuthentication}>
            <ContributionEdit authorize={authorize} />
          </Route>
          <Route path="*">
            <div>
              <h2>Page Not found</h2>
              <p>
                You are connecting with wrong url. Please{" "}
                <a href="/">Click Here</a> for home page.
              </p>
            </div>
          </Route>
        </Switch>
      </Router>
      <Recaptcha />
    </>
  );
}

export default App;
