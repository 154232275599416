import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify"; //toster
import "./Style.css";
import Menubar from "../components/pageComponents/navigationBar";
import Sidebar from "../components/pageComponents/sideBar";
import { WalletContext } from "./WalletContext";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
// Route to contribute page
import { useHistory } from "react-router-dom";
//utils function
import { isFinished, isActive, isUpcoming, getDealStauts } from "./utils";
//sample data
// import {result} from "./data";
import Up from "../components/assects/up.png";
import Down from "../components/assects/down.png";
//Calender part
import DayPicker from "react-day-picker";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import "react-day-picker/lib/style.css";

const MyDeals = () => {
  const [dealsData, setDealsData] = useState([]);
  const [vestingDeal, setVestingDeal] = useState([]);
  const [selectedVestingDeal, setSelectedVestingDeal] = useState(null);
  const [vestingCalendarData, setVestingCalendarData] = useState([]);
  const [selectedVestingDealHold, setSelectedVestingDealHold] = useState(false);
  const [selectedOrderText, setSelectedOrderText] =
    useState("Date Latest First");
  const [loading, setLoading] = useState(false); //Loading bar
  //import hooks for object
  const [walletData, setWalletData] = useContext(WalletContext);

  // jump to contribute page
  const history = useHistory();

  const getDeals = async () => {
    if (!walletData.isConnected) {
      toast.error("Please Connect wallet first!");
    }
    let dealsList;
    //API call for check user is registered
    let userData = {
      userWalletAddress: walletData.walletAddress,
    };
    console.log(userData);
    await fetch("https://backend.bulknetwork.app/get-deals-by-walletAddress", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      cache: "no-cache",
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        dealsList = [...new Set(responseJson.data)];
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log(dealsList)

    let data = [];
    let dataVesting = [];
    for (let i = 0; i < dealsList.length; i++) {
      var dealName = "";
      var vesting = "";
      // API call to fetch deals
      await fetch(
        "https://backend.bulknetwork.app/get-single-deal/" +
          dealsList[i] +
          '?filter={"offset": 0,"limit": 500,"skip": 0,"order": "string","fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"Price": true,"Vesting": true,"DealRate": true,"AllocationForTier1": true,"AllocationForTier2": true,"AllocationForTier3": true,"AllocationForTier4": true,"Description": true,"Icon": true,"CurrentRaize": true,"WalletAddress": true,"VisibleInAll": true,"VisibleInTop10": true,"VisibleInTier1": true,"VisibleInTier2": true,"VisibleInTier3": true,"VisibleInTier4": true,"Vesting": true,"LastUpdate":true}}'
      )
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.success) {
            dealName = result.res.Name;
            vesting = result.res.Vesting;
            data.push(result.res);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      await fetch(
        'https://backend.bulknetwork.app/vesting-data?filter={"offset":0,"limit":500,"skip":0,"order":"string","where":{"DealId":"' +
          dealsList[i] +
          '"},"fields":{"DealId":true,"id":true,"date":true,"UnlockPercentage":true,"CumulativeUnlock":true,"DistributionTrHash":true,"MarketSaleTrHash":true,"IsDistributed":true}}'
      )
        .then((res) => res.json())
        .then((result) => {
          var newData = {
            dealId: dealsList[i],
            Name: dealName,
            Vesting: vesting,
            data: result,
          };
          console.log(newData);
          dataVesting.push(newData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // console.log(data);
    setDealsData(data);
    setVestingCalendarData(dataVesting);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getDeals();
    // console.log(JSON.stringify(dealsData));
  }, [walletData.walletAddress]);

  /* Vesting Date Data */
  const getModiferData = () => {
    var result = {};
    vestingCalendarData.map((vestingDealData, key) => {
      var resultIsDistributed = [];
      var resultNotDistributed = [];
      if (vestingDealData.data != undefined) {
        for (let j = 0; j < vestingDealData.data.length; j++) {
          var obj = vestingDealData.data[j];
          if (obj.IsDistributed) resultIsDistributed.push(new Date(obj.date));
          if (!obj.IsDistributed) resultNotDistributed.push(new Date(obj.date));
        }
      }
      result["vestingIsDistributed" + key] = resultIsDistributed;
      result["vestingNotDistributed" + key] = resultNotDistributed;
    });
    console.log(result);
    return result;
  };

  var colors = {
    brightyellow: "#FFAA1D",
    aqua: "#00ffff",
    black: "#000000",
    blue: "#0000ff",
    brown: "#a52a2a",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgrey: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    peridot: "#ABAD48",
    fuzzywuzzy: "#87421F",
    peach: "#FFCBA4",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkviolet: "#9400d3",
    fuchsia: "#ff00ff",
    gold: "#ffd700",
    green: "#008000",
    indigo: "#4b0082",
    khaki: "#f0e68c",
    lightblue: "#add8e6",
    lightgreen: "#90ee90",
    lightgrey: "#d3d3d3",
    lightpink: "#ffb6c1",
    lime: "#00ff00",
    magenta: "#ff00ff",
    maroon: "#800000",
    navy: "#000080",
    olive: "#808000",
    orange: "#ffa500",
    pink: "#ffc0cb",
    purple: "#800080",
    violet: "#800080",
    red: "#ff0000",
    silver: "#c0c0c0",
    yellow: "#ffff00",
  };

  const modifiers = getModiferData();

  const getModiferStylesData = () => {
    var result = {};
    vestingCalendarData.map((vestingDealData, key) => {
      result["vestingIsDistributed" + key] = {
        color: Object.values(colors)[key],
        border: "1px solid " + Object.values(colors)[key],
      };
      result["vestingNotDistributed" + key] = {
        color: "white",
        backgroundColor: Object.values(colors)[key],
      };
    });
    console.log(result);
    return result;
  };

  const modifiersStyles = getModiferStylesData();

  const isDateSame = (d1, d2) => {
    var d1Date = d1.getDate();
    var d1Month = d1.getMonth();
    var d1Year = d1.getFullYear();
    var d2Date = d2.getDate();
    var d2Month = d2.getMonth();
    var d2Year = d2.getFullYear();
    if (d1Date == d2Date && d1Month == d2Month && d1Year == d2Year) return true;
    return false;
  };

  const handleDayMouseEnter = (day) => {
    // alert(day)
    var result = [];
    vestingCalendarData.map((vestingDealData, key) => {
      if (vestingDealData.data != undefined) {
        for (let j = 0; j < vestingDealData.data.length; j++) {
          var obj = vestingDealData.data[j];
          if (isDateSame(day, new Date(obj.date))) {
            var newData = {
              dealId: vestingDealData.dealId,
              Name: vestingDealData.Name,
              Vesting: vestingDealData.Vesting,
              data: obj,
            };
            result.push(newData);
          }
        }
      }
    });
    if (result.length > 0) setSelectedVestingDeal(result);
  };

  const closeVestingDataHold = () => {
    setSelectedVestingDealHold(false);
    setSelectedVestingDeal(null);
  };

  const handleDayMouseLeave = (day) => {
    if (!selectedVestingDealHold) setSelectedVestingDeal(null);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Text Copied");
  };

  const setSortFilter = (selectedOrder) => {
    setLoading(true);
    var array = dealsData;

    if (selectedOrder == "LastUpdate DESC") {
      array.sort((a, b) =>
        new Date(a.LastUpdate) < new Date(b.LastUpdate) ? 1 : -1
      );
      setDealsData(array);
      setSelectedOrderText("Date New First");
    } else if (selectedOrder == "LastUpdate") {
      array.sort((a, b) =>
        new Date(a.LastUpdate) < new Date(b.LastUpdate) ? 1 : -1
      );
      setDealsData(array);
      setSelectedOrderText("Date Old First");
    } else if (selectedOrder == "Name") {
      array.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      setDealsData(array);
      setSelectedOrderText("Name A to Z");
    } else if (selectedOrder == "Name DESC") {
      array.sort((a, b) => (a.Name < b.Name ? 1 : -1));
      setDealsData(array);
      setSelectedOrderText("Name Z to A");
    }

    setLoading(false);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = async () => {
    console.log("call");
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    localStorage.setItem("recaptchaToken", token);
    return token;
  };

  return (
    <>
      <div onLoad={handleReCaptchaVerify()}></div>
      <div className="dashboard">
        <Container className="box-container">
          <div className="content d-flex flex-wrap">
            <Col md={3} className="left-nav">
              <div className="cnt-lft">
                <Menubar></Menubar>
                <div className="hd-down">
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                    mobileView={true}
                  ></ConnectWallet>
                </div>
              </div>
            </Col>

            <Col md={6} className="right-content">
              <div className="deal-row none-mb">
                <div className="d-flex align-items-center">
                  <div className="heading-row">
                    <h1>My Deals</h1>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* Connect Wallet Button */}
                  <ConnectWallet
                    walletData={walletData}
                    setWalletData={setWalletData}
                  ></ConnectWallet>
                </div>
              </div>

              <div className="calender-desktop-view">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <DayPicker
                    numberOfMonths={3}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    onDayMouseEnter={handleDayMouseEnter}
                    onDayMouseLeave={handleDayMouseLeave}
                    onDayClick={() => setSelectedVestingDealHold(true)}
                  />
                  <div
                    className="vestingHoverDataModal"
                    style={{
                      visibility:
                        selectedVestingDeal == null && !selectedVestingDealHold
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <div className="closed">
                      <a
                        style={{ color: "white", border: "none" }}
                        onClick={closeVestingDataHold}
                      >
                        <span style={{ fontSize: "25px" }}>×</span>
                      </a>
                    </div>
                    {selectedVestingDeal != null &&
                      selectedVestingDeal.map(
                        (selectedVestingDealData, key) => {
                          return (
                            <>
                              <span className="light-blue-text">
                                Project Name:{" "}
                              </span>
                              <span className="white-text">
                                {selectedVestingDealData?.Name}
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Vesting Schedule:{" "}
                              </span>
                              <span className="white-text">
                                {selectedVestingDealData?.Vesting}
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Unlock Percentage:{" "}
                              </span>
                              <span className="white-text">
                                {selectedVestingDealData?.data.UnlockPercentage}
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Cumulative unlock percentage:{" "}
                              </span>
                              <span className="white-text">
                                {selectedVestingDealData?.data.CumulativeUnlock}
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Distribution Tx Hash:{" "}
                              </span>
                              <span
                                style={{ float: "right" }}
                                onClick={() => {
                                  copyToClipboard(
                                    selectedVestingDealData?.data
                                      .DistributionTrHash
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  fill="none"
                                  color="white"
                                >
                                  <rect
                                    x="9"
                                    y="9"
                                    width="13"
                                    height="13"
                                    rx="2"
                                    ry="2"
                                  />
                                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                </svg>
                              </span>
                              <br />
                              <span className="hash">
                                <a
                                  target="_blank"
                                  href={
                                    selectedVestingDealData?.data
                                      .DistributionTrHash
                                  }
                                >
                                  {
                                    selectedVestingDealData?.data
                                      .DistributionTrHash
                                  }
                                </a>
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Market Sale distribution Tx Hash:{" "}
                              </span>
                              <span
                                style={{ float: "right" }}
                                onClick={() => {
                                  copyToClipboard(
                                    selectedVestingDealData?.data
                                      .MarketSaleTrHash
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  fill="none"
                                  color="white"
                                >
                                  <rect
                                    x="9"
                                    y="9"
                                    width="13"
                                    height="13"
                                    rx="2"
                                    ry="2"
                                  />
                                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                </svg>
                              </span>
                              <br />
                              <span className="hash">
                                <a
                                  target="_blank"
                                  href={
                                    selectedVestingDealData?.data
                                      .MarketSaleTrHash
                                  }
                                >
                                  {
                                    selectedVestingDealData?.data
                                      .MarketSaleTrHash
                                  }
                                </a>
                              </span>
                              <br />
                              <span className="light-blue-text">
                                Distributed:{" "}
                              </span>
                              <span className="white-text">
                                {selectedVestingDealData?.data.IsDistributed
                                  ? "Yes"
                                  : "No"}
                              </span>
                              <br />
                              {selectedVestingDeal.length > 1 &&
                              key !== selectedVestingDeal.length - 1 ? (
                                <hr style={{ color: "white" }} />
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        }
                      )}
                  </div>
                  <div
                    style={{
                      color: "#747AA8",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {vestingCalendarData.map((vestingDealData, key) => {
                      return (
                        <span style={{ margin: "5px" }}>
                          <i
                            class="fa fa-circle"
                            aria-hidden="true"
                            style={{ color: Object.values(colors)[key] }}
                          ></i>{" "}
                          {vestingDealData.Name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="tab-box">
                {/* Options */}
                <hr style={{ color: "white" }}></hr>
                <Row>
                  <Col md={8}></Col>
                  <Col md={4}>
                    <div className="tab-right">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <span className="sort-by">
                            <i class="fa fa-filter" aria-hidden="true"></i>Sort
                            By:
                          </span>{" "}
                          <span> {selectedOrderText} </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{
                              padding: "0 0 10px 0",
                              color: "#747AA8",
                              fontSize: "13px",
                              fontWeight: "500",
                              textDecoration: "none",
                              marginRight: "10%",
                              letterSpacing: "1px",
                            }}
                            onClick={() => setSortFilter("LastUpdate DESC")}
                          >
                            By date: New First
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              padding: "0 0 10px 0",
                              color: "#747AA8",
                              fontSize: "13px",
                              fontWeight: "500",
                              textDecoration: "none",
                              marginRight: "10%",
                              letterSpacing: "1px",
                            }}
                            onClick={() => setSortFilter("LastUpdate")}
                          >
                            By date: Old First
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              padding: "0 0 10px 0",
                              color: "#747AA8",
                              fontSize: "13px",
                              fontWeight: "500",
                              textDecoration: "none",
                              marginRight: "10%",
                              letterSpacing: "1px",
                            }}
                            onClick={() => setSortFilter("Name")}
                          >
                            By name: A - Z
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              padding: "0 0 10px 0",
                              color: "#747AA8",
                              fontSize: "13px",
                              fontWeight: "500",
                              textDecoration: "none",
                              marginRight: "10%",
                              letterSpacing: "1px",
                            }}
                            onClick={() => setSortFilter("Name DESC")}
                          >
                            By name: Z - A
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>

                {/* Deals Cards */}
                <div className="tab-content">
                  <Row>
                    {/* Loading bar */}
                    {loading ? (
                      <div>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="light"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>
                        {/* For Deals set the data */}
                        {dealsData.map((data, key) => {
                          return (
                            <div className="all-deal-box">
                              <Row className=" header-chart align-items-center">
                                <Col md={8}>
                                  <div className="user-name-row dl-dt">
                                    <img
                                      src={"data:image/png;base64," + data.Icon}
                                    />
                                    <div>
                                      <p>{data.Name}</p>
                                      <p className="small-text">
                                        {data.Ticker}
                                      </p>
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  md={4}
                                  className="d-flex justify-content-end none-mb"
                                >
                                  {isFinished(data.End) ? (
                                    <button
                                      onClick={(e) =>
                                        history.push("/contribute/" + data.id)
                                      }
                                      className="btn-main"
                                    >
                                      View Deal
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        history.push("/contribute/" + data.id)
                                      }
                                      className="btn-main"
                                    >
                                      Join Deal
                                    </button>
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col md={4}>
                                  <div class="deal-info">
                                    <ul>
                                      <li>
                                        <span className="text-heading">
                                          Max Allocation
                                        </span>{" "}
                                        <span>
                                          {" "}
                                          <img src={Up} />
                                          {data.AllocationForTier1}{" "}
                                          {data.Currency}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="text-heading">
                                          Min Allocation
                                        </span>{" "}
                                        <span>
                                          <img src={Down} />
                                          {data.AllocationForTier4}{" "}
                                          {data.Currency}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="chart-box">
                                    <svg
                                      width="56"
                                      height="30"
                                      viewBox="0 0 56 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2 28C4.41676 25.3389 7.63911 18.2204 13.2782 18.0208C18.9173 17.8212 23.1467 24.8066 28.9872 24.8066C34.8277 24.8066 37.4358 13.3385 44.6961 8.04155C48.107 5.55309 54.5646 0.457338 53.9604 2.45318"
                                        stroke="url(#paint0_linear_2_459)"
                                        stroke-width="3"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_2_459"
                                          x1="53.9604"
                                          y1="2"
                                          x2="2.19124"
                                          y2="28.3221"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop
                                            stop-color="#587DFF"
                                            stop-opacity="0"
                                          />
                                          <stop
                                            offset="0.535704"
                                            stop-color="#587DFF"
                                          />
                                          <stop
                                            offset="1"
                                            stop-color="#587DFF"
                                            stop-opacity="0"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                    <div className="chart-box-content">
                                      <p>Total Raize</p>
                                      <p class="price-chart">
                                        <span>
                                          {data.TotalRaize} {data.Currency}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="chart-box">
                                    <div className="progress-bar-row">
                                      <p className="pr-percent">
                                        {(
                                          (data.CurrentRaize /
                                            data.TotalRaize) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </p>
                                      <ProgressBar
                                        now={
                                          (data.CurrentRaize /
                                            data.TotalRaize) *
                                          100
                                        }
                                      />
                                    </div>
                                    <div className="chart-box-content">
                                      <p>Status</p>
                                      {isFinished(data.End) ? (
                                        <p class="price-chart">
                                          <i
                                            class="fa fa-circle"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Finished
                                        </p>
                                      ) : (
                                        getDealStauts(data.Start, data.End)
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              {isFinished(data.End) ? (
                                <button
                                  onClick={(e) =>
                                    history.push("/contribute/" + data.id)
                                  }
                                  className="btn-main show-mobile"
                                >
                                  View Deal
                                </button>
                              ) : (
                                <button
                                  onClick={(e) =>
                                    history.push("/contribute/" + data.id)
                                  }
                                  className="btn-main show-mobile"
                                >
                                  Join Deal
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </Col>

            <Sidebar walletData={walletData}></Sidebar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MyDeals;
