import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Form,
  Spinner,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import "./admin.css";

const RegisterAccounts = ({ authorize }) => {
  const [registerData, setRegisterData] = useState([{}]);
  const [loading, setLoading] = useState(false); //Loading bar
  const [editData, setEditData] = useState({});

  const [modalCreateShow, setModalCreateShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const getRegisterData = async () => {
    setLoading(true);
    await fetch(
      'https://backend.bulknetwork.app/get-all-users?filter={"offset": 0,"skip": 0,"order": "string","where": {"additionalProp1": {}},"fields": {"WalletAddress": true,"id": true,"Email": true,"TelegramHandle": true}}'
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setRegisterData(result);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegisterData();
  }, []);

  //Methods
  //edit Data
  const editRegistrationData = (data) => {
    setModalShow(true);
    setEditData(data);
  };

  //Model
  function CreateRegistrationModel(props) {
    const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(event.target)
      var formData = {
        WalletAddress: event.target.walletAddressCreate.value,
        Email: event.target.emailCreate.value,
        TelegramHandle: event.target.telegramIdCreate.value,
      };
      console.log(formData);
      fetch("https://backend.bulknetwork.app/create-users", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.success) {
            toast.success(responseJson.message);
            getRegisterData();
            props.onHide();
          } else toast.error(responseJson.error.message);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return (
      <div
        className="overlay-popup"
        style={{ visibility: props.show ? "initial" : "hidden" }}
      >
        <div className="popup-box">
          <div className="closed">
            <a
              style={{ color: "white", border: "none" }}
              onClick={props.onHide}
            >
              <span style={{ fontSize: "25px" }}>×</span>
            </a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Create Account</h2>
            </div>
            <Form className="deal-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="walletAddressCreate">
                <Form.Label>Wallet Address</Form.Label>
                <Form.Control
                  className="input-design"
                  type="text"
                  id="walletAddressCreate"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="emailCreate">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className="input-design"
                  type="email"
                  id="emailCreate"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="telegramIdCreate">
                <Form.Label>Telegram Handle</Form.Label>
                <Form.Control
                  className="input-design"
                  type="text"
                  id="telegramIdCreate"
                  placeholder=""
                  required
                />
                <Form.Text id="telegramIdHelpBlock" muted>
                  e.g. @JhonDoe
                </Form.Text>
              </Form.Group>
              <div className="footer-popup">
                <Button type="submit" variant="primary">
                  SUBMIT
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  function EditRegistrationModel(props) {
    useEffect(() => {
      if (Object.keys(editData).length != 0 && modalShow) {
        document.getElementById("walletAddress").value = editData.WalletAddress;
        document.getElementById("email").value = editData.Email;
        document.getElementById("telegramId").value = editData.TelegramHandle;
      }
    }, [editData]);

    const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(event.target)
      var formData = {
        WalletAddress: event.target.walletAddress.value,
        Email: event.target.email.value,
        TelegramHandle: event.target.telegramId.value,
      };
      console.log(formData);
      fetch("https://backend.bulknetwork.app/create-users", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.success) {
            toast.success(responseJson.message);
            getRegisterData();
            props.onHide();
          } else toast.error(responseJson.error.message);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return (
      <div
        className="overlay-popup"
        style={{ visibility: modalShow ? "initial" : "hidden" }}
      >
        <div className="popup-box">
          <div className="closed">
            <a
              style={{ color: "white", border: "none" }}
              onClick={() => setModalShow(false)}
            >
              <span style={{ fontSize: "25px" }}>×</span>
            </a>
          </div>
          <div className="popup-body">
            <div className="popup-header">
              <h2>Registration</h2>
            </div>
            <Form className="deal-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="walletAddress">
                <Form.Label>Wallet Address</Form.Label>
                <Form.Control
                  className="input-design"
                  type="text"
                  id="walletAddress"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className="input-design"
                  type="email"
                  id="email"
                  placeholder=""
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="telegramId">
                <Form.Label>Telegram Handle</Form.Label>
                <Form.Control
                  className="input-design"
                  type="text"
                  id="telegramId"
                  placeholder=""
                  required
                />
                <Form.Text id="telegramIdHelpBlock" muted>
                  e.g. @JhonDoe
                </Form.Text>
              </Form.Group>
              <div className="footer-popup">
                <Button type="submit" variant="primary">
                  SUBMIT
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  //delete method
  const deleteRegisterData = async (id) => {
    // console.log(id)
    await fetch("https://backend.bulknetwork.app/users/" + id, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        console.log(response);
        if (response.status == 204) toast.success("deleted Sucessfully");
        else if (response.status == 404) toast.success("Already deleted");
        else toast.error("Error while deleting data!");
        getRegisterData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {authorize ? (
        <div
          className={loading ? "parentDisable" : ""}
          width="100%"
          style={{ padding: "1%" }}
        >
          <div className={loading ? "overlay-box" : "hide"}>
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>

          <br />
          <div className="deal-form-page">
            <Container>
              <>
                <CreateRegistrationModel
                  show={modalCreateShow}
                  onHide={() => setModalCreateShow(false)}
                />
                <EditRegistrationModel
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
              <h1 className="page-heading">Register Accounts</h1>
              <Button
                style={{ float: "right" }}
                onClick={() => setModalCreateShow(true)}
              >
                Add new account
              </Button>
              <br />
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Wallet Address</th>
                    <th>Email</th>
                    <th>Telegram Handle</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {registerData.map((data, key) => {
                    return (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{data.WalletAddress}</td>
                        <td>{data.Email}</td>
                        <td>{data.TelegramHandle}</td>
                        <td>
                          <Button onClick={() => editRegistrationData(data)}>
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            style={{ float: "right" }}
                            onClick={() => deleteRegisterData(data.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
};
export default RegisterAccounts;
