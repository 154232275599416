import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Container, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify'; //toster
import 'react-toastify/dist/ReactToastify.css';
import { contributeBSC } from "../components/contracts/BSCContributeCAll";
//contribute function call
import { contributeETH } from "../components/contracts/ETHContributeCAll";
import Menubar from "../components/pageComponents/navigationBar";
import RegistrationModel from "../components/pageComponents/registrationModel";
import Sidebar from "../components/pageComponents/sideBar";
//connect wallet button
import ConnectWallet from "../components/Wallet/connectWallet";
import './Style.css';
//utils function
import { getDealEndIn, getDealGoesLiveIn, isActive } from "./utils";
import { WalletContext } from './WalletContext';
//Calender part
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

//socail logs
import discordLogo from "../components/assects/SocialLogo/discord.svg";
import githubLogo from "../components/assects/SocialLogo/github.svg";
import websiteLogo from "../components/assects/SocialLogo/globe.svg";
import linkLogo from "../components/assects/SocialLogo/link.svg";
import mediumLogo from "../components/assects/SocialLogo/medium.svg";
import telegramLogo from "../components/assects/SocialLogo/telegram.svg";
import twitterLogo from "../components/assects/SocialLogo/twitter.svg";
import youtubeLogo from "../components/assects/SocialLogo/youtube.svg";

import moreThenLogo from '../components/assects/more-than-logo.png';

const Contribute = () => {
    const { id } = useParams();
    const [disableContribute, setDisableContribute] = useState(false);//Loading bar
    const [dealsDetails, setDealsDetails] = useState({});//Deatils of deal
    const [dealStartTime, seDealStartTime] = useState('');//dealStartTime
    const [dealEndTime, setDealEndTime] = useState('');//dealEndTime
    const [buyingCheck, setBuyingCheck] = useState(true);//set buying Url
    const [yourContriution, setYourContriution] = useState(0);//Contribution of connected wallet
    const [contributionData, setContributionData] = useState(null);//id of contribution data
    const [myContributionData, setMyContributionData] = useState([]);//id of contribution data
    const [loading, setLoading] = useState(false);//Loading bar
    const [isRegistered, setRegistered] = useState(false);//user register
    const [profileData, setProfileData] = useState(null);
    const [manageSelltoggle, setManageSelltoggle] = useState(false);//user Manage Sell Toggle
    const [top10User, setTop10User] = useState(false);//user register
    const [customInfoWindowToggle, setCustomInfoWindowToggle] = useState(true);//customInfoWindow Toggle
    const [modalShow, setModalShow] = React.useState(false);
    const [vestingDeal, setVestingDeal] = useState([])
    const [selectedVestingDeal, setSelectedVestingDeal] = useState(null)
    const [selectedVestingDealHold, setSelectedVestingDealHold] = useState(false)
    const history = useHistory()

    const [mailNotificationDisable, setMailNotificationDisable] = useState(false)
    const [mailNotificationDisableCount, setMailNotificationDisableCount] = useState(60)

    const [bottomContentTab, setBottomContentTab] = useState("social");

    const [scrollTrigger, setScrollTrigger] = useState(1260);

    function scrollToElement(id) {
        var element = document.getElementById(id);
        var height = document.getElementById('social-link-menu').offsetHeight
        const offset = window.innerWidth < 768? height:0
        var offsetTop = element.offsetTop - offset;
    
        window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }

    //import hooks for object
    const [walletData, setWalletData] = useContext(WalletContext)

    const getDetails = async () => {
        setLoading(true)
        // API call to fetch deals
        await fetch('https://backend.bulknetwork.app/get-single-deal/' + id + '?filter={"offset": 0,"limit": 500,"skip": 0,"order": "string","fields": {"Name": true,"id": true,"Ticker": true,"TotalRaize": true,"Currency": true,"Start": true,"End": true,"Price": true,"Vesting": true,"DealRate": true,"AllocationForTop10": true,"AllocationForTier1": true,"AllocationForTier2": true,"AllocationForTier3": true,"AllocationForTier4": true,"Social": true,"Description": true,"CustomSections": true,"socialWebsitesLogos": true,"Icon": true,"CurrentRaize": true,"WalletAddress": true,"ManageSell": true,"VisibleInTop10": true, "CustomInfoWindow": true,"CustomInfoText": true}}').then(res => res.json()).then(
            (result) => {
                // console.log(result.error)
                if (result.success) {
                    //set return data into dealsDetails object
                    setDealsDetails(result.res);
                    // console.log(result.res)
                    setLoading(false)
                } else {
                    if (result.error.statusCode == 404) {
                        toast.error("Deal not found !");
                        history.push('/')
                    } else {
                        // console.log('Error while loading deal!');
                        toast.error("Error while loading deal!");
                    }
                    setLoading(false)
                }
            }
        )

        await fetch('https://backend.bulknetwork.app/vesting-data?filter={"offset":0,"limit":500,"skip":0,"order":"string","where":{"DealId":"' + id + '"},"fields":{"DealId":true,"id":true,"date":true,"UnlockPercentage":true,"CumulativeUnlock":true,"DistributionTrHash":true,"MarketSaleTrHash":true,"IsDistributed":true}}').then(res => res.json()).then(
            (result) => {
                var newData = { dealId: id, data: result };
                setVestingDeal(newData);
            }
        )
    }

    useEffect(() => {
        setLoading(true)
        if (dealsDetails.CurrentRaize < dealsDetails.TotalRaize) {
            //set times
            setInterval(() => {
                let result = getDealGoesLiveIn(dealsDetails.Start)
                if (result)
                    seDealStartTime(result)
            }, 1000);
            setInterval(() => {
                let result = getDealEndIn(dealsDetails.End)
                if (result)
                    setDealEndTime(result)
            }, 1000);
            if (isActive(dealsDetails.Start, dealsDetails.End))
                setDisableContribute(false)
            else
                setDisableContribute(true)
        } else {
            seDealStartTime('Closed')
            setDealEndTime('Closed')
            setDisableContribute(true)
        }
        setLoading(false)
        // backgroundCurrentRaizeUpadte();
    }, [dealsDetails]);

    useEffect(() => {
        getDetails();
        setScrollTrigger(document.getElementById('social-link-menu').offsetTop);
    }, []);

    useEffect(() => {
        if (contributionData?.CustomInfo != null && customInfoWindowToggle) {
            document.getElementById("customInfo").value = contributionData.CustomInfo;
        }
    }, [customInfoWindowToggle]);

    /*
    const backgroundCurrentRaizeUpadte=async()=>{
        setInterval(()=>{
            if(Object.keys(dealsDetails).length !== 0){
                fetch('https://backend.bulknetwork.app/get-single-deal/'+id+'?filter={"offset": 0,"limit": 100,"skip": 0,"order": "string","fields": {"CurrentRaize": true}}').then(res=>res.json()).then(
                (result)=>{
                    // console.log(result.error)
                        if(result.success){
                            //set return data into dealsDetails object
                            // console.log(result.res.CurrentRaize);
                            // console.log(dealsDetails.CurrentRaize);
                            if(result.res.CurrentRaize!=dealsDetails.CurrentRaize){
                                setDealsDetails(dealsDetails=>({...dealsDetails,CurrentRaize:result.res.CurrentRaize}));
                                // console.log(result.res)
                            }
                        } 
                    }
                ).catch(err=>{
                    // console.log(err)
                })
            }
        },1000);
    }*/

    useEffect(() => {
        // API call to fetch user contribution
        if (walletData.walletAddress.length == 0) {
            setTop10User(false);
            setYourContriution(0);
            setContributionData(null);
            setRegistered(false)
            return;
        }
        let contributeData = {
            dealId: id,
            userWalletAddress: walletData.walletAddress
        }
        // console.log(JSON.stringify(contributeData));
        //API call for check data is present
        fetch('https://backend.bulknetwork.app/get-contributions', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            cache: "no-cache",
            body: JSON.stringify(
                contributeData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                //already  present
                if (responseJson.success) {
                    var totalAmount = 0;
                    for (var i = 0; i < responseJson.data.length; i++) {
                        totalAmount += responseJson.data[i].TotalAmountInvested;
                    }
                    // set Value
                    setYourContriution(totalAmount);
                    setContributionData(responseJson.data[0]);
                    setMyContributionData(responseJson.data);
                    if (responseJson.data[0].CustomInfo != null) {
                        setCustomInfoWindowToggle(false);
                        // console.log("set to false");
                    }
                } else {
                    setYourContriution(0)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        //API call for check user is registered
        let userData = {
            walletAddress: walletData.walletAddress
        }
        fetch('https://backend.bulknetwork.app/get-one', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            cache: "no-cache",
            body: JSON.stringify(
                userData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.success)
                    setProfileData(responseJson.data);
                //already registered set value
                setRegistered(responseJson.success)
            })
            .catch((error) => {
                console.error(error);
            });

        //API call for check Manage Sell is present
        let manageSellData = {
            walletAddress: walletData.walletAddress,
            dealId: id
        }
        fetch('https://backend.bulknetwork.app/manage-sells', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            cache: "no-cache",
            body: JSON.stringify(
                manageSellData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                // console.log(responseJson.data.length);
                //already  present
                if (responseJson.data.length > 0) {
                    // set Value
                    setManageSelltoggle(true)
                } else {
                    setManageSelltoggle(false)
                }
            })
            .catch((error) => {
                console.error(error);
            });

        fetch('https://bulkstaking.ew.r.appspot.com/process').then(res => res.json()).then(
            (result) => {
                // console.log(result.tops);
                setTop10User(false);
                result.tops.forEach(function (item, index) {
                    if (walletData.walletAddress == item.toLowerCase()) {
                        setTop10User(true);
                    }
                });
            });
    }, [walletData.walletAddress]);

    useEffect(() => {
        //set input contribute field
        setContributionToMax();
        document.getElementById('contributionAmount').max = getYourMaxAllocaion(walletData.tier);
    }, [walletData.tier, yourContriution]);

    const setContributionToMax = () => {
        //set input contribute field
        document.getElementById('contributionAmount').value = getYourMaxAllocaion(walletData.tier);
    }

    const increaseContribution = () => {
        //increase input contribute field
        let val = parseFloat(document.getElementById('contributionAmount').value) + parseFloat(0.5);
        let maxVal = getYourMaxAllocaion(walletData.tier);
        if (val > maxVal)
            val = maxVal;
        document.getElementById('contributionAmount').value = val;
    }

    const decreaseContribution = () => {
        //decrease input contribute field
        let val = document.getElementById('contributionAmount').value - 0.5;
        if (val < 0)
            val = 0;
        document.getElementById('contributionAmount').value = val;
    }

    const updateContributionCall = async (value) => {
        let jsonData = {
            CurrentRaize: value,
            id: id
        }
        // console.log(jsonData)
        await fetch('https://backend.bulknetwork.app/update-currentRaize', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                jsonData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                if (responseJson.error) {
                    updateContributionCall(value);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const setContributionCAll = async (txId, value) => {
        //Set Your Contriution
        var contributorType = 'Tier' + walletData.tier;
        await fetch('https://bulkstaking.ew.r.appspot.com/process').then(res => res.json()).then(
            (result) => {
                // console.log(result.tops);
                result.tops.forEach(function (item, index) {
                    if (walletData.walletAddress == item.toLowerCase()) {
                        contributorType = 'Top10';
                    }
                });
            });
        //API call for update
        let contributeData = {
            DealId: id,
            TransactionId: txId,
            UserWalletAddress: walletData.walletAddress,
            TotalAmountInvested: value,
            ContributorType: contributorType,
            Email: profileData.Email,
            TelegramHandle: profileData.TelegramHandle
        }
        // console.log(contributeData)
        // create call
        await fetch('https://backend.bulknetwork.app/create-contributors', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                contributeData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                if (responseJson.error) {
                    setContributionCAll(value);
                } else {
                    var data = myContributionData;
                    data.push(responseJson.data);
                    setMyContributionData(data);
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        if (mailNotificationDisable) {
            var timing = 60;
            var countdownTimer = setInterval(function () {
                --timing;
                setMailNotificationDisableCount(timing);
                if (timing === 0) {
                    clearInterval(countdownTimer);
                    setMailNotificationDisable(false);
                }
            }, 1000);
        }
    }, [mailNotificationDisable]);

    const getContributionTableData = () => {
        var result = "";
        for (let i = 0; i < myContributionData.length; i++) {
            result = result + `<tr>
                        <td>
                        <p style="font-size: 12px; font-weight: 700;">
                        <a href="https://bscscan.com/tx/`+ myContributionData[i].TransactionId + `" target="_blank">` + myContributionData[i].TransactionId + `</a>
                            </p>
                        </td>
                        <td>
                        <p style="font-size: 12px; font-weight: 700;text-align: center;">`+ myContributionData[i].TotalAmountInvested + `</p>
                        </td>
                    </tr>`;
        }
        console.log(result)
        return result;
    }

    const sendMailNotification = async () => {
        await sendContributionMail();
        setMailNotificationDisableCount(60);
        setMailNotificationDisable(true);
        toast.success('Mail Notification send successfully!');
    }

    const sendContributionMail = async () => {
        let mailData = {
            email: profileData.Email,
            subject: "Contributed Sucessfully",
            html: `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="background-color: #2e3560; color: white;">
                    <tr>
                    <td>
                
                        <table style="border-collapse: collapse;table-layout: fixed;" align="center">
                        <tr>
                            <td colspan="2">
                            <p style="font-size: 15px; margin-bottom: 0;">`+ dealsDetails.Name + `</p>
                            <p style="font-size: 13px; font-weight: 400; color: #747aa8; line-height: 8px;">`+ dealsDetails.Ticker + `</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Contirbution Wallet</p>
                            </td>
                            <td>
                            <p style="font-size: 12px; font-weight: 700;">`+ walletData.walletAddress + `</p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                style="background-color: #2e3560; color: white;">
                                <tr>
                                    <td>
                                    <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Contribution
                                        tx</p>
                                    </td>
                                    <td>
                                    <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Contirbution
                                        Amount</p>
                                    </td>
                                </tr>
                                `+ getContributionTableData() + `
                                </table>
                            </td>
                            </tr>
                        <tr>
                            <td>
                            <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Deal rate</p>
                            </td>
                            <td>
                            <p style="font-size: 12px; font-weight: 700;">`+ dealsDetails.DealRate + `</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Vesting</p>
                            </td>
                            <td>
                            <p style="font-size: 12px; font-weight: 700;">`+ dealsDetails.Vesting + `</p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <p style="font-size: 12px; font-weight: 500; color: #747aa8;padding-right: 10px;">Deal description</p>
                                <p style="font-size: 12px; font-weight: 700;">`+ dealsDetails.Description + `</p>
                            </td>
                        </tr>
                        </table>
                    </td>
                    </tr>
                </table>`
        }
        // console.log(mailData)
        // create call
        await fetch('https://backend.bulknetwork.app/send-mail', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                mailData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //Contribute Button call
    const contribute = async () => {
        setLoading(true)
        // Smart contract call to fetch deals
        if (walletData.isConnected) {
            //if tier 0 then ask to stack token first
            if (walletData.tier == 0) {
                toast.error("You don't have tokens in stake")
            } else {
                if (isRegistered) { //is email and telegram present
                    let value = document.getElementById('contributionAmount').value;
                    if (value <= getYourMaxAllocaion(walletData.tier) && value > 0) {
                        // alert("contribute button click")
                        var receipt;
                        let valueUSDT = walletData.web3.utils.toWei(value, 'ether'); //convert to USDT with proper decimal value
                        //Contract call
                        if (walletData.networkName == "Eth") {
                            //call ethereum smart contract
                            receipt = await contributeETH(walletData.walletAddress, valueUSDT, dealsDetails.WalletAddress)
                        } else if (walletData.networkName == "BSC") {
                            //call BSC smart contract
                            receipt = await contributeBSC(walletData.walletAddress, valueUSDT, dealsDetails.WalletAddress)
                        }
                        if (receipt) { //if smart contract updated then only
                            dealsDetails.CurrentRaize = parseFloat(dealsDetails.CurrentRaize) + parseFloat(value);
                            //API Call to update contributed amount
                            updateContributionCall(parseFloat(value));
                            // console.log('contribution amount updated')
                            //Set Your Contriution
                            await setContributionCAll(receipt.transactionHash, parseFloat(value));
                            setYourContriution(parseFloat(yourContriution) + parseFloat(value))
                            // console.log('your contribution value added')
                            //<td style="text-align: center;"><img width="20px" height="20px" src="data:image/png;base64, `+dealsDetails.Icon+`"/></td>

                            //API call for email service
                            await sendContributionMail();
                            toast.success('Contributed Successfully!');
                        } else {
                            console.log("Error while contract call! Please try again!")
                            toast.error("Error while contract call! Please try again!")
                        }
                    } else {
                        console.log("Value should be less than max Allocation and more than 0 !")
                        toast.error("Value should be less than max Allocation and more than 0 !")
                    }
                } else {
                    // register popup
                    setModalShow(true)
                }
            }
        } else {
            console.log('Please connect your wallet first!')
            toast.error("Please connect your wallet first!")
        }
        //uppdate deal details
        //set Loading false
        setLoading(false)
    }

    const getYourMaxAllocaion = (tier) => {
        let result = 0;
        //Set according to its tier
        switch (tier) {
            case 1: result = dealsDetails.AllocationForTier1;
                break;
            case 2: result = dealsDetails.AllocationForTier2;
                break;
            case 3: result = dealsDetails.AllocationForTier3;
                break;
            case 4: result = dealsDetails.AllocationForTier4;
                break;
            default: result = 0;
        }
        if (top10User)
            result = dealsDetails.AllocationForTop10;
        //Check for remaning tokens
        let remaning = parseFloat(dealsDetails.TotalRaize) - parseFloat(dealsDetails.CurrentRaize);
        if (remaning < result)
            result = remaning;
        result = result - yourContriution;
        if (result < 0)
            return 0;
        return result;
    }

    const ManageSell = () => {
        if (manageSelltoggle) {
            //Delete ManageSell record API Call
            let manageSellData = {
                walletAddress: walletData.walletAddress,
                dealId: id
            }
            fetch('https://backend.bulknetwork.app/manage-sells-delete', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                cache: "no-cache",
                body: JSON.stringify(
                    manageSellData
                ),
            }).then((response) => response.json())
                .then((responseJson) => {
                    // console.log(responseJson);
                    //deleted Successfully
                    if (responseJson.success) {
                        setManageSelltoggle(false)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            // Add data to Manage Sell
            let manageSellData = {
                WalletAddress: walletData.walletAddress,
                DealId: id
            }
            fetch('https://backend.bulknetwork.app/create-manage-sells', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                cache: "no-cache",
                body: JSON.stringify(
                    manageSellData
                ),
            }).then((response) => response.json())
                .then((responseJson) => {
                    // console.log(responseJson);
                    //created Successfully
                    if (responseJson.id) {
                        setManageSelltoggle(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const updateCustomInfo = async (event) => {
        event.preventDefault();
        let jsonData = {
            dealId: id,
            userWalletAddress: walletData.walletAddress,
            customInfo: event.target.customInfo.value
        }
        console.log(jsonData)
        await fetch('https://backend.bulknetwork.app/update-custom-data', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                jsonData
            ),
        }).then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                toast.success("Custom Info updated");
            })
            .catch((error) => {
                console.error(error);
            });

        let newData = contributionData;
        newData.CustomInfo = event.target.customInfo.value;
        setContributionData(newData);

        setCustomInfoWindowToggle(false);
    }

    const customInfoWindowToggleEdit = () => {
        setCustomInfoWindowToggle(true);
    }

    /* Vesting Date Data */
    const getVestingIsDistributed = () => {
        if (vestingDeal.data != undefined) {
            var result = [];
            for (let j = 0; j < vestingDeal.data.length; j++) {
                var obj = vestingDeal.data[j];
                if (obj.IsDistributed)
                    result.push(new Date(obj.date));
                // console.log(JSON.stringify(obj));
            }
            return result;
        }
        return [];
    }

    const getVestingNotDistributed = () => {
        if (vestingDeal.data != undefined) {
            var result = [];
            for (let j = 0; j < vestingDeal.data.length; j++) {
                var obj = vestingDeal.data[j];
                if (!obj.IsDistributed)
                    result.push(new Date(obj.date));
                // console.log(JSON.stringify(obj));
            }
            return result;
        }
        return [];
    }

    const modifiers = {
        vestingIsDistributed: getVestingIsDistributed(),
        vestingNotDistributed: getVestingNotDistributed(),
    };

    const modifiersStyles = {
        vestingNotDistributed: {
            color: 'white',
            backgroundColor: '#ffc107',
        },
        vestingIsDistributed: {
            color: '#ffc107',
            border: "1px solid #EBA724",
        },
    };

    const isDateSame = (d1, d2) => {
        var d1Date = d1.getDate();
        var d1Month = d1.getMonth()
        var d1Year = d1.getFullYear();
        var d2Date = d2.getDate();
        var d2Month = d2.getMonth()
        var d2Year = d2.getFullYear();
        if (d1Date == d2Date && d1Month == d2Month && d1Year == d2Year)
            return true;
        return false;
    }

    const handleDayMouseEnter = (day) => {
        // alert(day)
        if (vestingDeal.data != undefined) {
            for (let j = 0; j < vestingDeal.data.length; j++) {
                var obj = vestingDeal.data[j];
                if (isDateSame(day, new Date(obj.date)))
                    setSelectedVestingDeal(obj);
            }
        }
    }

    const closeVestingDataHold = () => {
        setSelectedVestingDealHold(false);
        setSelectedVestingDeal(null);
    }

    const handleDayMouseLeave = (day) => {
        if (!selectedVestingDealHold)
            setSelectedVestingDeal(null);
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Text Copied");
    }

    // sticky menu
    window.onscroll = function () {
        var target = document.getElementById('social-link-menu');
        // console.log(scrollTrigger);
        // We add pageYOffset for compatibility with IE.
        if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
            target.classList.add("stick-to-top");
        } else {
            target.classList.remove("stick-to-top");
        }
    };

    return (<>

        <div className={loading ? 'parentDisable' : ''} width="100%">
            <div className={loading ? 'overlay-box' : 'hide'}>
                <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>

            <div className="dashboard">
                <Container className="box-container">

                    <div className="content d-flex flex-wrap">

                        <Col md={3} className="left-nav">
                            <div className="cnt-lft">
                                <Menubar></Menubar>
                                <div className="hd-down">
                                    <ConnectWallet walletData={walletData} setWalletData={setWalletData} mobileView={true}></ConnectWallet>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} className="right-content">
                            <div className="deal-row none-mb">
                                <div className="d-flex align-items-center">
                                    <div className="heading-row">
                                        <a onClick={() => history.push('/')} class="bc-btn">
                                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                                        </a> <h1>Deal Details</h1>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    {/* Connect Wallet Button */}
                                    <ConnectWallet walletData={walletData} setWalletData={setWalletData}></ConnectWallet>
                                </div>
                            </div>

                            <>
                                <RegistrationModel walletAddress={walletData.walletAddress}
                                    modalShow={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </>


                            <div className="calender-desktop-view" hidden={contributionData == null}>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <DayPicker numberOfMonths={3}
                                        modifiers={modifiers}
                                        modifiersStyles={modifiersStyles}
                                        onDayMouseEnter={handleDayMouseEnter}
                                        onDayMouseLeave={handleDayMouseLeave}
                                        onDayClick={() => setSelectedVestingDealHold(true)}
                                    />
                                    <div className="vestingHoverDataModal" style={{ visibility: (selectedVestingDeal == null && !selectedVestingDealHold) ? "hidden" : "visible" }}>
                                        <div className="closed">
                                            <a style={{ color: 'white', border: 'none' }} onClick={closeVestingDataHold}><span style={{ fontSize: '25px' }}>×</span></a>
                                        </div>
                                        <span className="light-blue-text">Project Name: </span><span className="white-text">{dealsDetails?.Name}</span><br />
                                        <span className="light-blue-text">Vesting Schedule: </span><span className="white-text">{dealsDetails?.Vesting}</span><br />
                                        <span className="light-blue-text">Unlock Percentage: </span><span className="white-text">{selectedVestingDeal?.UnlockPercentage}</span><br />
                                        <span className="light-blue-text">Cumulative unlock percentage: </span><span className="white-text">{selectedVestingDeal?.CumulativeUnlock}</span><br />
                                        <span className="light-blue-text">Distribution Tx Hash: </span>
                                        <span style={{ float: "right" }} onClick={() => { copyToClipboard(selectedVestingDeal?.DistributionTrHash) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" color="white">
                                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                            </svg>
                                        </span>
                                        <br />
                                        <span className="hash">{selectedVestingDeal?.DistributionTrHash}</span>
                                        <br />
                                        <span className="light-blue-text">Market Sale distribution Tx Hash: </span>
                                        <span style={{ float: "right" }} onClick={() => { copyToClipboard(selectedVestingDeal?.MarketSaleTrHash) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                fill="none" color="white">
                                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                            </svg>
                                        </span>
                                        <br />
                                        <span className="hash">{selectedVestingDeal?.MarketSaleTrHash}</span>
                                        <br />
                                        <span className="light-blue-text">Distributed: </span><span className="white-text">{selectedVestingDeal?.IsDistributed ? "Yes" : "No"}</span><br />
                                    </div>
                                </div>
                            </div>

                            {/* Deals Cards */}
                            <div className="content-box">

                                <Row>
                                    <Col md={6}>
                                        <div className="user-name-row dl-dt">
                                            <img src={"data:image/png;base64," + dealsDetails.Icon} />
                                            <div>
                                                <p>{dealsDetails.Name} </p>
                                                <p className="small-text">{dealsDetails.Ticker}</p>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div class="row-box justify-content-end"><p class="text-heading">Deal goes live in</p><p class="amount">{dealStartTime}</p></div>
                                        <div class="row-box justify-content-end"><p class="text-heading">Deal ends in</p><p class="amount"><i class="fa fa-circle" aria-hidden="true"></i> {dealEndTime}</p></div>
                                    </Col>
                                </Row>

                                <Row className="chart-row">
                                    <Col md={4}>
                                        <div className="chart-box">
                                            <svg width="56" height="30" viewBox="0 0 56 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 28C4.41676 25.3389 7.63911 18.2204 13.2782 18.0208C18.9173 17.8212 23.1467 24.8066 28.9872 24.8066C34.8277 24.8066 37.4358 13.3385 44.6961 8.04155C48.107 5.55309 54.5646 0.457338 53.9604 2.45318" stroke="url(#paint0_linear_2_459)" stroke-width="3" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_2_459" x1="53.9604" y1="2" x2="2.19124" y2="28.3221" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#587DFF" stop-opacity="0" />
                                                        <stop offset="0.535704" stop-color="#587DFF" />
                                                        <stop offset="1" stop-color="#587DFF" stop-opacity="0" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <div className="chart-box-content">
                                                <p>Total Raize</p>
                                                <p class="price-chart"><span></span> {dealsDetails.TotalRaize ? dealsDetails.TotalRaize.toLocaleString() : dealsDetails.TotalRaize}</p>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="chart-box">
                                            <p className="pr-percent">{(dealsDetails.CurrentRaize / dealsDetails.TotalRaize * 100).toFixed(2)}%</p>
                                            <div className="progress-bar-row">
                                                <ProgressBar now={(dealsDetails.CurrentRaize / dealsDetails.TotalRaize) * 100} />
                                            </div>
                                            <div className="chart-box-content">
                                                <p>Total Amount Contributed</p>
                                                <p class="price-chart">{dealsDetails.CurrentRaize ? dealsDetails.CurrentRaize.toLocaleString() : dealsDetails.CurrentRaize}</p>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="chart-box">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.89659 6.05577C1.07522 8.54606 0 11.6165 0 14.9382C0 23.2566 6.74342 30 15.0618 30C23.3803 30 30.1237 23.2566 30.1237 14.9382C30.1237 7.27682 24.4035 0.951492 17.0011 0L16.5549 3.97622C21.958 4.7052 26.1237 9.33521 26.1237 14.9382C26.1237 21.0474 21.1711 26 15.0618 26C8.95256 26 4 21.0474 4 14.9382C4 12.4034 4.85256 10.0678 6.28656 8.20235L2.89659 6.05577Z" fill="url(#paint0_linear_2_508)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_2_508" x1="-0.000212375" y1="-1.77376" x2="21.1975" y2="3.05295" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#EBA724" />
                                                        <stop offset="1" stop-color="#FFD583" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <div className="chart-box-content">
                                                <p>Rate</p>
                                                <p class="price-chart">{dealsDetails.DealRate}<span></span></p>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="tire-section">
                                    <div className="tire-section-heading"> <span>{top10User ? 'Top 10' : 'Tier ' + walletData.tier}</span></div>

                                    <Col md={6}>
                                        <div class="deal-info">
                                            <ul>
                                                <li><span className='text-heading'>Token locked (BSC):</span> <span>{walletData.BSCTokenCount.toLocaleString()} BULK</span></li>
                                                <li><span className='text-heading'>Token locked (ETH):</span>  <span>{walletData.ETHTokenCount.toLocaleString()} BULK</span></li>
                                                <li><span className='text-heading'>Tokens in Farming (BSC):</span>  <span>{walletData.TotalFarmingTokens.toLocaleString()} BULK</span></li>
                                                <li><span className='text-heading'>Total:</span> <span>{walletData.TotalTokenCount.toLocaleString()} BULK</span></li>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col md={6} >
                                        <div className="chart-box">
                                            <Row>
                                                <Col md={6}>
                                                    <div className="chart-box-content">
                                                        <p>Your Max Allocation</p>
                                                        <p className="max-allocation"><span className="max-allocation-amount">{getYourMaxAllocaion(walletData.tier)}</span> {dealsDetails.Currency}</p>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="chart-box-content">
                                                        <p>Your Total Contribution</p>
                                                        <p className="max-allocation"><span className="max-allocation-amount">{yourContriution}</span> {dealsDetails.Currency}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>


                                <Row className="tire-section">
                                    <div className="tire-section-heading"> <span>Contribution</span></div>

                                    <Row style={{ margin: "15px 0" }}>
                                        <Col md={4} >
                                            <div>
                                                <p className="contibution-text">Enter your Contribution</p>
                                            </div>
                                        </Col>

                                        <Col md={4} >
                                            <div className="minus-plus-row">
                                                <button onClick={decreaseContribution}>-</button>
                                                <input type="number" id="contributionAmount" min="0" step="0.5"></input>
                                                <div class="plus-btn">
                                                    <div onClick={setContributionToMax} className="badge">Max</div>
                                                    &emsp;
                                                    <button onClick={increaseContribution}>+</button>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={4} >
                                            <Button className="cnt-button" style={{ margin: '0px 0px 10px 0px' }} onClick={contribute} disabled={disableContribute}> Contribute </Button>
                                            <div hidden={!(dealsDetails.ManageSell && yourContriution > 0)}>
                                                <Button className="cnt-button cancel-btn" onClick={ManageSell}>
                                                    {manageSelltoggle ? 'Cancel Managed Sale ' : 'Request Managed Sale '}  </Button>
                                            </div>
                                            <OverlayTrigger placement="bottom" overlay={
                                                <Tooltip id="button-tooltip-2">
                                                    The Managed Sell option is intended to save on high tx fees on the Ethereum blockchain. By activating the option you choose to delegate the token selling to the Platform administrators (the selling timing is chosen to their own discretion) and to receive the selling proceeds in the form of stable coins on the BSC blockchain.
                                                </Tooltip>
                                            }>
                                                <p className="qust">{manageSelltoggle ? 'Managed Sale Activated' : 'Managed Sale Deactivated'}&ensp;<span className="badge">i</span></p>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Row>

                                <div className="fill-your-solana" style={{ display: dealsDetails.CustomInfoWindow ? "block" : "none" }}>
                                    <p className="bold"> {dealsDetails.CustomInfoText}</p>
                                    {customInfoWindowToggle ?
                                        <Form onSubmit={updateCustomInfo}>
                                            <Row>
                                                <Col md={8}>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Control id="customInfo" type="text" placeholder="Enter text" style={{ color: 'white' }}
                                                            disabled={contributionData == null} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Button className="cnt-button" type="submit" disabled={contributionData == null}>  Submit  </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        :
                                        <p>{contributionData?.CustomInfo}
                                            <strong onClick={customInfoWindowToggleEdit}><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 14L0.46967 13.4697C0.329018 13.6103 0.25 13.8011 0.25 14H1ZM14 1L14.5303 0.46967C14.2374 0.176777 13.7626 0.176777 13.4697 0.46967L14 1ZM18 5L18.5303 5.53033C18.8232 5.23744 18.8232 4.76256 18.5303 4.46967L18 5ZM5 18V18.75C5.19891 18.75 5.38968 18.671 5.53033 18.5303L5 18ZM1 18H0.25C0.25 18.4142 0.585786 18.75 1 18.75L1 18ZM9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75V17.25ZM17.5 18.75C17.9142 18.75 18.25 18.4142 18.25 18C18.25 17.5858 17.9142 17.25 17.5 17.25V18.75ZM1.53033 14.5303L14.5303 1.53033L13.4697 0.46967L0.46967 13.4697L1.53033 14.5303ZM13.4697 1.53033L17.4697 5.53033L18.5303 4.46967L14.5303 0.46967L13.4697 1.53033ZM17.4697 4.46967L4.46967 17.4697L5.53033 18.5303L18.5303 5.53033L17.4697 4.46967ZM5 17.25H1V18.75H5V17.25ZM1.75 18V14H0.25V18H1.75ZM10.4697 4.53033L14.4697 8.53033L15.5303 7.46967L11.5303 3.46967L10.4697 4.53033ZM9 18.75H17.5V17.25H9V18.75Z" fill="url(#paint0_linear_1033_706)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1033_706" x1="3.5" y1="5" x2="9.5" y2="18" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#7C6BDE" />
                                                        <stop offset="1" stop-color="#637EEB" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>edit</strong>
                                        </p>
                                    }
                                </div>


                                <div className="tab-col">
                                    <Row>
                                        <Col md={6}>
                                            <div className="deal-info">
                                                <div className="heading-sm">Deal Info</div>
                                                <ul>
                                                    <li><span className='text-heading'>Start:</span>{new Date(dealsDetails.Start).toUTCString()}</li>
                                                    <li><span className='text-heading'>End:</span>{new Date(dealsDetails.End).toUTCString()}</li>
                                                    <li><span className='text-heading'>Currency:</span>{dealsDetails.Currency}</li>
                                                    <li><span className='text-heading'>Price:</span>{dealsDetails.Price}</li>
                                                    <li><span className='text-heading'>Vesting:</span> {dealsDetails.Vesting}</li>
                                                </ul>
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="deal-info">
                                                <div className="heading-sm">Allocation per contributor</div>
                                                <ul>
                                                    <li><span className='text-heading'>Top 10:</span> {dealsDetails.AllocationForTop10} {dealsDetails.Currency}</li>
                                                    <li><span className='text-heading'>Tier 1:</span> {dealsDetails.AllocationForTier1} {dealsDetails.Currency}</li>
                                                    <li><span className='text-heading'>Tier 2:</span> {dealsDetails.AllocationForTier2} {dealsDetails.Currency}</li>
                                                    <li><span className='text-heading'>Tier 3:</span> {dealsDetails.AllocationForTier3} {dealsDetails.Currency}</li>
                                                    <li><span className='text-heading'>Tier 4:</span> {dealsDetails.AllocationForTier4} {dealsDetails.Currency}</li>
                                                </ul>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>


                                <Row className="tire-section buy-section">
                                    <Col md={3} className="d-flex align-items-center"><p>Buy Bulk On</p></Col>
                                    <Col md={5} className="d-flex">
                                        <div className="custom_radio">
                                            <input type="radio" id="buyingUrl-1" name="buyingUrl" onClick={() => setBuyingCheck(true)} checked={buyingCheck} /><label for="buyingUrl-1">Uniswap</label>
                                            <input type="radio" id="buyingUrl-2" name="buyingUrl" onClick={() => setBuyingCheck(false)} checked={!buyingCheck} /><label for="buyingUrl-2" >Pancakeswap</label>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <a href={buyingCheck ?
                                            ("https://app.uniswap.org/#/swap?outputCurrency=0xa143ac515dca260a46c742c7251ef3b268639593") :
                                            ("https://pancakeswap.finance/swap?outputCurrency=0xa143ac515dca260a46c742c7251ef3b268639593")
                                        } target="_blank">
                                            <Button className="cnt-button">Buy</Button>
                                        </a>
                                    </Col>
                                </Row>



                                <Row>
                                    <Col md={12} className="bottom-content">
                                        <Row>
                                            <Col md={3}>
                                                <div id="social-link-menu">
                                                    <ul>
                                                        <li>
                                                            <span className="heading">{bottomContentTab == "social" ?
                                                                <img alt="active" src={moreThenLogo} /> : ""}</span>
                                                            <span onClick={() => {scrollToElement('social')
                                                                setBottomContentTab("social")}}
                                                                style={{ color: bottomContentTab == "social" ? "#fff" : "#747AA8" }}>Social</span>
                                                        </li>
                                                        <li>
                                                            <span className="heading">{bottomContentTab == "project-description" ?
                                                                <img alt="active" src={moreThenLogo} /> : ""}</span>
                                                            <span  onClick={() => {scrollToElement('project-description') 
                                                            setBottomContentTab("project-description")}}
                                                                style={{ color: bottomContentTab == "project-description" ? "#fff" : "#747AA8" }}>Project description</span>
                                                        </li>
                                                        {dealsDetails.CustomSections?.map((data, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <span className="heading">{bottomContentTab == data.heading ?
                                                                        <img alt="active" src={moreThenLogo} /> : ""}</span>
                                                                    <span onClick={() =>  {
                                                                        scrollToElement(data.heading)
                                                                        setBottomContentTab(data.heading)}}
                                                                        style={{ color: bottomContentTab == data.heading ? "#fff" : "#747AA8" }}>{data.heading}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md={9}>
                                                <div className="div-tab-block" onMouseOver={()=>setBottomContentTab("social")}>
                                                    <div id="social" className="heading">Social</div>
                                                    {dealsDetails.Social ?
                                                        JSON.parse(dealsDetails.Social).map((data, index) => {
                                                            return (
                                                                <a href={data.link}><img alt={data.type} style={{ height: "30px" }} src={
                                                                    data.type === "discord" ? discordLogo :
                                                                        data.type === "github" ? githubLogo :
                                                                            data.type === "website" ? websiteLogo :
                                                                                data.type === "link" ? linkLogo :
                                                                                    data.type === "medium" ? mediumLogo :
                                                                                        data.type === "telegram" ? telegramLogo :
                                                                                            data.type === "twitter" ? twitterLogo :
                                                                                                data.type === "youtube" ? youtubeLogo :
                                                                                                    dealsDetails?.socialWebsitesLogos[index] ?
                                                                                                        ("data:image/png;base64," + dealsDetails?.socialWebsitesLogos[index]) : ""} /></a>
                                                            )
                                                        }) : null}
                                                </div>
                                                <div className="div-tab-block" onMouseOver={()=>setBottomContentTab("project-description")}>
                                                    <div id="project-description" className="heading">Project description</div>
                                                    <div className="div-tab-block-description" dangerouslySetInnerHTML={{ __html: dealsDetails.Description }}></div>
                                                </div>

                                                {dealsDetails.CustomSections?.map((ele, index) => {
                                                    return (
                                                        <div className="div-tab-block" key={index} onMouseOver={()=>setBottomContentTab(ele.heading)}>
                                                            <div id={ele.heading} className="heading">{ele.heading}</div>
                                                            <div dangerouslySetInnerHTML={{ __html: ele.data }}></div>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {myContributionData.length > 0 &&
                                    <Row>
                                        <div className="deal-info" style={{ borderTop: "1px solid #404881", padding: "25px 10px" }}>
                                            <Row style={{ borderTop: "1px solid #404881", padding: "15px 0" }}>
                                                <Col md={8}>
                                                    <div className="heading-sm">My contributions</div>
                                                </Col>
                                                <Col md={4}>
                                                    <Button className="cnt-button" onClick={sendMailNotification} disabled={mailNotificationDisable}>{mailNotificationDisable ? mailNotificationDisableCount + " sec remaining" : "Resend Email Notification"}</Button>
                                                </Col>
                                            </Row>
                                            <table className="myContributionTable">
                                                <thead>
                                                    <tr>
                                                        <th>Tx hash</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {myContributionData.map((data, key) => {
                                                        return (
                                                            <tr>
                                                                <td><a href={"https://bscscan.com/tx/" + data?.TransactionId} target="_blank">{data?.TransactionId}</a></td>
                                                                <td>{data?.TotalAmountInvested}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                }
                            </div>
                        </Col> {/* right content closed */}


                        <Sidebar walletData={walletData}></Sidebar>

                    </div>
                </Container>
            </div>
        </div >
    </>)
}

export default Contribute
